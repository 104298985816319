/* eslint-disable indent */
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { useState } from 'react';
import queries from '../../../api/queries/ProductQueries';
import { useParams } from 'react-router-dom';
import { useToasterLogic } from '../../../utils/useToasterLogic';
import { modifierDTO } from '../../../types/ProductDto';

export const useGroupLogic = () => {
  const [products, setProducts] = useState<any>([]);
  const [group, setGroup] = useState<any>([]);
  const [groupBundles, setGroupBundles] = useState<any>([]);
  const [cover, setCover] = useState<any>(null);
  const [action, setAction] = useState<any>();
  const [productData, setProductData] = useState<any>();
  const [oriProduct, setOriProduct] = useState<any>();
  const queryClient = useQueryClient();

  const { id } = useParams();

  const { successToast, errorToast } = useToasterLogic();

  const groupFetchQuery = useQuery({
    queryKey: ['group', id],
    queryFn: () => queries.fetchGroupById({ id: id }),
    onSuccess: (data) => {
      setGroup(data);
      setProducts((data as any)?.products);
    },
    // retry: (failureCount, error: any) => (error.response.status === 404 ? false : true),
    refetchOnWindowFocus: false,
    enabled: !!id
  });

  const groupCoverFetchQuery = useQuery({
    queryKey: ['groupCover', id],
    queryFn: () => queries.fetchGroupImage({ id: id }),
    onSuccess: async (data) => {
      let image = await queries.convertBlobToBase64(await data);
      setCover(image);
    },
    retry: (failureCount, error: any) => (error.response.status === 404 ? false : true),
    refetchOnWindowFocus: false,
    enabled: !!id
  });

  const groupBundesFetchQuery = useQuery({
    queryKey: ['groupBundles', id],
    queryFn: () => queries.fetchGroupBundles({ id: id }),
    onSuccess: (data) => {
      setGroupBundles(data);
    },
    retry: (failureCount, error: any) => (error.response.status === 404 ? false : true),
    refetchOnWindowFocus: false,
    enabled: !!id
  });

  const productUpdateQuery = useMutation({
    mutationFn: () =>
      queries.updateProduct({
        productDto: {
          isVisible: productData?.isVisible,
          modifiers: productData?.modifiers?.map((m: modifierDTO) =>
            !oriProduct?.modifiers?.find((oM: modifierDTO) => oM?.id === m?.id)
              ? {
                  ...m,
                  id: null,
                  prices: m.prices.map((price: any) => ({
                    ...price,
                    id: null
                  }))
                }
              : { ...m }
          ),
          name: productData?.name,
          productGroupId: productData?.productGroupId,
          description: productData?.description,
          productType: productData?.productType,
          tags: productData?.tags,
          orderNumber: productData?.orderNumber,
          isSingletonCartItem: productData?.isSingletonCartItem,
          suggestedQuantity: productData?.suggestedQuantity,
          connectedProducts: productData?.connectedProducts
        },
        id: productData?.id
      }),
    onSuccess: () => {
      queryClient.invalidateQueries(['group']);
      setAction(null);
      setProductData(null);
      successToast('Sikeres termék frissítés');
    },
    onError: () => {
      errorToast('Sikertelen termék frissítés');
    }
  });
  const productCreateQuery = useMutation({
    mutationFn: () =>
      queries.createProduct({
        productDto: {
          ...productData,
          modifiers: productData?.modifiers?.map((m: modifierDTO) => {
            return { ...m, id: null };
          }),
          tags: []
        },
        groupId: id
      }),
    onSuccess: () => {
      queryClient.invalidateQueries(['group']);
      setAction(null);
      setProductData(null);
      successToast('Sikeres termék létrehozás');
    },
    onError: () => {
      errorToast('Sikertelen termék létrehozás');
    }
  });

  const handleSubmit = () => {
    if (action === 'update') {
      productUpdateQuery.mutate();
    } else {
      productCreateQuery.mutate();
    }
  };

  const handleStatus = (product: any, status: boolean) => {
    setProductData({ ...product, isVisible: status });
    productUpdateQuery.mutate();
  };

  return {
    groupFetchQuery,
    productCreateQuery,
    productUpdateQuery,
    groupBundesFetchQuery,
    groupBundles,
    groupCoverFetchQuery,
    products,
    group,
    cover,
    setCover,
    action,
    setAction,
    productData,
    setProductData,
    handleSubmit,
    handleStatus,
    id,
    setOriProduct
  };
};
