import { api } from '../axios';
import ENV from '../env';

export default {
  async fetchModifierKeys() {
    return await (await api({ baseUrl: ENV.REACT_APP_API_URL, auth: false })).get(`/modifier-keys`);
  },
  async fetchCurrentCompartment() {
    return await (await api({ baseUrl: ENV.REACT_APP_API_URL, auth: true })).get(`admin/users/compartments/current`);
  }
};
