import React from 'react';
import { modifierDTO } from '../../../../types/ProductDto';
import { IconButton, Tooltip } from '@mui/material';
import { MdDelete, MdEdit } from 'react-icons/md';
import styles from './modifierItem.module.css';
import { useModifierItemLogic } from './useModifierItemLogic';
import Uploader from '../../Uploader/Uploader';

interface ModifierItemProps {
  m: modifierDTO;
  productData: any;
  setProductData: any;
  setEditModifier: any;
}

const ModifierItem: React.FC<ModifierItemProps> = ({ m, productData, setEditModifier, setProductData }) => {
  const { modifierImage, setModifierImage } = useModifierItemLogic(m?.id, productData?.id);

  return (
    <div key={m?.modifierKey} className={styles.modifierItemWrapper}>
      <div className={styles.modifierItem}>
        <div className={styles.before}>
          {modifierImage && <img src={modifierImage} alt='terméktulajdonság_képe' width={30} />}
          <p>{m?.modifierKey}</p>
        </div>
        <div style={{ display: 'flex' }}>
          <IconButton onClick={() => setEditModifier(m)}>
            <MdEdit />
          </IconButton>
          <IconButton
            onClick={() =>
              setProductData({
                ...productData,
                modifiers: productData?.modifiers?.filter((modi: modifierDTO) => modi.id !== m.id)
              })
            }>
            <MdDelete />
          </IconButton>
        </div>
      </div>
      <Tooltip placement='top' title={'Képfeltöltés csak előzetesen már mentett terméktulajdonságnál működik!'}>
        <div style={{ width: '100%' }}>
          <Uploader
            image={modifierImage}
            setImage={setModifierImage}
            id={m?.id}
            url='/admin/products'
            parentId={productData?.id}
            variant={'outlined'}
            fullWidth={true}
          />
        </div>
      </Tooltip>
    </div>
  );
};

export default ModifierItem;
