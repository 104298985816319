import { Button, CircularProgress, Dialog, DialogContent, DialogTitle } from '@mui/material';
import React from 'react';
import styles from './confirmDialog.module.css';

interface ConfirmDialogProps {
  open: any;
  setOpen: any;
  title: string;
  action: any;
  loading: boolean;
}

const ConfirmDialog: React.FC<ConfirmDialogProps> = ({ open, setOpen, title, action, loading }) => {
  return (
    <Dialog open={open} onClose={() => setOpen(false)}>
      <DialogTitle>{title}</DialogTitle>
      <DialogContent>
        <div className={styles.buttonGroup}>
          <Button disabled={loading} onClick={() => action()}>
            {loading ? <CircularProgress size={24} /> : 'Igen'}
          </Button>
          <Button onClick={() => setOpen(false)}>Nem</Button>
        </div>
      </DialogContent>
    </Dialog>
  );
};

export default ConfirmDialog;
