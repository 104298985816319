import React from 'react';
import styles from './redirect.module.css';
import { useNavigate } from 'react-router-dom';
import useGeneralStore from '../../store/GeneralStore';

const Redirect = () => {
  const navigate = useNavigate();

  const { current } = useGeneralStore((state: any) => ({
    current: state.current
  }));

  React.useEffect(() => {
    if (current?.groups && !current?.groups?.includes('dt_admin')) {
      navigate('/logout');
    }
  }, [current, navigate]);

  return (
    <div className={styles.background}>
      <h3 className={styles.main_text}>Redirecting....</h3>
    </div>
  );
};

export default Redirect;
