import { useState } from 'react';

export const useGetQueryString = () => {
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState(localStorage.getItem('pageSize') ? localStorage.getItem('pageSize') : 10);

  const getDateString = (date: any) => {
    if (date && typeof date !== 'undefined') {
      return new Date(date).toISOString();
    }
    return new Date().toISOString();
  };
  interface getProductQueryStringProps {
    page: string;
    size: string;
    sort: string[];
  }
  const getProductQueryString = (props: getProductQueryStringProps): string => {
    return `page=${props.page}&size=${props.size}&sort=${props.sort.join(',')},asc`;
  };

  const getQueryString = (searchParams: any) => {
    let queryString = '';
    let foundValidQueryParam = false;
    searchParams?.forEach((searchParam: any) => {
      if (searchParam.value !== '') {
        if (!foundValidQueryParam) {
          queryString += `${searchParam.value}`;
        } else {
          queryString += `&${searchParam.value}`;
        }
        foundValidQueryParam = true;
      }
    });
    if (!foundValidQueryParam) return '';
    return queryString;
  };

  return {
    currentPage,
    setCurrentPage,
    pageSize,
    getDateString,
    setPageSize,
    getProductQueryString,
    getQueryString
  };
};
