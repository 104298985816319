import React from 'react';
import styles from './configuratorCheckbox.module.css';
import { modifierDTO } from '../../../types/ProductDto';
import { FormControlLabel, Checkbox, FormGroup } from '@mui/material';

interface ConfiguratorCheckboxProps {
  modifier: modifierDTO;
  dtoModifiers: any;
  mementoDtoModifiers?: any;
  setDtoModifiers: any;
}

{
  /*eslint-disable */
}

const ConfiguratorCheckbox: React.FC<ConfiguratorCheckboxProps> = ({
  modifier,
  dtoModifiers,
  mementoDtoModifiers,
  setDtoModifiers
}) => {
  return (
    <>
      {/*eslint-disable */}
      <FormGroup className={styles.checkbox} style={{ width: '90%', color: 'var(--color-main) !important' }}>
        <FormControlLabel
          control={
            <Checkbox
              checked={dtoModifiers[modifier?.modifierKey] == '1'}
              onChange={(e: any) =>
                setDtoModifiers({
                  ...dtoModifiers,
                  [modifier?.modifierKey]: dtoModifiers[modifier?.modifierKey] == 1 ? 0 : 1
                })
              }
            />
          }
          label={modifier?.description}
        />
      </FormGroup>
      {mementoDtoModifiers && dtoModifiers[modifier?.modifierKey] != mementoDtoModifiers[modifier?.modifierKey] && (
        <FormGroup className={styles.checkBoxMemento}>
          <FormControlLabel
            control={
              <Checkbox
                checked={mementoDtoModifiers[modifier?.modifierKey] == '1'}
                disabled={false}
                className={styles.checkBoxMemento}
                style={{ color: '#FFC107', transform: 'scale(0.8)' }}
              />
            }
            label={modifier?.description}
            style={{ color: '#FFC107' }}
            sx={{ '& .MuiFormControlLabel-label': { fontSize: '12px' } }}
          />
        </FormGroup>
      )}
    </>
  );
};

export default ConfiguratorCheckbox;
