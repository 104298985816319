import React, { useState } from 'react';
import styles from './instanceForm.module.css';
import { Button, CircularProgress, IconButton, TextField } from '@mui/material';
import { MdAddCircleOutline, MdClose, MdDelete } from 'react-icons/md';

interface InstanceFormProps {
  handleSubmit: any;
  loading: boolean;
  setAdditionals: any;
  additionals: any;
  oriAdditionals: any;
  setEdit: any;
  edit: boolean;
}
/*eslint-disable*/
const InstanceForm: React.FC<InstanceFormProps> = ({
  handleSubmit,
  setAdditionals,
  additionals,
  loading,
  oriAdditionals,
  setEdit,
  edit
}) => {
  const [newAdditional, setNewAdditional] = useState<string>('');
  const [isAdd, setIsAdd] = useState<boolean>(false);

  return (
    <div className={styles.form}>
      {/*
       IF DCID WILL BE A REAL THING
      <div className={styles.detailRow}>
        <p className={styles.rowItemTitle}>
          cloudId:{' '}
          <Tooltip placement='top' title='A DCID mező kitöltéséhez szükséges, nem módosítható kulcs'>
            <label>
              <MdInfo />
            </label>
          </Tooltip>
        </p>
        <div>
          <TextField
            disabled={!edit}
            name='cloudId'
            autoComplete='cloudId'
            value={additionals?.cloudId || ''}
            autoFocus
            className={styles.input}
            onChange={(e) => {
              e.preventDefault();
              setAdditionals({ ...additionals, cloudId: e.target.value });
            }}
          />
          <IconButton type='button' disabled>
            <MdDelete color='transparent' />
          </IconButton>
        </div>
      </div> */}

      {Object.keys(additionals)?.map((k: any) => (
        <div className={styles.detailRow}>
          <p className={styles.rowItemTitle}>{k}:</p>
          <div className={styles.fieldWrapper}>
            <TextField
              disabled={!edit}
              name={k}
              autoComplete={k}
              value={additionals[k]}
              autoFocus
              className={styles.input}
              onChange={(e) => {
                e.preventDefault();
                setAdditionals({ ...additionals, [k]: e.target.value });
              }}
            />
            <IconButton
              type='button'
              disabled={!edit}
              onClick={() => {
                const tempAdditionals = additionals;
                delete tempAdditionals[k];
                setAdditionals({ ...tempAdditionals });
              }}>
              <MdDelete color={!edit ? 'lightgrey' : 'red'} />
            </IconButton>
          </div>
        </div>
      ))}

      <div className={styles.newAdditional} style={{ justifyContent: isAdd ? 'space-between' : 'center' }}>
        {isAdd ? (
          <>
            <TextField
              name='newAdditional'
              autoComplete='newAdditional'
              value={newAdditional}
              autoFocus
              className={styles.input}
              onChange={(e) => {
                e.preventDefault();
                setNewAdditional(e.target.value);
              }}
            />
            <div className={styles.btnWrapper}>
              <Button
                style={{ marginLeft: '1.2rem', width: '70%' }}
                variant='outlined'
                type='button'
                onClick={() => {
                  setAdditionals({ ...additionals, [newAdditional]: '' });
                  setNewAdditional('');
                  setIsAdd(false);
                }}>
                Hozzáad
              </Button>
              <IconButton
                type='button'
                onClick={() => {
                  setIsAdd(false);
                  setNewAdditional('');
                }}>
                <MdClose />
              </IconButton>
            </div>
          </>
        ) : (
          <IconButton disabled={!edit} onClick={() => setIsAdd(true)}>
            <MdAddCircleOutline />
          </IconButton>
        )}
      </div>

      <div className={styles.buttonsContainer}>
        <button
          disabled={loading}
          onClick={() => handleSubmit()}
          className={styles.button}
          type='button'
          style={{ display: edit ? 'unset' : 'none' }}>
          {loading ? <CircularProgress size={24} /> : 'Mentés'}
        </button>
        <button
          className={styles.button}
          type='button'
          style={{ display: edit ? 'unset' : 'none' }}
          onClick={() => {
            setEdit(false);
            setIsAdd(false);
            setAdditionals(oriAdditionals);
          }}>
          Mégsem
        </button>

        {!edit && (
          <button
            className={styles.button}
            type='button'
            onClick={() => {
              setEdit(true);
            }}>
            Elérés módosítása
          </button>
        )}
      </div>
    </div>
  );
};

export default InstanceForm;
