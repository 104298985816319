import { QueryOptionDto } from '../../../../types/QueryBuilderDtos';

const queryOptions: QueryOptionDto[] = [
  {
    name: 'id',
    displayName: 'Azonosító',
    type: 'string',
    used: false,
    value: ''
  },
  {
    name: 'userId',
    displayName: 'Felhasználó',
    type: 'string',
    used: false,
    value: ''
  },
  {
    name: 'compartmentId',
    displayName: 'Compartment',
    type: 'string',
    used: false,
    value: ''
  },
  // {
  //   name: 'createdDate',
  //   displayName: 'Létrehozás dátuma',
  //   type: 'date',
  //   used: false,
  //   value: ''
  // },
  {
    name: 'sort',
    displayName: 'Rendezési szempont',
    type: 'key-value',
    keys: [
      { name: 'id', displayName: 'Azonosító' },
      { name: 'userId', displayName: 'Felhasználó' },
      { name: 'compartmentId', displayName: 'Compartment' },
      { name: 'createdDate', displayName: 'Létrehozás dátuma' },
      { name: 'creditBalance', displayName: 'Kredit egyenleg' },
      { name: 'currency', displayName: 'Valuta' },
      { name: 'status', displayName: 'Státusz' }
    ],
    values: [
      { name: 'asc', displayName: 'Növekvő' },
      { name: 'desc', displayName: 'Csökkenő' }
    ],
    used: false,
    value: ''
  },
  {
    name: 'pagination',
    displayName: '',
    type: 'pagination',
    used: true,
    value: ''
  }
];

export default queryOptions;
