import React, { useState } from 'react';
import styles from './productInstanceCreator.module.css';
import { Dialog, DialogContent, IconButton, MenuItem, Select } from '@mui/material';
import { MdAddCircle } from 'react-icons/md';
import Configurator from '../../configuratorComponents/configurator/Configurator';
import { useQuery } from '@tanstack/react-query';
import queries from '../../../api/queries/ProductQueries';
import useGeneralStore from '../../../store/GeneralStore';

const ProductInstanceCreator = () => {
  const [open, setOpen] = useState<boolean>(false);
  const [selectedProduct, setSelectedProduct] = useState<any>();
  const [selectedGroup, setSelectedGroup] = useState<any>();

  const { subscription }: any = useGeneralStore((state) => ({
    subscription: state.subscription
  }));

  const handleClose = () => {
    setOpen(false);
    setSelectedGroup(null);
    setSelectedProduct(null);
  };

  const productsFetchQuery = useQuery<any, any>({
    queryKey: ['products', selectedGroup?.id, subscription?.currency],
    queryFn: () => queries.fetchProducts({ groupId: selectedGroup?.id, currency: subscription?.currency }),
    retry: (failureCount, error: any) => (error.response.status === 404 ? false : true),
    refetchOnWindowFocus: false,
    enabled: !!selectedGroup?.id
  });
  const groupsFetchQuery = useQuery<any, any>({
    queryKey: ['groups'],
    queryFn: () => queries.fetchGroups(),
    retry: (failureCount, error: any) => (error.response.status === 404 ? false : true),
    refetchOnWindowFocus: false
  });

  return (
    <div className={styles.instanceCreator}>
      <div style={{ display: 'flex', alignItems: 'center' }}>
        <h4>Új szolgáltatás hozzáadása </h4>
        <IconButton>
          <MdAddCircle onClick={() => setOpen(!open)} />
        </IconButton>
        <Dialog open={open && groupsFetchQuery?.data} onClose={handleClose}>
          <DialogContent className={styles.dialogcontent}>
            <h3>Új szolgáltatás hozzáadása</h3>
            <Select onChange={(e) => setSelectedGroup(e.target.value)} className={styles.input}>
              {groupsFetchQuery?.data
                ?.filter((group: any) => group.isBundle === false)
                ?.map((group: any) => (
                  <MenuItem key={group?.id} value={group}>
                    {group?.name}
                  </MenuItem>
                ))}
            </Select>
            {productsFetchQuery?.data && (
              <Select onChange={(e) => setSelectedProduct(e.target.value)} className={styles.input}>
                {productsFetchQuery?.data?._embedded?.productDTOList?.map((product: any) => (
                  <MenuItem key={product?.id} value={product}>
                    {product?.name}
                  </MenuItem>
                ))}
              </Select>
            )}
            {!!selectedProduct && <Configurator product={selectedProduct} create={true} />}
          </DialogContent>
        </Dialog>
      </div>
    </div>
  );
};

export default ProductInstanceCreator;
