import React from 'react';
import { MdOutlineReceipt, MdAccountCircle } from 'react-icons/md';
import { routes } from '../../../config/routes';
import { FaShoppingBag } from 'react-icons/fa';

export const useSidebarData = () => {
  /*eslint-disable*/
  const sidebardata = [
    {
      text: 'Termékek',
      icon: <FaShoppingBag fontSize={30} />,
      link: routes.GROUPS.to
    },
    {
      text: 'Címkék',
      icon: <MdAccountCircle fontSize={30} />,
      link: routes.TAGS.to
    },
    {
      text: 'Kedvezmények',
      icon: <MdOutlineReceipt fontSize={30} />,
      link: routes.DISCOUNTS.to
    }
  ];

  return {
    sidebardata
  };
};
