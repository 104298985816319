import React, { useEffect, useState } from 'react';
import { modifierDTO } from '../../../types/ProductDto';
import { Checkbox, FormControlLabel, FormGroup, TextField } from '@mui/material';

import styles from './configuratorTextField.module.css';

interface ConfiguratorTextFieldProps {
  modifier: modifierDTO;
  dtoModifiers: any;
  mementoDtoModifiers?: any;
  setDtoModifiers: any;
  disabled?: boolean;
  productId?: number;
}

const ConfiguratorTextField: React.FC<ConfiguratorTextFieldProps> = ({
  modifier,
  dtoModifiers,
  setDtoModifiers,
  mementoDtoModifiers,
  disabled,
  productId
}) => {
  const [checked, setChecked] = useState(false);
  useEffect(() => {
    if (dtoModifiers[modifier?.modifierKey] !== '') {
      setChecked(dtoModifiers[modifier?.modifierKey]?.includes('dciuser.com') ? false : true);
    }
  }, []);
  return (
    <>
      {/*eslint-disable */}
      {modifier?.optional ? (
        modifier?.modifierKey === 'domain' || modifier?.modifierKey === 'email' ? (
          <>
            <h3>{modifier?.description}</h3>
            <FormGroup style={{ width: '90%', color: 'var(--color-main) !important' }}>
              <FormControlLabel
                disabled={disabled}
                control={
                  <Checkbox checked={checked} onChange={(e: any) => (checked ? setChecked(false) : setChecked(true))} />
                }
                label={'USE_OWN'}
              />
              {checked ? (
                <TextField
                  label={modifier?.modifierKey}
                  disabled={disabled}
                  value={
                    !!dtoModifiers[0]?.id
                      ? dtoModifiers?.find((e: any) => e.id === productId)?.modifiers[modifier?.modifierKey]
                      : dtoModifiers[modifier?.modifierKey]
                  }
                  onChange={(e: any) =>
                    !!dtoModifiers[0]?.id
                      ? setDtoModifiers(
                          dtoModifiers?.map((el: any) =>
                            el.id === productId
                              ? {
                                  ...el,
                                  modifiers: {
                                    ...el.modifiers,
                                    [modifier?.modifierKey]: e.target.value
                                  }
                                }
                              : { ...el }
                          )
                        )
                      : setDtoModifiers({
                          ...dtoModifiers,
                          [modifier?.modifierKey]: e.target.value
                        })
                  }
                />
              ) : (
                <div className={styles.fieldWrapper}>
                  <TextField
                    className={styles.textfield}
                    label={modifier?.modifierKey}
                    disabled={disabled}
                    value={
                      modifier?.modifierKey === 'domain'
                        ? !!dtoModifiers[0]?.id
                          ? dtoModifiers
                              ?.find((e: any) => e.id === productId)
                              ?.modifiers[modifier?.modifierKey]?.split('.dciuser')[0]
                          : dtoModifiers[modifier?.modifierKey]?.split('.dciuser')[0]
                        : !!dtoModifiers[0]?.id
                        ? dtoModifiers
                            ?.find((e: any) => e.id === productId)
                            ?.modifiers[modifier?.modifierKey]?.split('@dciuser')[0]
                        : dtoModifiers[modifier?.modifierKey]?.split('@dciuser')[0]
                    }
                    onChange={(e: any) =>
                      !!dtoModifiers[0]?.id
                        ? setDtoModifiers(
                            dtoModifiers?.map((el: any) =>
                              el.id === productId
                                ? {
                                    ...el,
                                    modifiers: {
                                      ...el.modifiers,
                                      [modifier?.modifierKey]:
                                        modifier?.modifierKey === 'domain'
                                          ? `${e.target.value}.dciuser.com`
                                          : `${e.target.value}@dciuser.com`
                                    }
                                  }
                                : { ...el }
                            )
                          )
                        : setDtoModifiers({
                            ...dtoModifiers,
                            [modifier?.modifierKey]:
                              modifier?.modifierKey === 'domain'
                                ? `${e.target.value}.dciuser.com`
                                : `${e.target.value}@dciuser.com`
                          })
                    }
                  />
                  {modifier?.modifierKey === 'domain' ? '.' : '@'}dciuser.com
                </div>
              )}
            </FormGroup>
            {mementoDtoModifiers &&
              dtoModifiers[modifier?.modifierKey] != mementoDtoModifiers[modifier?.modifierKey] && (
                <div className={styles.textFieldMemento}>{mementoDtoModifiers[modifier?.modifierKey]}</div>
              )}
          </>
        ) : (
          <>
            <FormGroup className={styles.textfield} style={{ width: '90%', color: 'var(--color-main) !important' }}>
              <FormControlLabel
                disabled={disabled}
                control={
                  <Checkbox checked={checked} onChange={(e: any) => (checked ? setChecked(false) : setChecked(true))} />
                }
                label={modifier?.description}
              />
              {checked && (
                <TextField
                  required
                  label={modifier?.modifierKey}
                  disabled={disabled}
                  value={
                    !!dtoModifiers[0]?.id
                      ? dtoModifiers?.find((e: any) => e.id === productId)?.modifiers[modifier?.modifierKey]
                      : dtoModifiers[modifier?.modifierKey]
                  }
                  onChange={(e: any) =>
                    !!dtoModifiers[0]?.id
                      ? setDtoModifiers(
                          dtoModifiers?.map((el: any) =>
                            el.id === productId
                              ? {
                                  ...el,
                                  modifiers: {
                                    ...el.modifiers,
                                    [modifier?.modifierKey]: e.target.value
                                  }
                                }
                              : { ...el }
                          )
                        )
                      : setDtoModifiers({
                          ...dtoModifiers,
                          [modifier?.modifierKey]: e.target.value
                        })
                  }
                />
              )}
            </FormGroup>
          </>
        )
      ) : (
        <>
          <h3>{modifier?.description}</h3>
          <FormGroup className={styles.textfield} style={{ width: '90%', color: 'var(--color-main) !important' }}>
            <TextField
              required
              label={modifier?.modifierKey}
              disabled={disabled}
              value={
                !!dtoModifiers[0]?.id
                  ? dtoModifiers?.find((e: any) => e.id === productId)?.modifiers[modifier?.modifierKey]
                  : dtoModifiers[modifier?.modifierKey]
              }
              onChange={(e: any) =>
                !!dtoModifiers[0]?.id
                  ? setDtoModifiers(
                      dtoModifiers?.map((el: any) =>
                        el.id === productId
                          ? {
                              ...el,
                              modifiers: {
                                ...el.modifiers,
                                [modifier?.modifierKey]: e.target.value
                              }
                            }
                          : { ...el }
                      )
                    )
                  : setDtoModifiers({
                      ...dtoModifiers,
                      [modifier?.modifierKey]: e.target.value
                    })
              }
            />
          </FormGroup>
        </>
      )}
    </>
  );
};

export default ConfiguratorTextField;
