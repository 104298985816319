import React from 'react';
import styles from './ThumbnailUploader.module.css';
interface ThumbnailUploaderProps {
  thumbnail: any;
  onChange: any;
}

const ThumbnailUploader: React.FC<ThumbnailUploaderProps> = ({ thumbnail, onChange }) => {
  const [previewUrl, setPreviewUrl] = React.useState<any>(null);
  const handleImageChange = (e: any) => {
    const file = e.target.files[0];
    const modifiedFile = new File([file], 'thumbnail.jpg', { type: file.type });

    onChange(modifiedFile);
  };

  React.useEffect(() => {
    if (thumbnail instanceof File) {
      const reader = new FileReader();
      reader.readAsDataURL(thumbnail);
      reader.onload = () => {
        // Set the base64 string as the preview URL
        setPreviewUrl(reader.result?.toString()?.substring(reader.result.toString().indexOf(',') + 1)); // This will be a base64 string
      };
    } else {
      setPreviewUrl(thumbnail); // This will be a base64 string
    }
  }, [thumbnail]);

  return (
    <div className={styles.image_upload}>
      {previewUrl && (
        <div>
          <img src={`data:image/png;base64,${previewUrl}`} alt='Preview' className={styles.thumbnail} />
        </div>
      )}
      <input type='file' accept='image/*' name='thumbnail' onChange={handleImageChange} />
    </div>
  );
};

export default ThumbnailUploader;
