import React from 'react';
import styles from './Center.module.css';

export type CenterProps = {
  element: string;
  children: React.ReactNode;
};

// eslint-disable-next-line react/prop-types
const Center: React.FC<CenterProps> = React.memo(({ element, children, ...props }) => {
  // eslint-disable-next-line no-undef
  const Tag = element as keyof JSX.IntrinsicElements;

  return (
    <Tag className={styles.center} {...props}>
      <div>{children}</div>
    </Tag>
  );
});

Center.displayName = 'Center';

export default Center;
