import React from 'react';
import styles from './BundleCard.module.css';
import { useBundleCardLogic } from './useBundleCard';
import { Card, CardActionArea, IconButton, Switch } from '@mui/material';
import { MdDelete, MdEdit } from 'react-icons/md';
import ConfirmDialog from '../../general/confirmDialog/ConfirmDialog';
import { formatCurrency } from '../../../js/utility';
import { useNavigate } from 'react-router-dom';
import { routes } from '../../../config/routes';
import GroupDialog from '../GroupDialog/GroupDialog';
interface BundleCardProps {
  bundle: any;
}

const BundleCard: React.FC<BundleCardProps> = ({ bundle }) => {
  const {
    bundleImage,
    deleteBundle,
    setSureOpen,
    sureOpen,
    setBundleData,
    setAction,
    action,
    bundleData,
    updateBundleQuery,
    handleStatus
  } = useBundleCardLogic(bundle);
  const navigate = useNavigate();
  return (
    <Card className={styles.bundleCard}>
      <CardActionArea
        className={bundle?.isVisible ? styles.cardContent : styles.cardContentInactive}
        onClick={() => navigate(`${routes.GROUPS.to}/${bundle?.id}`)}>
        <div className={styles.bundle_sticker}>
          <div className={styles.sticker}>BUNDLE</div>
        </div>
        <div className={styles.cardTitleWrapper} style={{ opacity: bundle?.isVisible ? 1 : 0.4 }}>
          <h1 className={styles.diskCardTitle}>{bundle?.name}</h1>
        </div>
        <img
          src={bundleImage}
          alt='bundle_image'
          className={bundle?.isVisible ? styles.bundleIcon : styles.bundleIconInactive}
          width={'120px'}
        />
        <div className={styles.elements}>
          {
            /*eslint-disable */
            bundle?.products?.length > 0 ? (
              bundle?.products
                ?.sort((a: any, b: any) => a.name.toLowerCase().localeCompare(b.name.toLowerCase()))
                ?.map((m: any) => (
                  <p key={m.id} className={bundle?.isVisible ? styles.profuctInfo : styles.bundleInfoInactive}>
                    {m?.name}
                  </p>
                ))
            ) : (
              <p>No products available</p>
            )
          }
        </div>
        <div>
          <p className={bundle?.isVisible ? styles.price : styles.priceInactive}>
            {formatCurrency(bundle.prices.find((x: any) => x.currency === 'HUF')?.grossAmount * 720 ?? 0, 'HUF')}
          </p>
          <p className={bundle?.isVisible ? styles.price : styles.priceInactive}>
            {formatCurrency(bundle.prices.find((x: any) => x.currency === 'EUR')?.grossAmount * 720 ?? 0, 'EUR')}
          </p>
        </div>
      </CardActionArea>
      <div className={styles.action_group}>
        <IconButton
          onClick={() => {
            setBundleData(bundle);
            setAction('update');
          }}>
          <MdEdit />
        </IconButton>

        <Switch
          checked={bundle?.isVisible}
          className={styles.switch}
          onChange={() => handleStatus(bundle, bundle?.isVisible ? false : true)}
        />
        <IconButton
          onClick={() => {
            setSureOpen(true);
          }}>
          <MdDelete />
        </IconButton>
      </div>
      <ConfirmDialog
        open={sureOpen}
        setOpen={setSureOpen}
        action={() => deleteBundle.mutate()}
        loading={deleteBundle.isLoading}
        title='Biztos törölni szeretnéd ezt a bundle-t?'
      />

      <GroupDialog
        type={'Bundle'}
        action={action}
        setAction={setAction}
        groupData={bundleData}
        setGroupData={setBundleData}
        handleSubmit={() => updateBundleQuery.mutate()}
        loading={updateBundleQuery.isLoading}
      />
    </Card>
  );
};

export default BundleCard;
