/* eslint-disable */
import { Dialog, DialogTitle, Slide, TextField, Tooltip, useMediaQuery } from '@mui/material';
import React, { useState } from 'react';
import styles from './serviceItem.module.css';
import IconButton from '../../general/iconButton/IconButton';
import { serviceDTO } from '../../../types/ServicesDto';
import { useServiceItemLogic } from './useServiceItemLogic';
import ServiceDetailDialog from '../ServiceDetailDialog/ServiceDetailDialog';
import { MdApps, MdCreate, MdOutlineWarning } from 'react-icons/md';
import Switch from '@mui/material/Switch';
interface ServiceItemProps {
  service: any;
}

const ServiceItem: React.FC<ServiceItemProps> = ({ service }) => {
  const [open, setOpen] = useState<boolean>(false);
  const [changeName, setChangeName] = useState<boolean>(false);
  const [newName, setNewName] = useState<string>('');
  const mobile = useMediaQuery('(min-width:480px)');
  const tablet = useMediaQuery('(min-width:768px)');
  const laptop = useMediaQuery('(min-width:1200px)');
  const modifierUri = JSON.parse(service?.modifierURI);

  const {
    getUnit,
    productImage,
    product,
    configModifiers,
    instance,
    setInstance,
    patchService,
    additionals,
    setAdditionals,
    setEdit,
    edit,
    patchInstanceActive
  } = useServiceItemLogic(service, service['productId']);
  const handleCloseName = () => {
    setChangeName(false);
    setNewName('');
  };

  return (
    <>
      <div key={service['id']} className={styles.gridItem}>
        <div className={styles.vpsIcon}>
          <img
            height={!tablet ? (!mobile ? '60px' : '60px') : '60px'}
            src={productImage}
            alt='Vps_icon'
            style={{ marginLeft: '1rem' }}
          />
        </div>
        <Slide direction='right' timeout={500} in={true}>
          <div className={styles.service}>
            <div className={styles.serviceSummary}>
              <div style={{ display: 'flex', width: '100%', alignItems: 'center', height: '100%' }}>
                <div className={styles.tablecell}>
                  <p className={styles.item}>{service?.name ? service?.name : service?.productName}</p>
                </div>
                <div className={styles.hideM}>
                  <p className={styles.item}>
                    {modifierUri['cpuCores'] !== undefined ? modifierUri['cpuCores'] + ' ' + getUnit('cpuCores') : '-'}
                  </p>
                </div>
                <div className={styles.hideM}>
                  <p className={styles.item}>
                    {modifierUri['disk'] !== undefined ? modifierUri['disk'] + ' ' + getUnit('disk') : '-'}
                  </p>
                </div>
                <div className={styles.hideM}>
                  <p className={styles.item}>
                    {modifierUri['memory'] !== undefined ? modifierUri['memory'] + ' ' + getUnit('memory') : '-'}
                  </p>
                </div>
                <div className={styles.hideM}>
                  <p className={styles.item}>{modifierUri['backup'] !== undefined ? modifierUri['backup'] : '-'}</p>
                </div>
                <div className={styles.hideM}>
                  <p className={styles.item}>
                    {modifierUri['operatingSystem'] !== undefined ? modifierUri['operatingSystem'] : '-'}
                  </p>
                </div>
                <div className={styles.hideM}>
                  <p className={styles.item}>{JSON.parse(service?.modifierURI)['vpn'] == 1 ? 'Yes' : '-'}</p>
                </div>
                <div className={styles.hideM}>
                  <p className={styles.item}>{JSON.parse(service?.modifierURI)['publicIp'] == 1 ? 'Yes' : '-'}</p>
                </div>
                <div className={styles.hideXS}>
                  <Switch
                    style={{ color: instance?.isActive ? 'green' : 'red' }}
                    checked={instance?.isActive ?? false}
                    onChange={() => {
                      setInstance({ ...instance, isActive: !instance?.isActive });
                      patchInstanceActive.mutate();
                    }}></Switch>
                  {instance?.isUnderModification ? (
                    <Tooltip title='Módosítás igényelve'>
                      <div>
                        <MdOutlineWarning color='#FFC107' size={'25px'}></MdOutlineWarning>
                      </div>
                    </Tooltip>
                  ) : (
                    <div className={styles.dot} style={{ backgroundColor: 'var(--color-grey)' }}></div>
                  )}
                </div>
              </div>

              {/*eslint-enable*/}
            </div>{' '}
          </div>
        </Slide>
        {!laptop ? (
          <button
            className={styles.modificationWidget}
            onClick={() => {
              setOpen(true);
            }}>
            <MdApps
              style={{
                color: 'var(--color-main-red)'
              }}></MdApps>
          </button>
        ) : (
          <button
            className={styles.modificationWidget}
            onClick={() => {
              setOpen(true);
            }}>
            {'Részletek'}
          </button>
        )}
        {instance && (
          <ServiceDetailDialog
            open={open}
            setOpen={setOpen}
            instance={instance}
            setInstance={setInstance}
            product={product}
            getUnit={getUnit}
            patchInstance={patchService}
            patchInstanceActive={patchInstanceActive}
            additionals={additionals}
            setAdditionals={setAdditionals}
            setEdit={setEdit}
            edit={edit}
          />
        )}
      </div>
    </>
  );
};

export default ServiceItem;
