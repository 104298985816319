import { useMutation } from '@tanstack/react-query';
import React from 'react';
import queries from '../../../api/queries/ProductQueries';
import { useToasterLogic } from '../../../utils/useToasterLogic';

export const useUploaderLogic = (id: any, setImage: any, baseUrl: string, parentId?: number) => {
  const { successToast } = useToasterLogic();
  const [loading, setLoading] = React.useState<any>();
  const [imageList, setImageList] = React.useState<any>();
  const [progress, setProgress] = React.useState<number>();

  const coverDelete = useMutation({
    mutationFn: () =>
      queries.deleteImage({
        url: parentId ? `${baseUrl}/${parentId}/modifiers/${id}/image` : `${baseUrl}/${id}/image`
      }),
    onSuccess: () => {
      successToast('Sikeres törlés');
      setLoading(false);
    }
  });

  const onChange = (imageFileList: any, addUpdateIndex: any) => {
    // data for submit
    console.log(imageFileList, addUpdateIndex);
    setImageList(imageFileList);
    if (imageFileList[0]) {
      setImage(imageFileList[0]['data_url']);
    } else {
      setImage(null);
    }
  };

  const onProgress = (uid: any, progress: any) => {
    setProgress(progress);
  };

  const onSuccess = (uid: any, progress: any) => {
    console.log('progress: ', uid, progress);
  };

  const handleUpload = async () => {
    await queries.uploadRequest({
      setLoading: setLoading,
      file: imageList[0].file,
      onProgress: onProgress,
      onSuccess: onSuccess,
      url: parentId ? `${baseUrl}/${parentId}/modifiers/${id}/image` : `${baseUrl}/${id}/image`
    });
    setImageList(null);
  };

  const handleImageDelete = () => {
    coverDelete.mutate();
  };
  return { loading, progress, handleUpload, handleImageDelete, onChange, imageList };
};
