import React, { useState } from 'react';
import styles from './serviceDetailDialog.module.css';
import IconButton from '../../general/iconButton/IconButton';
import { Dialog, DialogContent } from '@mui/material';
import x from '../../../assets/images/x.svg';
import InstanceForm from '../InstanceForm/InstanceForm';
import Switch from '@mui/material/Switch';
import Configurator from '../../configuratorComponents/configurator/Configurator';

interface ServiceDetailDialogProps {
  open: boolean;
  setOpen: any;
  instance: any;
  setInstance: any;
  product: any;
  getUnit: (_text: string) => string;
  patchInstance: any;
  patchInstanceActive: any;
  additionals: any;
  setAdditionals: any;
  edit: boolean;
  setEdit: any;
}

const ServiceDetailDialog: React.FC<ServiceDetailDialogProps> = ({
  open,
  setOpen,
  instance,
  setInstance,
  product,
  getUnit,
  patchInstance,
  patchInstanceActive,
  additionals,
  setAdditionals,
  edit,
  setEdit
}) => {
  const [isConfigurator, setIsConfigurator] = useState<boolean>(false);

  return (
    <Dialog
      open={open}
      onClose={() => {
        setOpen(false);
        setEdit(false);
        setIsConfigurator(false);
        setAdditionals(instance?.additionalAttributes);
      }}
      style={{ marginTop: '5rem' }}>
      <div
        style={{
          position: 'relative',
          width: '100%',
          display: 'flex',
          justifyContent: 'flex-end',
          background: 'var(--color-main)'
        }}>
        <IconButton
          icon={<img src={x} alt='x_icon' width={'50px'} />}
          onClick={() => {
            setOpen(false);
            setAdditionals(instance?.additionalAttributes);
            setEdit(false);
            setIsConfigurator(false);
          }}
          size={30}
          color={'var(--color-main-red)'}
        />
      </div>
      <DialogContent className={styles.dialogContent}>
        <div>
          <h3>{'Részletek'}</h3>
          <div style={{ color: '#FF5067' }}>
            {instance?.isToBeDeleted ? 'Törlés alatt' : instance?.isDeleted ? 'Törölve' : ''}
          </div>
          <div style={{ color: '#FFC107' }}>{instance?.isUnderModification ? 'Módosítás igényelve' : ''}</div>
        </div>
        <div className={styles.details}>
          {/*
          IF DCID WILL BE A REAL THING
          <div className={styles.detailRow}>
            <p className={styles.rowItemTitle}>DCID:</p>
            <p className={styles.rowItem}>{instance?.cloudId}</p>
          </div> */}
          <div className={styles.detailRow}>
            <p className={styles.rowItemTitle}>{'Szolgáltatás neve'}:</p>
            <p className={styles.rowItem}>{instance?.name}</p>
          </div>
          <div className={styles.detailRow}>
            <p className={styles.rowItemTitle}>{'Szolgáltatás típusa'}:</p>
            <p className={styles.rowItem}>{product?.name}</p>
          </div>
          <div className={styles.detailRow}>
            <p className={styles.rowItemTitle}>{'Szolgáltatás státusza'}:</p>
            <p className={styles.rowItem}>
              <Switch
                style={{ color: instance?.isActive ? 'green' : 'red' }}
                checked={instance?.isActive}
                onChange={() => {
                  setInstance({ ...instance, isActive: !instance?.isActive });
                  patchInstanceActive.mutate();
                }}></Switch>
            </p>
          </div>
          {Object.keys(JSON.parse(instance?.modifierURI))
            ?.sort((a: string, b: string) => a.toLowerCase().localeCompare(b.toLowerCase()))
            ?.map(
              (k: string) =>
                k !== 'vlan' &&
                k !== 'publicIp' && (
                  <div key={`${k}_detail`} className={styles.detailRow}>
                    <p className={styles.rowItemTitle}>{k}:</p>
                    <p className={styles.rowItem}>
                      {JSON.parse(instance?.modifierURI)[k]} {getUnit(k)}
                    </p>
                  </div>
                )
            )}
        </div>
        {isConfigurator ? (
          <Configurator
            product={product}
            instance={instance}
            closeAction={() => setIsConfigurator(false)}></Configurator>
        ) : (
          <button
            className={styles.button}
            onClick={() => setIsConfigurator(true)}
            disabled={instance?.isToBeDeleted && !instance?.isDeleted}>
            {'Konfigurálás'}
          </button>
        )}
        <div>
          <h3>{'Eléréshez szükséges adatok'}</h3>
        </div>
        <InstanceForm
          handleSubmit={() => patchInstance.mutate()}
          loading={patchInstance.isLoading}
          setAdditionals={setAdditionals}
          additionals={additionals}
          oriAdditionals={instance?.additionalAttributes}
          edit={edit}
          setEdit={setEdit}
        />
      </DialogContent>
    </Dialog>
  );
};

export default ServiceDetailDialog;
