import React from 'react';
import { useEffect } from 'react';
import { useState } from 'react';
import KeyValueEditor from './KeyValueEditor/KeyValueEditor';
import StringEditor from './StringEditor/StringEditor';
// Currently Not Needed Imports
// import DateEditor from './DateEditor/DateEditor';
// import DeliveryTargetEditor from './DeliveryTargetEditor/DeliveryTargetEditor';
// import SelectEditor from './SelectEditor/SelectEditor';
// import GroupNameEditor from './GroupNameEditor/GroupNameEditor';
// import StatusEditor from './StatusEditor/StatusEditor';
import { QueryOptionDto } from '../../../../types/QueryBuilderDtos';

interface QueryParamEditorParams {
  tempOption: QueryOptionDto;
  setTempOption: any;
}

const QueryParamEditor: React.FC<QueryParamEditorParams> = ({ tempOption, setTempOption }) => {
  // types = ['string', 'date', 'select', 'key-value'];

  const [editor, setEditor] = useState<any>();

  /*eslint-disable*/
  const getEditor = (tempOption: QueryOptionDto) => {
    switch (tempOption.type) {
      case 'string':
        return <StringEditor option={tempOption} setOption={setTempOption} />;
      case 'key-value':
        return <KeyValueEditor option={tempOption} setOption={setTempOption} />;
      //case 'date':
      //  return <DateEditor option={tempOption} setOption={setTempOption} />;
      // Currently Not Needed Cases
      // case 'reportingDate':
      //   return <SelectEditor option={tempOption} setOption={setTempOption} />;
      // case 'status':
      //   return <StatusEditor option={tempOption} setOption={setTempOption} />;
      // case 'deliveryTarget':
      //   return <DeliveryTargetEditor option={tempOption} setOption={setTempOption} />;
      // case 'groupName':
      //   return <GroupNameEditor option={tempOption} setOption={setTempOption} />;
      default:
        break;
    }
  };

  useEffect(() => {
    if (tempOption) {
      setEditor(getEditor(tempOption));
    }
  }, [tempOption]);

  return <div onClick={(e) => e.stopPropagation()}>{editor || <></>}</div>;
};

export default QueryParamEditor;
