import { useQuery } from '@tanstack/react-query';
import userQueries from '../../api/queries/UserQueries';
import compartmentQueries from '../../api/queries/SubscriptionQueries';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { routes } from '../../config/routes';
import useGeneralStore from '../../store/GeneralStore';

export const useUsersLogic = () => {
  const [users, setUsers] = useState<any>();

  const { setCompartments }: any = useGeneralStore((state) => ({
    setCompartments: state.setCompartments
  }));

  const navigate = useNavigate();

  /*eslint-disable*/

  const userFetchQuery = useQuery({
    queryKey: ['users'],
    queryFn: () => userQueries.fetchAllUsers(),
    onSuccess: (data: any) => {
      setUsers(data);
    }
  });

  const compartmentFetchQuery = useQuery({
    queryKey: ['compartments'],
    queryFn: () => compartmentQueries.fetchAllCompartments(),
    onSuccess: (data: any) => {
      setCompartments(data);
    },

    retry: (failureCount, error: any) => (error.response.status === 404 ? false : true),
    refetchOnWindowFocus: false,    
  });

  const routeChange = (id: any) => {
    navigate(`${routes.USERS.to}/${id}`)
  }

  return { users, routeChange };
};
