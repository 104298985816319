import { useGetQueryString } from '../../../utils/useGetQueryString';
import React from 'react';
import { useState } from 'react';
import QueryOptions from './config/QueryOptions';
import QueryParam from './QueryParam/QueryParam';
import styles from './queryBuilder.module.css';
import { useEffect } from 'react';
import PaginationEditor from './QueryParamEditor/PaginationEditor/PaginationEditor';
import { useLocation, useNavigate } from 'react-router-dom';
import { QueryOptionDto } from '../../../types/QueryBuilderDtos';
import { Button, Tooltip, Typography } from '@mui/material';
import { MdAdd, MdRefresh, MdRemove, MdRestartAlt } from 'react-icons/md';

interface QueryBuilderProps {
  itemsLength: number;
  showAll?: boolean;
  children: any;
  reset?: any;
  refresh?: any;
}

const QueryBuilder: React.FC<QueryBuilderProps> = ({ itemsLength, showAll, children, reset, refresh }) => {
  const { search } = useLocation();
  const navigate = useNavigate();

  //TODO check value validity
  const getInitialOptions = (options: QueryOptionDto[]) => {
    const searchParams = new URLSearchParams(search);

    return options?.map((o) => {
      if (search.includes(o.name)) {
        let value = searchParams.get(o.name);
        if (value === null) {
          // it might be a date
          let fromValue = searchParams.get(`${o.name}From`);
          let toValue = searchParams.get(`${o.name}To`);
          if (fromValue && toValue) {
            return {
              ...o,
              used: true,
              value: `${o.name}From=${fromValue}&${o.name}To=${toValue}`
            };
          }

          return { ...o, used: false, value: '' };
        }
        return { ...o, used: true, value: `${o.name}=${value}` };
      } else if (search.includes('fromDateTime')) {
        let value = searchParams.get('fromDateTime');
        let value2 = searchParams.get('toDateTime');
        if (value === null) {
          // it might be a date
          let fromValue = searchParams.get(`fromDateTime`);
          let toValue = searchParams.get(`toDateTime`);
          if (fromValue && toValue) {
            return {
              ...o,
              used: true,
              value: `fromDateTime=${fromValue}&toDateTime=${toValue}`
            };
          }

          return { ...o, used: false, value: '' };
        }
        return {
          ...o,
          used: true,
          value: `fromDateTime=${value}&toDateTime=${value2}`
        };
      } else {
        if (o.name === 'pagination') {
          let size = searchParams.get(`size`);
          let page = searchParams.get(`page`);
          if (showAll) {
            return {
              ...o,
              used: true,
              value: `size=${itemsLength}&page=${1}`
            };
          }
          if (size && page) {
            // it is a pagination data
            return {
              ...o,
              used: true,
              value: `size=${size}&page=${page}`
            };
          }
        }
        return { ...o, used: false, value: '' };
      }
    });
  };

  const { getQueryString } = useGetQueryString();
  const [options, setOptions] = useState<any>(getInitialOptions(QueryOptions));
  const [currentQueryString, setCurrentQueryString] = useState(search);
  const [isAddDisabled, setIsAddDisabled] = useState(false);
  const [isAddOptionOpen, setIsAddOptionOpen] = useState(false);

  const toggleIsAddOptionOpen = () => setIsAddOptionOpen(!isAddOptionOpen);

  useEffect(() => {
    setCurrentQueryString(getQueryString(options));
  }, [options]);

  useEffect(() => {
    if (search !== currentQueryString) {
      navigate(`/subscriptions?${currentQueryString}`);
    }
  }, [currentQueryString]);
  useEffect(() => {
    setOptions(getInitialOptions(QueryOptions));
    if (search !== currentQueryString) {
      setCurrentQueryString(search);
    }
  }, [search, showAll]);

  const setOption = (option: QueryOptionDto) => {
    setOptions(
      options.map((currentOption: QueryOptionDto) => {
        if (currentOption.name === option.name) {
          return option;
        } else {
          return currentOption;
        }
      })
    );
  };

  const disableOption = (option: QueryOptionDto) => {
    setOptions(
      options.map((currentOption: QueryOptionDto) => {
        if (currentOption.name === option.name) {
          currentOption.used = false;
          currentOption.value = '';
        }
        return currentOption;
      })
    );
  };
  const enableOption = (name: string) => {
    setOptions(
      options.map((currentOption: QueryOptionDto) => {
        if (currentOption.name === name) {
          currentOption.used = true;
        }
        return currentOption;
      })
    );

    setIsAddOptionOpen(false);
  };

  return (
    <div style={{ display: 'flex', alignItems: 'center', width: '100%' }}>
      <div className={styles.normalWrapper}>
        <div className={styles.searchWrapper}>
          <div className={styles.titleWrapper}>
            <Typography
              variant='h2'
              style={{
                color: 'var(--color-main)',
                fontFamily: 'var(--font-main)',
                fontSize: '2.5rem'
              }}>
              Keresés
            </Typography>
            <PaginationEditor options={options} setOptions={setOptions} itemsLength={itemsLength} />
          </div>
          <div className={styles.paramWrapper}>
            {options.map(
              (option: QueryOptionDto) =>
                option.used &&
                option.type !== 'pagination' && (
                  <QueryParam
                    key={`${option.name}_queryparam`}
                    option={{ ...option }}
                    setOption={setOption}
                    disableOption={() => disableOption(option)}
                    setIsAddDisabled={setIsAddDisabled}
                  />
                )
            )}
          </div>

          <div className={styles.addWrapper}>
            <Tooltip title={'Keresési szűrő hozzáadása'}>
              <Button
                className={styles.button}
                variant='outlined'
                onClick={toggleIsAddOptionOpen}
                disabled={isAddDisabled}>
                {isAddOptionOpen ? (
                  <MdRemove style={{ fontSize: '1.5rem' }} />
                ) : (
                  <MdAdd style={{ fontSize: '1.5rem' }} />
                )}
                Szűrő hozzáadása
              </Button>
            </Tooltip>
            {isAddOptionOpen && (
              <div className={styles.optionsWrapper}>
                {options.map(
                  (option: QueryOptionDto) =>
                    !option.used &&
                    option.type !== 'pagination' && (
                      <div
                        className={styles.option}
                        key={`${option.name}_option`}
                        onClick={() => enableOption(option.name)}>
                        {option.displayName}
                      </div>
                    )
                )}
              </div>
            )}
          </div>
          <Tooltip title={'Keresés alaphelyzetbe állítása'}>
            <Button
              className={styles.button}
              variant='outlined'
              onClick={() => {
                reset();
              }}>
              <MdRestartAlt style={{ fontSize: '1.5rem' }} />
              Szűrők törlése
            </Button>
          </Tooltip>
          <Tooltip title={'Tételek frissítése'}>
            <Button className={styles.button} variant='outlined' onClick={() => refresh()}>
              <MdRefresh style={{ fontSize: '1.5rem' }} />
              Feliratkozások frissítése
            </Button>
          </Tooltip>
        </div>

        <div className={styles.actionsWrapper}>
          <div>
            {
              // Placeholder.
            }
          </div>
        </div>
        {children}
        <PaginationEditor options={options} setOptions={setOptions} itemsLength={itemsLength} />
      </div>
    </div>
  );
};

export default QueryBuilder;
