import React, { useState } from 'react';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import queries from '../../../api/queries/ProductQueries';
import { useToasterLogic } from '../../../utils/useToasterLogic';

export const useProductCardLogic = (product: any) => {
  const [sureOpen, setSureOpen] = useState<boolean>(false);
  const [productImage, setProductImage] = useState<any>();
  const { errorToast, successToast } = useToasterLogic();
  const [newModifiers, setNewModifiers] = React.useState<any>();
  const [paymentType, setPaymentType] = React.useState<string>('RECURRING_PAYMENT');
  const queryClient = useQueryClient();

  const getTotalPrice = (product: any, currency: string) => {
    let totalPrice = 0;
    if (product) {
      product?.modifiers?.forEach((modi: any) => {
        const price = modi.prices?.find((p: any) => p.currency === currency);
        if (price == undefined) return null;
        if (modi.modifierCategory === 'SLIDER') {
          totalPrice = totalPrice + price.grossAmount * modi.defaultValue;
        } else {
          totalPrice = totalPrice + price.grossAmount;
        }
      });
    }
    return (totalPrice * 720).toFixed(2);
  };

  React.useEffect(() => {
    if (product) {
      let modis: any = {};
      product?.modifiers?.forEach((m: any) => {
        if (m?.possibleValues?.length > 0) {
          modis = { ...modis, [m.modifierKey]: m?.possibleValues[0] };
        } else {
          modis = { ...modis, [m.modifierKey]: m?.defaultValue };
        }
      });

      setNewModifiers(modis);
    }
  }, [product]);

  const productImageFetchQuery = useQuery({
    queryKey: ['productImage', product?.id],
    queryFn: () => queries.fetchProductImage({ id: product?.id }),
    onSuccess: async (data) => {
      let image = await queries.convertBlobToBase64(await data);
      setProductImage(image);
    },
    retry: (failureCount, error: any) => (error.response.status === 404 ? false : true),
    refetchOnWindowFocus: false,
    enabled: !!product?.id
  });

  const deleteProduct = useMutation({
    mutationFn: () => queries.deleteProduct({ id: product?.id }),
    onSuccess: () => {
      setSureOpen(false);
      successToast('Sikeres termék törlés');
      queryClient.invalidateQueries(['group']);
    },
    onError: () => {
      errorToast('Sikertelen termék törlés!');
    }
  });

  return {
    setNewModifiers,
    getTotalPrice,
    paymentType,
    setPaymentType,
    newModifiers,
    productImageFetchQuery,
    productImage,
    setProductImage,
    deleteProduct,
    setSureOpen,
    sureOpen
  };
};
