/**
 * Select a HTML element by it's ID.
 *
 * @param {*} id    The HTML element's ID.
 * @returns         The selecthed element
 */
export const s = (id) => document.getElementById(id);

/**
 * Selects all HTML elements by a given class name.
 *
 * @param {*} className     The elements className.
 * @returns                 HTMLNodeCollection of the selected elements.
 */
export const cs = (className) => document.getElementsByClassName(className);

/**
 * Returns a Promise, which resolves in X milliseconds.
 * Can be used to wait for animations, etc...
 *
 * @param {*} ms    The time to resolve the Promise.
 * @returns         A Promise.
 */
export const wait = (ms) => new Promise((resolve) => setTimeout(resolve, ms));

/**
 * Returns a formatted price to display in frontend
 *
 * @param {*} price   Unformatted number
 * @returns         Formatted price like 1,234,567
 */
export const formatCurrency = (amount, currency) => {
  if (amount === undefined || amount === null) {
    return '';
  }

  const minimumFractionDigits = currency === 'HUF' ? 0 : 2;

  let formattedAmount = Number(amount).toFixed(minimumFractionDigits);

  const parts = formattedAmount.split('.');
  parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ' ');
  formattedAmount = parts.join('.');

  return `${formattedAmount} ${currency}`;
};
