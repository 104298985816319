import React from 'react';
import styles from './iconButton.module.css';

export type IconButtonProps = {
  onClick: any;
  icon: any;
  size?: number;
  color?: string;
  disabled?: boolean;
};

const IconButton: React.FC<IconButtonProps> = ({ onClick, icon, size, color, disabled }) => {
  return (
    <button
      disabled={disabled}
      className={styles.iconBtn}
      onClick={onClick}
      style={{
        fontSize: `${size}px`,
        color: color,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        cursor: disabled ? 'unset' : 'pointer'
      }}>
      {icon}
    </button>
  );
};

export default IconButton;
