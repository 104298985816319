import { useQuery, useQueryClient } from '@tanstack/react-query';
import queries from '../../api/queries/SubscriptionQueries';
import { useEffect } from 'react';
import { useToasterLogic } from '../../utils/useToasterLogic';
import { useLocation } from 'react-router-dom';

export const useSubscriptionsLogic = () => {
  let { search } = useLocation();

  const queryClient = useQueryClient();

  const { errorToast } = useToasterLogic();

  const subscriptionsQuery = useQuery<any, any>({
    queryKey: ['subscriptions', search],
    queryFn: () => queries.getAllSubscription({ search }),
    onError: (e: any) => {
      errorToast(e?.message);
    },
    refetchOnWindowFocus: false
  });

  const reset = () => {
    queryClient.invalidateQueries(['subscriptions', '?size=10&page=1&sort=id,asc']);
  };

  const refresh = () => {
    queryClient.invalidateQueries(['subscriptions', search]);
  };

  useEffect(() => {
    refresh();
  }, [search]);

  return { subscriptionsQuery, refresh, reset };
};
