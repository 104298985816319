import axios from 'axios';
import AuthService from '../services/AuthService';
import ENV from './env';

export const api = async ({ baseUrl, auth }) => {
  const instance = axios.create({
    baseURL: baseUrl,
    timeout: parseInt(ENV.REACT_APP_AXIOS_TIMEOUT)
  });

  instance.interceptors.request.use(async (config) => {
    const authHeader = await AuthService.authHeader();
    if (authHeader && auth) {
      config.headers['Authorization'] = authHeader;
    }
    return config;
  });

  instance.interceptors.response.use(
    (response) => {
      return response.data; // return only the response data
    },
    async function (error) {
      const originalRequest = error.config;
      if (error.response.status === 401 && !originalRequest._retry) {
        originalRequest._retry = true;
        await AuthService.refreshToken();
        const authHeader = await AuthService.authHeader();
        axios.defaults.headers.common['Authorization'] = authHeader;
        return instance(originalRequest);
      }
      return Promise.reject(error);
    }
  );

  return instance;
};
