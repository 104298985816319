import React from 'react';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import productQueries from '../../../api/queries/ProductQueries';
import subscriptionQueries from '../../../api/queries/SubscriptionQueries';
import useGeneralStore from '../../../store/GeneralStore';
import { modifierDTO } from '../../../types/ProductDto';
import { useToasterLogic } from '../../../utils/useToasterLogic';

export const useServiceItemLogic = (service: any, productId: number) => {
  const queryClient = useQueryClient();
  const [additionals, setAdditionals] = React.useState<any>(service?.additionalAttributes);

  const [open, setOpen] = React.useState<boolean>(false);
  const [edit, setEdit] = React.useState<boolean>(false);
  const [productImage, setProductImage] = React.useState<any>();
  const [product, setProduct] = React.useState<any>();
  const [configModifiers, setConfigModifiers] = React.useState<any>();

  const [instance, setInstance] = React.useState<any>();

  const { successToast, errorToast } = useToasterLogic();

  const { modifierKeys, subscription } = useGeneralStore((state: any) => ({
    modifierKeys: state.modifierKeys,
    subscription: state.subscription
  }));

  const getUnit = (item: string) => {
    return (modifierKeys as any)?.find((k: any) => k.name == item)?.unitOfMeasurement === 'GB'
      ? 'GB'
      : (modifierKeys as any)?.find((k: any) => k.name == item)?.unitOfMeasurement;
  };

  /*eslint-disable*/

  const productImageFetchQuery = useQuery({
    queryKey: ['productImage', productId],
    queryFn: () => productQueries.fetchProductImage({ id: productId }),
    onSuccess: async (data: Blob) => {
      let image = await productQueries.convertBlobToBase64(await data);
      setProductImage(image);
    },
    // retry: (failureCount, error: any) => (error.response.status === 404 ? false : true),
    refetchOnWindowFocus: false,
    enabled: !!productId
  });
  const productFetchQuery = useQuery({
    queryKey: ['product', productId],
    queryFn: () => productQueries.fetchProductById({ productId: productId, currency: 'EUR' }),
    onSuccess: async (data: any) => {
      setProduct(data);
    },
    // retry: (failureCount, error: any) => (error.response.status === 404 ? false : true),
    refetchOnWindowFocus: false,
    enabled: !!productId
  });

  const deleteService = useMutation({
    mutationFn: async () =>
      subscriptionQueries.removeService({
        compId: subscription?.compartmentId,
        serviceID: service['id']
      }),
    onSuccess() {
      queryClient.invalidateQueries(['subscription']);
      successToast('Sikeres törlés');
    },
    onError(error: any) {
      errorToast(error.response.data.message);
    }
  });
  const patchService = useMutation({
    mutationFn: async () =>
      subscriptionQueries.patchProductInstance({
        pIId: service['id'],
        config: { additionalAttributes: additionals }
      }),
    onSuccess() {
      queryClient.invalidateQueries(['subscription']);
      successToast('Sikeres módosítás');
      setEdit(false)
    },
    onError(error: any) {
      errorToast(error.response.data.message);
    }
  });
  const patchInstanceActive = useMutation({
    mutationFn: async () =>
      subscriptionQueries.patchInstanceActive({
        compId: subscription?.compartmentId,
        pIId: service['id'],
        config: { isActive: instance.isActive }
      }),
    onSuccess() {
      queryClient.invalidateQueries(['subscription']);
      successToast('Sikeres módosítás');
      setEdit(false)
    },
    onError(error: any) {
      errorToast(error.response.data.message);
    }
  });

  React.useEffect(() => {
    if (service && product) {
      let modifiers = product?.modifiers;
      modifiers = modifiers?.map((m: modifierDTO) => {
        return { ...m, defaultValue: JSON.parse(service['modifierURI'])[m.modifierKey] };
      });
      setConfigModifiers(modifiers);
    }
    if (service) {
      setInstance(service);
    }
  }, [service, product]);

  return {
    deleteService,
    open,
    setOpen,
    getUnit,
    productImage,
    product,
    configModifiers,
    instance,
    setInstance,
    patchService,
    setEdit,
    edit,
    patchInstanceActive,
    setAdditionals,
    additionals
  };
};
