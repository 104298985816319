import KeyCloak from 'keycloak-js';
import ENV from '../api/env';

const kc = new KeyCloak({
  realm: ENV.REACT_APP_KC_REALM,
  url: ENV.REACT_APP_KC_URL,
  clientId: ENV.REACT_APP_KC_CLIENT_ID
});

const initKeyCloak = (onInitCallback: () => void, onFailCallback: () => void) => {
  kc?.init({
    onLoad: 'check-sso',
    pkceMethod: 'S256'
  })
    .then(() => {
      onInitCallback();
    })
    .catch(() => {
      onFailCallback();
    });
};

const login = (redirectUrl?: any) => {
  kc!.login({
    redirectUri: redirectUrl ? redirectUrl : `${ENV.REACT_APP_KC_REDIRECT_URI}`
  });
};
const logout = () => {
  kc!.logout({
    redirectUri: `${ENV.REACT_APP_KC_REDIRECT_URI}`
  });
};
const getToken = () => kc?.token;

const refreshToken = () => kc.updateToken(5).catch(login);

const register = () => {
  kc!.register({
    redirectUri: `${ENV.REACT_APP_KC_REDIRECT_URI}`
  });
};

const getUsername = () => kc.tokenParsed?.preferred_username;
const getFirstName = () => kc.tokenParsed?.given_name;
const isLoggedIn = () => !!kc.token;
const hasRole = (role: string) => kc?.tokenParsed?.realm_access?.roles.includes(role);

const authHeader = () => {
  const token = getToken();
  return token ? `Bearer ${token}` : null;
};

const AuthService = {
  initKeyCloak,
  isLoggedIn,
  authHeader,
  login,
  logout,
  register,
  refreshToken,
  getToken,
  getUsername,
  getFirstName,
  hasRole
};

export default AuthService;
