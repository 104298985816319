import { MenuItem, Select } from '@mui/material';
import React from 'react';
import { useEffect } from 'react';
import { useState } from 'react';
import { QueryOptionDto } from '../../../../../types/QueryBuilderDtos';
import styles from './keyValueEditor.module.css';

interface KeyValueEditorProps {
  option: QueryOptionDto;
  setOption: any;
}

const KeyValueEditor: React.FC<KeyValueEditorProps> = ({ option, setOption }) => {
  const [currentKey, setCurrentKey] = useState(option.value === '' ? -1 : option.value.split('=')[1].split(',')[0]);
  const [currentValue, setCurrentValue] = useState(option.value === '' ? -1 : option.value.split('=')[1].split(',')[1]);

  useEffect(() => {
    if (currentKey !== -1 && currentValue !== -1) {
      setOption({
        ...option,
        value: `${option.name}=${currentKey},${currentValue}`
      });
    }
  }, [currentKey, currentValue]);

  return (
    <div style={{ width: '100%' }}>
      {(option as any)?.keys?.length > 0 ? (
        <Select
          required
          variant='outlined'
          onChange={(e) => {
            setCurrentKey(e.target.value);
          }}
          value={currentKey}
          fullWidth
          style={{
            fontFamily: `var(--font-main)`,
            fontSize: '0.9rem'
          }}>
          <MenuItem className={styles.menuItem} key={`default_template_option_key`} value={-1} disabled>
            Kérem válasszon...
          </MenuItem>
          {(option as any)?.keys?.map((key: any) => (
            <MenuItem key={`${option.name}_option_key`} value={key.name} className={styles.menuItem}>
              {key.displayName}
            </MenuItem>
          ))}
        </Select>
      ) : (
        <Select
          variant='outlined'
          defaultValue={-1}
          fullWidth
          style={{
            fontFamily: `var(--font-main)`,
            width: '100%',
            fontSize: '0.9rem'
          }}>
          <MenuItem key={`no_items`} value={-1} disabled className={styles.menuItem}>
            Nincs megjeleníthető elem
          </MenuItem>
        </Select>
      )}
      {(option as any)?.values?.length > 0 ? (
        <Select
          required
          variant='outlined'
          onChange={(e) => {
            setCurrentValue(e.target.value);
          }}
          value={currentValue}
          fullWidth
          style={{
            fontFamily: `var(--font-main)`,
            width: '100%',
            fontSize: '0.9rem'
          }}>
          <MenuItem key={`default_template_option`} value={-1} disabled className={styles.menuItem}>
            Kérem válasszon...
          </MenuItem>
          {(option as any)?.values?.map((value: any) => (
            <MenuItem key={`${option.name}_option`} value={value.name} className={styles.menuItem}>
              {value.displayName}
            </MenuItem>
          ))}
        </Select>
      ) : (
        <Select
          variant='outlined'
          defaultValue={-1}
          fullWidth
          style={{
            fontFamily: `var(--font-main)`,
            width: '100%',
            fontSize: '0.9rem'
          }}>
          <MenuItem key={`no_items`} value={-1} disabled className={styles.menuItem}>
            Nincs megjeleníthető elem
          </MenuItem>
        </Select>
      )}
    </div>
  );
};

export default KeyValueEditor;
