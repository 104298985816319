import { useEffect, useState } from 'react';
import { modifierDTO } from '../../../types/ProductDto';

export const useModifierLogic = (modifier: modifierDTO | 'new') => {
  const [modifierData, setModifierData] = useState<any>({ optional: false, id: +(Math.random() * 100).toFixed(0) });

  useEffect(() => {
    if (modifier !== 'new') {
      setModifierData(modifier);
    } else {
      setModifierData({ optional: false, id: +(Math.random() * 100).toFixed(0) });
    }
  }, [modifier]);

  return {
    setModifierData,
    modifierData
  };
};
