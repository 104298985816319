import * as React from 'react';
import styles from './htmlEditor.module.css';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';

interface HtmlEditorProps {
  value: string;
  customKey: any;
  onChange: any;
}

const HtmlEditor: React.FC<HtmlEditorProps> = ({ value, customKey, onChange }) => {
  return (
    <div style={{ overflowX: 'hidden', padding: '1rem 0' }}>
      <ReactQuill
        key={customKey}
        className={styles.editor}
        theme='snow'
        value={value}
        onChange={(e) => {
          onChange(e);
        }}
        modules={{
          toolbar: [
            ['bold', 'italic', 'underline', 'strike'], // toggled buttons
            [{ list: 'ordered' }, { list: 'bullet' }],
            [{ header: [1, 2, 3, 4, 5, 6, false] }],
            [{ color: [] }, { background: [] }], // dropdown with defaults from theme
            ['image', 'link', 'video'],
            ['clean'] // remove formatting button
          ]
        }}
      />
    </div>
  );
};

export default HtmlEditor;
