import queries from '../../api/queries/TagsQueries';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { useToasterLogic } from '../../utils/useToasterLogic';
import { useState } from 'react';

export const useTagsLogic = () => {
  const [tags, setTags] = useState<any[]>([]);
  const [products, setProducts] = useState<any[]>([]);

  const [productFetch, setProductFetch] = useState<boolean>(false);

  const [newTagText, setNewTagText] = useState<string>('');
  const [findTagText, setFindTagText] = useState<string>('');

  const queryClient = useQueryClient();

  const { successToast, errorToast } = useToasterLogic();

  const putTag = useMutation({
    mutationFn: (id) => queries.putTag({ tagID: id, tagsDTO: newTagText }),
    onSuccess: () => {
      queryClient.invalidateQueries(['tags']);
      successToast('Sikeres módosítás');
      console.log(newTagText);
    },
    onError: () => {
      console.log(newTagText);
      errorToast('Sikertelen módosítás');
    }
  });

  const deleteTag = useMutation({
    mutationFn: (id) => queries.deleteTag({ tagID: id }),
    onSuccess: () => {
      queryClient.invalidateQueries(['tags']);
      successToast('Sikeres törlés');
    },
    onError: () => {
      errorToast('Sikertelen törlés');
    }
  });

  const fetchTags = useQuery({
    queryKey: ['tags'],
    queryFn: () => queries.fetchTags(),
    onSuccess: (data: any) => {
      setTags(data);
    },
    retry: (failureCount, error: any) => (error.response.status === 404 ? false : true),
    refetchOnWindowFocus: false
  });

  const postTag = useMutation({
    mutationFn: () => queries.postTag({ tagsDTO: newTagText }),
    onSuccess: () => {
      queryClient.invalidateQueries(['tags']);
      successToast('Sikeres létrehozás');
    },
    onError: () => {
      errorToast('Sikertelen létrehozás');
    }
  });

  const fetchProducts = useQuery({
    queryKey: ['productTags'],
    queryFn: () => queries.fetchProducts({ tagsDTO: findTagText }),
    onSuccess: (data: any) => {
      setProducts(data);
      setProductFetch(false);
    },
    retry: (failureCount, error: any) => (error.response.status === 404 ? false : true),
    refetchOnWindowFocus: false,
    enabled: productFetch
  });

  return {
    putTag,
    deleteTag,
    fetchTags,
    postTag,
    fetchProducts,
    tags,
    products,
    newTagText,
    setNewTagText,
    setFindTagText,
    setProductFetch
  };
};
