import * as React from 'react';
import styles from './sidebar.module.css';
import { Link } from 'react-router-dom';
import { useSidebarData } from './sidebarData';

interface Props {
  children: React.ReactNode;
  highlight: number;
}

const Sidebar: React.FC<Props> = ({ children, highlight }) => {
  const { sidebardata } = useSidebarData();

  return (
    <div className={styles.mainContainer}>
      <div className={styles.leftContainer}>
        <div className={styles.links}>
          {sidebardata.map((item, idx: number) => (
            <div key={item.text} className={styles.link_container}>
              <Link to={item.link} className={idx == highlight ? styles.highlighted : styles.normal}>
                <span className={styles.texts}>{item.text}</span>
                <span className={styles.icons}>{item.icon}</span>
              </Link>
            </div>
          ))}
        </div>
      </div>
      <div className={styles.rightContainer}>{children}</div>
    </div>
  );
};

export default Sidebar;
