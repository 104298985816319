import React from 'react';
import styles from './blogs.module.css';
import { useBlogsLogic } from './useBlogsLogic';
import { IconButton } from '@mui/material';
import moment from 'moment';
import { MdAdd, MdDelete, MdEdit } from 'react-icons/md';
import { DataGrid, GridToolbarContainer, GridToolbarQuickFilter } from '@mui/x-data-grid';
import CustomNoRowsOverlay from '../../components/datagrid/customNoRowsOverlay/CustomNoRowsOverlay';
import Center from '../../components/general/center/Center';
import ConfirmDialog from '../../components/general/confirmDialog/ConfirmDialog';
import BlogEditor from '../../components/blogComponents/blogEditor/BlogEditor';
import huIcon from '../../assets/icons/hu.png';
import enIcon from '../../assets/icons/en.png';

const Blogs = () => {
  const [confirmOpen, setConfirmOpen] = React.useState(false);
  const [isEdit, setIsEdit] = React.useState(false);
  const blogData = React.useRef<any>({});
  const { fetchBlogs, deleteBlog, setPaginationModel, paginationModel } = useBlogsLogic();

  const [rowCountState, setRowCountState] = React.useState(fetchBlogs.data?.page?.totalElements || 0);

  React.useEffect(() => {
    const pageInfo = fetchBlogs.data?.page;
    setRowCountState((prevRowCountState: any) =>
      pageInfo?.totalElements !== undefined ? pageInfo?.totalElements : prevRowCountState
    );
  }, [fetchBlogs.data?.page?.totalElements, setRowCountState]);

  function CustomToolbar() {
    return (
      <GridToolbarContainer className={styles.toolbar}>
        <GridToolbarQuickFilter />
        <IconButton
          onClick={() => {
            blogData.current = {};
            setIsEdit(true);
          }}>
          <MdAdd />
        </IconButton>
      </GridToolbarContainer>
    );
  }
  const columns = [
    {
      field: 'id',
      headerName: '',
      type: 'actions',
      renderCell: (params: any) => (
        <img
          className={styles.thumbnail}
          src={`data:image/png;base64,${params.row.thumbnail}`}
          alt={params.row.title + ' Thumbnail'}
          width={'100px'}
        />
      ),
      width: 100
    },
    {
      field: 'title',
      headerName: 'Title',
      flex: 1,
      minWidth: 150
    },
    {
      field: 'author',
      headerName: 'Author',
      flex: 1,
      minWidth: 150
    },
    {
      field: 'createdDate',
      headerName: 'Creation Date',
      renderCell: (params: any) => <div>{moment(params.value).format('yyyy.MM.DD hh:mm:ss')}</div>,
      flex: 1,
      minWidth: 150
    },
    {
      field: 'language',
      headerName: '',
      renderCell: (params: any) => (
        <div
          style={{ background: `url(${params.value == 'HU' ? huIcon : enIcon})`, height: '30px', width: '30px' }}></div>
      ),
      width: 30
    },
    {
      field: 'actions',
      type: 'actions',
      sortable: false,
      filterable: false,
      renderCell: (params: any) => (
        <>
          <IconButton
            onClick={() => {
              blogData.current = params.row;
              setIsEdit(true);
            }}>
            <MdEdit />
          </IconButton>
          <IconButton
            onClick={() => {
              blogData.current = params.row;
              setConfirmOpen(true);
            }}>
            <MdDelete />
          </IconButton>
        </>
      )
    }
  ];

  return (
    <main className={styles.main_container}>
      <Center element='section'>
        <div className={styles.header}>
          <h1 className={styles.headerTitle}>Blogok</h1>
        </div>
        <div>
          <DataGrid
            loading={fetchBlogs.isLoading}
            rows={fetchBlogs.data?._embedded?.blogDTOList ?? []}
            columns={columns}
            disableRowSelectionOnClick
            disableColumnFilter
            disableColumnSelector
            disableDensitySelector
            slots={{ toolbar: CustomToolbar, noRowsOverlay: CustomNoRowsOverlay }}
            slotProps={{
              toolbar: {
                showQuickFilter: true
              }
            }}
            autoHeight
            paginationModel={paginationModel}
            rowCount={rowCountState}
            pageSizeOptions={[10]}
            paginationMode='server'
            onPaginationModelChange={setPaginationModel}
          />
        </div>
      </Center>
      <BlogEditor
        open={isEdit}
        onClose={() => {
          blogData.current = {};
          setIsEdit(false);
        }}
        data={blogData.current ?? {}}
      />
      <ConfirmDialog
        open={confirmOpen}
        setOpen={setConfirmOpen}
        action={() => {
          deleteBlog.mutate(blogData?.current?.id);
          setConfirmOpen(false);
        }}
        loading={deleteBlog.isLoading}
        title='Biztos törölni szeretnéd ezt a blog cikket?'
      />
    </main>
  );
};

export default Blogs;
