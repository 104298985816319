export const routes = {
  LANDING: { name: 'Landing', to: '/' },
  USERS: { name: 'Users', to: '/users' },
  USER: { name: 'User', to: '/users/:id' },
  SUBSCRIPTIONS: { name: 'Subscriptions', to: '/subscriptions' },
  SUBSCRIPTION: { name: 'Subscription', to: '/subscriptions/:id' },
  GROUPS: { name: 'Groups', to: '/groups' },
  BLOG: { name: 'Blog', to: '/blog' },
  GROUP: { name: 'Group', to: '/groups/:id' },
  TAGS: { name: 'Tags', to: '/management/tags' },
  DISCOUNTS: { name: 'Discounts', to: '/management/discounts' },
  LOGIN: { name: 'Login', to: '/login' },
  LOGOUT: { name: 'Logout', to: '/logout' },
  REDIRECT: { name: 'Redirect', to: '/redirect' },
  NOTFOUND: { name: 'Not Found', to: '*' }
};
