import { useState } from 'react';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import queries from '../../../api/queries/ProductQueries';
import { useToasterLogic } from '../../../utils/useToasterLogic';

export const useBundleCardLogic = (bundle: any) => {
  const [sureOpen, setSureOpen] = useState<boolean>(false);
  const [action, setAction] = useState<any>();
  const [bundleImage, setBundleImage] = useState<any>();
  const [bundleData, setBundleData] = useState<any>();
  const { errorToast, successToast } = useToasterLogic();
  const queryClient = useQueryClient();

  const bundleImageFetchQuery = useQuery({
    queryKey: ['bundle_icon', bundle?.id],
    queryFn: () => queries.fetchGroupImage({ id: bundle?.id }),
    onSuccess: async (data) => {
      let image = await queries.convertBlobToBase64(await data);
      setBundleImage(image);
    },
    retry: (failureCount, error: any) =>
      error.response.status === 403 || error.response.status === 404 ? false : true,
    refetchOnWindowFocus: false,
    enabled: bundle?.id > 0
  });

  const deleteBundle = useMutation({
    mutationFn: () => queries.deleteGroup({ id: bundle?.id }),
    onSuccess: () => {
      setSureOpen(false);
      successToast('Sikeres bundle törlés');
      queryClient.invalidateQueries(['groupBundles']);
    },
    onError: () => {
      errorToast('Sikertelen bundle törlés!');
    }
  });

  const updateBundleQuery = useMutation({
    mutationFn: () => {
      // eslint-disable-next-line no-unused-vars, @typescript-eslint/no-unused-vars
      const { parent, children, ...bundleDataWithoutParent } = bundleData;

      return queries.updateGroup({
        groupDto: { ...bundleDataWithoutParent, products: [], parent: { id: parent.id } }
      });
    },
    onSuccess: () => {
      queryClient.invalidateQueries(['groupBundles']);
      setAction(null);
      setBundleData(null);
      successToast('Sikeres bundle frissítés');
    },
    onError: () => {
      errorToast('Sikertelen bundle frissítés');
    }
  });

  const handleStatus = (bundle: any, status: boolean) => {
    setBundleData({ ...bundle, isVisible: status });
    updateBundleQuery.mutate();
  };

  return {
    bundleImageFetchQuery,
    bundleImage,
    bundleData,
    setBundleData,
    updateBundleQuery,
    setBundleImage,
    deleteBundle,
    setSureOpen,
    sureOpen,
    action,
    setAction,
    handleStatus
  };
};
