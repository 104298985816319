import * as yup from 'yup';

export const useValidationLogic = () => {
  const GroupSchema = yup.object({
    name: yup.string('Adj meg egy név kulcsot').required('Szükséges mező'),
    urlSlug: yup.string('Adj meg egy releváns url-t').required('Szükséges mező'),
    description: yup.string('Adj meg egy név kulcsot').required('Szükséges mező'),
    shortDescription: yup.string('Adj meg egy név kulcsot').required('Szükséges mező')
  });
  const ProductSchema = yup.object({
    name: yup.string('Adj meg egy név kulcsot').required('Szükséges mező'),
    description: yup.string('Adj meg egy leírást').required('Szükséges mező'),
    productType: yup.string('Add meg a terméktípust').required('Szükséges mező')
  });
  const ModifierSchema = yup.object({
    modifierKey: yup.string('Adj meg egy név kulcsot').required('Szükséges mező'),
    description: yup.string('Adj meg egy leírást').required('Szükséges mező'),
    displayName: yup.string('Adj meg egy név kulcsot'),
    modifierCategory: yup.string('Adj meg egy név kulcsot').required('Szükséges mező'),
    minValue: yup.number('Adj meg egy név kulcsot'),
    maxValue: yup.number('Adj meg egy név kulcsot'),
    scale: yup.number('Adj meg egy név kulcsot'),
    defaultValue: yup.number('Adj meg egy név kulcsot'),
    grossAmountHUF: yup.number('Add meg a HUF árat').required('Szükséges mező'),
    grossAmountEUR: yup.number('Add meg az EUR árat').required('Szükséges mező'),
    //taxRateHUF: yup.number('Add meg az HUF áfát').required('Szükséges mező'),
    //taxRateEUR: yup.number('Add meg az EUR áfát').required('Szükséges mező'),
    //currency: yup.string('Adj meg egy név kulcsot').required('Szükséges mező'),
    possibleValues: yup.string('Adj meg egy név kulcsot')
  });
  const TagSchema = yup.object({
    name: yup.string('Adj meg egy név kulcsot').required('Szükséges mező')
  });
  const InstanceSchema = yup.object({
    username: yup.string('Adj meg egy név kulcsot'),
    initialPassword: yup.string('Adj meg egy név kulcsot'),
    domain: yup.string('Adj meg egy név kulcsot')
  });
  const DiscountSchema = yup.object({
    code: yup.string('Adj meg nevet').required('Szükséges mező'),
    //type: yup.string('Adj meg kedvezmény típust').required('Szükséges mező'),
    //discountAmount: yup.number('Adj meg kedvezmény mértéket'),
    //typeMatcher: yup.array('Adj meg címkéket'),
    discountRate: yup.number('Adj meg kedvezmény százalékot'),
    createdDate: yup.string('Adj meg kedvezmény kezdeti dátumot').required('Szükséges mező'),
    endDate: yup.string('Adj meg kedvezmény végdátumot').required('Szükséges mező')
  });
  const BlogSchema = yup.object({
    title: yup.string('Adj meg címet').required('Szükséges mező'),
    //short_description: yup.string('Adj meg egy rövíd leírást'),
    author: yup.string('Adj meg kedvezmény százalékot').required('Szükséges mező'),
    thumbnail: yup.mixed('Tölts fel egy képet').required('Thumbnail feltöltése kötelező'),
    content: yup.string('Ide írd a blogot'),
    language: yup.string('Válassz egy nyelvet').required('Szükséges mező')
  });

  return { GroupSchema, ProductSchema, ModifierSchema, TagSchema, InstanceSchema, DiscountSchema, BlogSchema };
};
