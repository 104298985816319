import * as React from 'react';
import styles from './logout.module.css';
import AuthService from '../../services/AuthService';
import { useNavigate } from 'react-router-dom';
import { routes } from '../../config/routes';
import useGeneralStore from '../../store/GeneralStore';

const Logout = () => {
  const navigate = useNavigate();
  const { setCurrent } = useGeneralStore((state: any) => ({
    setCurrent: state.setCurrent
  }));

  React.useEffect(() => {
    setCurrent({});
    AuthService.isLoggedIn() ? AuthService.logout() : navigate(routes.LANDING.to);
  }, []);

  return (
    <div className={styles.background}>
      <h3 className={styles.main_text}>Kijelentkezés....</h3>
    </div>
  );
};

export default Logout;
