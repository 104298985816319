import React from 'react';
import styles from './footerContact.module.css';

interface Props {
  width?: string;
  height?: string;
  text?: string;
  alt?: string;
  src?: string;
}
const FooterContact: React.FC<Props> = ({ width, height, alt, text, src }) => {
  return (
    <div className={styles.icon_container}>
      <i>
        <img src={src} width={width} height={height} alt={alt} />
      </i>
      <div>{text}</div>
    </div>
  );
};

export default FooterContact;
