import queries from '../../api/queries/DiscountsQueries';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { useToasterLogic } from '../../utils/useToasterLogic';

export const useDiscountLogic = () => {
  const queryClient = useQueryClient();
  const { successToast, errorToast } = useToasterLogic();

  const putDiscount = useMutation({
    mutationFn: (datas: any) => queries.putDiscount({ discountId: datas?.id, discountsDTO: datas?.data }),
    onSuccess: () => {
      queryClient.invalidateQueries(['discounts']);
      successToast('Sikeres módosítás');
    },
    onError: () => {
      errorToast('Sikertelen módosítás');
    }
  });

  const deleteDiscount = useMutation({
    mutationFn: (id) => queries.deleteDiscount({ discountId: id }),
    onSuccess: () => {
      queryClient.invalidateQueries(['discounts']);
      successToast('Sikeres törlés');
    },
    onError: () => {
      errorToast('Sikertelen törlés');
    }
  });

  const fetchDiscounts = useQuery<any, any>({
    queryKey: ['discounts'],
    queryFn: () => queries.fetchDiscounts(),
    onSuccess: () => {},
    retry: (failureCount, error: any) => (error.response.status === 404 ? false : true),
    refetchOnWindowFocus: false
  });

  const postDiscount = useMutation({
    mutationFn: (discountData) => queries.postDiscount({ discountsDTO: discountData }),
    onSuccess: () => {
      queryClient.invalidateQueries(['discounts']);
      successToast('Sikeres létrehozás');
    },
    onError: () => {
      errorToast('Sikertelen létrehozás');
    }
  });

  return {
    putDiscount,
    deleteDiscount,
    fetchDiscounts,
    postDiscount
  };
};
