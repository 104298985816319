import React from 'react';
import { MdLogout, MdMenuBook, MdOutlineSubtitles } from 'react-icons/md';
import { FaShoppingBag, FaUser } from 'react-icons/fa';
import { routes } from '../../../config/routes';
import AuthService from '../../../services/AuthService';

const primaryGuest = [
  {
    text: 'Bejelentkezés',
    icon: null,
    link: routes.LOGIN.to
  }
];

const primaryAuth = [
  {
    text: 'Blog',
    icon: null,
    link: routes.BLOG.to
  },
  {
    text: 'Bolt adminisztráció',
    icon: null,
    link: routes.GROUPS.to
  },
  {
    text: 'Feliratkozások',
    icon: null,
    link: routes.SUBSCRIPTIONS.to
  },
  {
    text: 'Felhasználók',
    icon: null,
    link: routes.USERS.to
  },
  {
    text: 'Kijelentkezés',
    icon: null,
    link: routes.LOGOUT.to
  }
];

export const primary = () => (AuthService.isLoggedIn() ? primaryAuth : primaryGuest);

export const sidebarTopGuest = [
  {
    text: 'Kijelentkezés',
    icon: <MdLogout fontSize={30} />,
    link: routes.LOGOUT.to
  }
];

export const sidebarTopAuth = [
  {
    text: 'Blog',
    icon: <MdMenuBook fontSize={30} />,
    link: routes.BLOG.to
  },
  {
    text: 'Bolt adminisztráció',
    icon: <FaShoppingBag fontSize={30} />,
    link: routes.TAGS.to,
    children: [
      {
        text: 'Termékek',
        link: routes.GROUPS.to
      },
      {
        text: 'Címkék',
        link: routes.TAGS.to
      },
      {
        text: 'Kedvezmények',
        link: routes.DISCOUNTS.to
      }
    ]
  },
  {
    text: 'Feliratkozások',
    icon: <MdOutlineSubtitles fontSize={30} />,
    link: routes.SUBSCRIPTIONS.to
  },
  {
    text: 'Felhasználók',
    icon: <FaUser fontSize={30} />,
    link: routes.USERS.to
  }
];

export const sidebar_top = () => (AuthService.isLoggedIn() ? sidebarTopAuth : sidebarTopGuest);

export const sidebarBottomGuest = [];

export const sidebarBottomAuth = [
  {
    text: 'Kijelentkezés',
    icon: <MdLogout fontSize={30} />,
    link: routes.LOGOUT.to
  }
];

export const sidebar_bottom = () => (AuthService.isLoggedIn() ? sidebarBottomAuth : sidebarBottomGuest);
