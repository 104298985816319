import React from 'react';
import styles from './groupCard.module.css';
import { useNavigate } from 'react-router-dom';
import { routes } from '../../../config/routes';
import queries from '../../../api/queries/ProductQueries';
import { Card, CardActionArea, IconButton, Switch } from '@mui/material';
import { useQuery } from '@tanstack/react-query';
import { MdDelete, MdEdit } from 'react-icons/md';

interface GroupCardProps {
  group: any;
  setAction: any;
  setSureOpen: any;
  setGroupData: any;
  handleStatus: any;
}

const GroupCard: React.FC<GroupCardProps> = ({ group, handleStatus, setGroupData, setAction, setSureOpen }) => {
  const navigate = useNavigate();
  const [groupIcon, setGroupIcon] = React.useState();

  /*eslint-disable*/
  const productImageFetchQuery = useQuery({
    queryKey: ['group_icon', group?.id],
    queryFn: () => queries.fetchProductImage({ id: group?.products[0]?.id }),
    onSuccess: async (data) => {
      let image = await queries.convertBlobToBase64(await data);
      setGroupIcon(image);
    },
    retry: (failureCount, error: any) =>
      error.response.status === 403 || error.response.status === 404 ? false : true,
    refetchOnWindowFocus: false,
    enabled: group?.products?.length > 0
  });
  /*eslint-enable*/

  return (
    <Card key={`group_card_${group?.id}`} className={styles.groupCard}>
      <CardActionArea
        className={group?.isVisible ? styles.actionArea : styles.actionAreaInactive}
        onClick={() => navigate(`${routes.GROUPS.to}/${group?.id}`)}>
        <div className={group?.isVisible ? styles.imageWrapper : styles.imageWrapperInactive}>
          <img src={groupIcon} alt='első_termék_képe' className={styles.groupIcon} />
        </div>
        <div className={group?.isVisible ? styles.textWrapper : styles.textWrapperInactive}>
          <h3 className={group?.isVisible ? styles.groupTitle : styles.groupTitleInactive}>{group?.name}</h3>
          <p className={styles.groupDesc}>{group?.shortDescription}</p>
        </div>
      </CardActionArea>
      <div className={styles.handleWrapper}>
        <IconButton
          onClick={() => {
            setGroupData(group);
            setAction('update');
          }}>
          <MdEdit />
        </IconButton>

        <Switch
          checked={group?.isVisible}
          className={styles.switch}
          onChange={() => handleStatus(group, group?.isVisible ? false : true)}
        />
        <IconButton
          onClick={() => {
            setSureOpen(group?.id);
          }}>
          <MdDelete />
        </IconButton>
      </div>
    </Card>
  );
};

export default GroupCard;
