import { api } from '../axios';
import ENV from '../env';

export default {
  async putTag({ tagID, tagsDTO }) {
    await (await api({ baseUrl: ENV.REACT_APP_API_URL, auth: true })).put(`admin/tags/${tagID}`, { name: tagsDTO });
  },
  async deleteTag({ tagID }) {
    await (await api({ baseUrl: ENV.REACT_APP_API_URL, auth: true })).delete(`admin/tags/${tagID}`);
  },
  async fetchTags() {
    return await (await api({ baseUrl: ENV.REACT_APP_API_URL, auth: true })).get(`admin/tags`);
  },
  async postTag({ tagsDTO }) {
    await (await api({ baseUrl: ENV.REACT_APP_API_URL, auth: true })).post(`admin/tags`, { name: tagsDTO });
  },
  async fetchProducts({ tagsDTO }) {
    return await (
      await api({ baseUrl: ENV.REACT_APP_API_URL, auth: true })
    ).get(`/admin/products/tags?filter=${tagsDTO}`);
  }
};
