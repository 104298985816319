import React from 'react';
import styles from './header.module.css';
import logo from '../../../assets/images/datatronic_logo.png';
import logo_blue from '../../../assets/images/datatronic_logo_blue.png';
import { s, wait } from '../../../js/utility.js';
import { Link } from 'react-router-dom';
// import LanguageChooser from './languageChooser/LanguageChooser';
import AuthService from '../../../services/AuthService';
import { primary, sidebar_top, sidebar_bottom } from './headerData';
import SidebarAccordion from '../SidebarAccordion/SidebarAccordion';
// import { formatMessage } from 'devextreme/localization';

const Header = () => {
  const toggleSidebar = async () => {
    const sidebarContainer = s(styles.sidebar_container);
    const sidebar = s(styles.sidebar);

    if (sidebarContainer?.classList.contains(styles.sidebar_visible)) {
      sidebar?.classList.toggle(styles.sidebar_visible);

      await wait(100);

      sidebarContainer?.classList.toggle(styles.sidebar_opacity);

      await wait(200);

      sidebarContainer?.classList.toggle(styles.sidebar_visible);
    } else {
      sidebarContainer?.classList.toggle(styles.sidebar_visible);

      await wait(50);

      sidebarContainer?.classList.toggle(styles.sidebar_opacity);

      await wait(50);

      sidebar?.classList.toggle(styles.sidebar_visible);
    }
  };

  return (
    <header>
      <div>
        <div
          className={styles.primary_header}
          style={{ backgroundColor: AuthService.isLoggedIn() ? 'var(--color-dark)' : 'var(--color-main)' }}>
          <Link to={AuthService.isLoggedIn() ? '/' : '/'} className={styles.logo}>
            <img src={logo} alt='Datatronic logo' title='Datatronic' width='150' height='25' />
          </Link>
          <nav aria-label='primary'>
            {primary().map((item) => (
              <Link key={item.text} to={item.link}>
                <span>{item.text}</span>
              </Link>
            ))}
          </nav>
          {/* <LanguageChooser /> */}
          <div
            className={styles.hamburger}
            id={styles.hamburger_btn}
            role='button'
            aria-label='Menü'
            onClick={() => toggleSidebar()}>
            <div></div>
            <div></div>
            <div></div>
          </div>
        </div>
      </div>
      <div className={styles.sidebar_container} id={styles.sidebar_container}>
        <div className={styles.sidebar} id={styles.sidebar}>
          <div className={styles.sidebar_logo}>
            <Link to={AuthService.isLoggedIn() ? '/dashboard' : '/'} onClick={() => toggleSidebar()}>
              <img src={logo_blue} alt='Datatronic logo' title='Datatronic' width='80' loading='lazy' />
            </Link>
          </div>
          <div className={styles.sidebar_top}>
            {sidebar_top().map((item: any) =>
              item.children == null ? (
                <Link key={item.text} to={item.link} onClick={() => toggleSidebar()}>
                  <span className={styles.icons}>{item.icon}</span>
                  <span className={styles.texts}>{item.text}</span>
                </Link>
              ) : (
                <SidebarAccordion key='1' text={item.text} icon={item.icon}>
                  {item.children.map((child: any) => (
                    <Link key={child.text} to={child.link} onClick={() => toggleSidebar()}>
                      <span className={styles.accordion_texts}>{child.text}</span>
                    </Link>
                  ))}
                </SidebarAccordion>
              )
            )}
          </div>
          <div className={styles.sidebar_bottom}>
            {sidebar_bottom().map((item: any) =>
              item.children == null ? (
                <Link key={item.text} to={item.link} onClick={() => toggleSidebar()}>
                  <span className={styles.icons}>{item.icon}</span>
                  <span className={styles.texts}>{item.text}</span>
                </Link>
              ) : (
                <SidebarAccordion key='1' text={item.text} icon={item.icon}>
                  {item.children.map((child: any) => (
                    <Link key={child.text} to={child.link} onClick={() => toggleSidebar()}>
                      <span className={styles.accordion_texts}>{child.text}</span>
                    </Link>
                  ))}
                </SidebarAccordion>
              )
            )}
          </div>
        </div>
        <div className={styles.right_side} onClick={() => toggleSidebar()}></div>
      </div>
    </header>
  );
};

export default Header;
