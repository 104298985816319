import React, { useState } from 'react';
import styles from './userSubCreator.module.css';
import {
  Button,
  Checkbox,
  CircularProgress,
  FormControl,
  FormControlLabel,
  FormGroup,
  IconButton,
  InputAdornment,
  MenuItem,
  Radio,
  RadioGroup,
  Select,
  TextField
} from '@mui/material';
import { MdPostAdd, MdVisibility, MdVisibilityOff } from 'react-icons/md';
import { useMutation } from '@tanstack/react-query';
import queries from '../../../api/queries/SubscriptionQueries';
import ConfirmDialog from '../../general/confirmDialog/ConfirmDialog';

type UserDTO = {
  username: string;
  password: string;
  firstname: string;
  lastname: string;
  email: string;
  language: any;
  groups: any;
  subscriptionCurrency: string;
};

const UserSubCreator = () => {
  const [create, setCreate] = useState<boolean>(false);
  const [newUser, setNewUser] = useState<string>('true');
  const [verificationNeeded, setVerificationNeeded] = useState<boolean>(false);
  const [newUserDTO, setNewUserDTO] = useState<UserDTO>({
    username: '',
    password: '',
    firstname: '',
    lastname: '',
    email: '',
    language: 'EN',
    groups: [],
    subscriptionCurrency: 'EUR'
  });
  const [showPassword, setShowPassword] = useState(false);
  const [confirmOpen, setConfirmOpen] = useState<boolean>(false);

  const userCreateMutation = useMutation<any, any>({
    mutationFn: () => queries.createUser({ userDTO: newUserDTO, isVerify: verificationNeeded }),
    onSuccess: () => {
      setCreate(false);
    }
  });

  return (
    <div className={styles.newSubCreator}>
      <div className={styles.addSubWrapper}>
        <h3>Új feliratkozás létrehozása</h3>
        <IconButton
          onClick={() => {
            setCreate(!create);
            userCreateMutation.reset();
            setNewUserDTO({
              username: '',
              password: '',
              firstname: '',
              lastname: '',
              email: '',
              language: 'EN',
              groups: [],
              subscriptionCurrency: 'EUR'
            });
          }}>
          <MdPostAdd />
        </IconButton>
      </div>
      {userCreateMutation.isSuccess && userCreateMutation.data && !create && (
        <>
          <h3>Feliratkozás és felhasználó létrehozva!</h3>
          <p>{`Felhasználó azonosító: ${userCreateMutation.data?.externalId}`}</p>
          <p>{`Felhasználó azonosító: ${userCreateMutation.data?.username}`}</p>
          <p>{`Felhasználó azonosító: ${userCreateMutation.data?.email}`}</p>
        </>
      )}
      {userCreateMutation.isError && !create && <h3>Sikertelen Létrehozás!</h3>}
      {create && (
        <div className={styles.subCreator}>
          <FormControl style={{ width: '90%', color: 'var(--color-main) !important' }}>
            <RadioGroup value={newUser} onChange={(e: any) => setNewUser(e.target.value)}>
              <FormControlLabel
                value={'true'}
                control={
                  <Radio
                    style={{
                      color: 'black'
                    }}
                  />
                }
                label={'Létrehozás új felhasználóhoz'}
                style={{
                  color: 'black'
                }}
              />
            </RadioGroup>
          </FormControl>
        </div>
      )}
      {create && newUser == 'true' && (
        <div className={styles.newUserForm}>
          <h4>Új felhasználó létrehozása</h4>
          <FormGroup className={styles.checkbox} style={{ width: '90%', color: 'var(--color-main) !important' }}>
            <FormControlLabel
              control={
                <Checkbox checked={verificationNeeded} onChange={() => setVerificationNeeded(!verificationNeeded)} />
              }
              label={'Fiók aktiválás szükséges?'}
            />
          </FormGroup>
          <TextField
            className={styles.input}
            label='Felhasználónév'
            value={newUserDTO.username}
            onChange={(e) => setNewUserDTO({ ...newUserDTO, username: e.target.value })}
          />
          <TextField
            className={styles.input}
            label='Jelszó'
            type={showPassword ? 'text' : 'password'}
            value={newUserDTO.password}
            onChange={(e) => setNewUserDTO({ ...newUserDTO, password: e.target.value })}
            InputProps={{
              endAdornment: (
                <InputAdornment position='end'>
                  <IconButton aria-label='toggle password visibility' onClick={() => setShowPassword(!showPassword)}>
                    {showPassword ? <MdVisibility /> : <MdVisibilityOff />}
                  </IconButton>
                </InputAdornment>
              )
            }}
          />
          <TextField
            className={styles.input}
            label='Keresztnév'
            value={newUserDTO.firstname}
            onChange={(e) => setNewUserDTO({ ...newUserDTO, firstname: e.target.value })}
          />
          <TextField
            className={styles.input}
            label='Vezetéknév'
            value={newUserDTO.lastname}
            onChange={(e) => setNewUserDTO({ ...newUserDTO, lastname: e.target.value })}
          />
          <TextField
            className={styles.input}
            label='Email'
            value={newUserDTO.email}
            onChange={(e) => setNewUserDTO({ ...newUserDTO, email: e.target.value })}
          />
          <Select
            className={styles.input}
            defaultValue={'EN'}
            onChange={(e) => setNewUserDTO({ ...newUserDTO, language: e.target.value })}>
            <MenuItem key={'EN'} value='EN'>
              EN
            </MenuItem>
            <MenuItem key={'HU'} value='HU'>
              HU
            </MenuItem>
          </Select>
          <Select
            multiple
            className={styles.input}
            value={newUserDTO.groups}
            onChange={(e) => setNewUserDTO({ ...newUserDTO, groups: e.target.value })}>
            <MenuItem key={'users'} value='users'>
              users
            </MenuItem>
            <MenuItem key={'dt_admin'} value='dt_admin'>
              dt_admin
            </MenuItem>
          </Select>
          <Select
            className={styles.input}
            defaultValue={'EUR'}
            onChange={(e) => setNewUserDTO({ ...newUserDTO, subscriptionCurrency: e.target.value })}>
            <MenuItem key={'EUR'} value='EUR'>
              EUR
            </MenuItem>
            <MenuItem key={'HUF'} value='HUF'>
              HUF
            </MenuItem>
          </Select>
          <div style={{ display: 'flex', justifyContent: 'space-around', width: '100%' }}>
            <Button variant='outlined' onClick={() => setCreate(false)}>
              Mégsem
            </Button>
            <Button disabled={userCreateMutation?.isLoading} variant='outlined' onClick={() => setConfirmOpen(true)}>
              {userCreateMutation?.isLoading ? <CircularProgress size={24} /> : 'Létrehozás'}
            </Button>
          </div>
        </div>
      )}
      <ConfirmDialog
        open={confirmOpen}
        setOpen={setConfirmOpen}
        title='Biztosan létrehozod a felhasználót ls a hozzá tartozó feliratkozást?'
        action={() => userCreateMutation.mutate()}
        loading={userCreateMutation.isLoading}
      />
    </div>
  );
};

export default UserSubCreator;
