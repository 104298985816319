import React from 'react';
import { useGroupsLogic } from './useGroupsLogic';
import GroupCard from '../../components/groupComponents/GroupCard/GroupCard';
import styles from './groups.module.css';
import GroupDialog from '../../components/groupComponents/GroupDialog/GroupDialog';
import ConfirmDialog from '../../components/general/confirmDialog/ConfirmDialog';
import { Button } from '@mui/material';
import { MdAdd } from 'react-icons/md';

const Groups = () => {
  const {
    groups,
    setAction,
    action,
    groupData,
    setGroupData,
    handleSubmit,
    handleStatus,
    sureOpen,
    setSureOpen,
    deleteGroupQuery,
    createGroupQuery,
    updateGroupQuery
  } = useGroupsLogic();

  return (
    <div className={styles.mainContainer}>
      <div className={styles.serviceWrapper}>
        <div className={styles.header}>
          <h1 className={styles.headerTitle}>Termékcsoportok</h1>
          <Button onClick={() => setAction('create')} variant='outlined' startIcon={<MdAdd />}>
            Új termékcsoport felvétele
          </Button>
        </div>
        {groups &&
          groups
            ?.filter((group) => group.isBundle === false)
            ?.sort((a, b) => {
              if (a?.orderNumber !== undefined && b?.orderNumber !== undefined) {
                if (a?.orderNumber !== b?.orderNumber) {
                  return a?.orderNumber - b?.orderNumber;
                }
              } else if (a?.orderNumber !== undefined) {
                return -1;
              } else if (b?.orderNumber !== undefined) {
                return 1;
              }
              return a.id - b.id;
            })
            ?.map((group: any) => (
              <GroupCard
                setAction={setAction}
                setSureOpen={setSureOpen}
                setGroupData={setGroupData}
                key={`groupcard_${group?.id}`}
                group={group}
                handleStatus={handleStatus}
              />
            ))}
      </div>
      <GroupDialog
        type={'Termékcsoport'}
        action={action}
        setAction={setAction}
        groupData={groupData}
        setGroupData={setGroupData}
        handleSubmit={handleSubmit}
        loading={updateGroupQuery.isLoading || createGroupQuery.isLoading}
      />
      <ConfirmDialog
        open={sureOpen}
        setOpen={setSureOpen}
        action={() => deleteGroupQuery.mutate(sureOpen)}
        title={`Biztos törölni akarod ezt a termékcsoportot?`}
        loading={deleteGroupQuery.isLoading}
      />
    </div>
  );
};

export default Groups;
