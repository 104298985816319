import React from 'react';
import styles from './group.module.css';
import { useGroupLogic } from './useGroupLogic';
import Breadcrumb from '../../../components/groupComponents/Breadcrumb/Breadcrumb';
import { routes } from '../../../config/routes';
import ProductCard from '../../../components/groupComponents/ProductCard/ProductCard';
import ProductDialog from '../../../components/groupComponents/ProductDialog/ProductDialog';
import Uploader from '../../../components/groupComponents/Uploader/Uploader';
import { Button } from '@mui/material';
import { MdAdd, MdAutoAwesomeMotion } from 'react-icons/md';
import GroupDialog from '../../../components/groupComponents/GroupDialog/GroupDialog';
import { useGroupsLogic } from '../useGroupsLogic';
import BundleCard from '../../../components/groupComponents/BundleCard/BundleCard';

const Group = () => {
  const {
    products,
    group,
    cover,
    setCover,
    groupFetchQuery,
    groupBundesFetchQuery,
    // groupCoverFetchQuery,
    groupBundles,
    action: productAction,
    setAction: productSetAction,
    setProductData,
    productData,
    handleSubmit: productHandleSubmit,
    handleStatus: productHandleStatus,
    id,
    setOriProduct,
    productUpdateQuery,
    productCreateQuery
  } = useGroupLogic();

  const {
    setAction: groupSetAction,
    action: groupAction,
    groupData,
    setGroupData,
    handleSubmit: groupHandleSubmit,
    createGroupQuery
  } = useGroupsLogic();
  return (
    <>
      <Breadcrumb
        name={group?.name}
        linkName={'Vissza a termékcsoportokhoz'}
        to={routes.GROUPS.to}
        baseColor='var(--color-text-light)'
      />
      <section className={styles.headerSection} style={{ backgroundImage: `url(${cover ? cover : 'cover'})` }}>
        <div className={styles.top_header}>
          <h1 className={styles.top_headerTitle}>{group?.name}</h1>
          <div className={styles.headerDescContainer}>
            <div className={styles.headerDesc}>{group?.description}</div>
          </div>
          {/*eslint-disable*/}
          <Uploader image={cover} setImage={setCover} id={+(id as any)} url='/admin/groups' />
        </div>
      </section>

      <section className={styles.mainContainer}>
        {/*My Services*/}
        <div className={styles.serviceContainer}>
          {/* eslint-disable */}
          <div className={styles.header}>
            <h1 className={styles.headerTitle}>Termékek</h1>
            <div style={{ display: 'flex', gap: '1rem' }}>
              <Button onClick={() => productSetAction('create')} variant='outlined' startIcon={<MdAdd />}>
                Új termék létrehozása
              </Button>
              <Button onClick={() => groupSetAction('create')} variant='outlined' startIcon={<MdAutoAwesomeMotion />}>
                Új bundle létrehozása
              </Button>
            </div>
          </div>
          {groupBundesFetchQuery.isLoading
            ? 'Töltés...'
            : groupBundles
                ?.sort((a: any, b: any) => {
                  if (a?.orderNumber !== undefined && b?.orderNumber !== undefined) {
                    if (a?.orderNumber !== b?.orderNumber) {
                      return a?.orderNumber - b?.orderNumber;
                    }
                  } else if (a?.orderNumber !== undefined) {
                    return -1;
                  } else if (b?.orderNumber !== undefined) {
                    return 1;
                  }
                  return a.id - b.id;
                })
                ?.map((bundle: any) => <BundleCard key={bundle.id} bundle={bundle} />)}
          {groupFetchQuery.isLoading
            ? 'Töltés...'
            : products
                ?.sort((a: any, b: any) => {
                  if (a?.orderNumber !== undefined && b?.orderNumber !== undefined) {
                    if (a?.orderNumber !== b?.orderNumber) {
                      return a?.orderNumber - b?.orderNumber;
                    }
                  } else if (a?.orderNumber !== undefined) {
                    return -1;
                  } else if (b?.orderNumber !== undefined) {
                    return 1;
                  }
                  return a.id - b.id;
                })
                ?.map((product: any) => (
                  <ProductCard
                    key={`${product?.productType}_${product.id}`}
                    product={product}
                    setOpen={productSetAction}
                    setProductData={setProductData}
                    handleStatus={productHandleStatus}
                    setOriProduct={setOriProduct}
                  />
                ))}
        </div>
        <ProductDialog
          action={productAction}
          setAction={productSetAction}
          productData={productData}
          setProductData={setProductData}
          loading={productUpdateQuery.isLoading || productCreateQuery.isLoading}
          handleSubmit={productHandleSubmit}
          products={products}
        />

        <GroupDialog
          type={'Bundle'}
          action={groupAction}
          setAction={groupSetAction}
          groupData={{ ...groupData, parent: { id: group.id }, isBundle: true }}
          setGroupData={setGroupData}
          handleSubmit={groupHandleSubmit}
          loading={createGroupQuery.isLoading}
        />
      </section>
    </>
  );
};

export default Group;
