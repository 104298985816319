export const welcomeTexts = [
  /*{
    origin: 'Sparky',
    who: 'Sparky',
    quote: 'Üdvözöllek kedves társ, aki jött belépni a DCI admin oldalára!'
  },
  {
    origin: 'Sparky',
    who: 'Sparky',
    quote: 'Hellóka!'
  },
  {
    origin: 'Sparky',
    who: 'Sparky',
    quote: 'Szia, idegen vándor itt a DCI admin oldalán!'
  },
  {
    origin: 'Sparky',
    who: 'Sparky',
    quote: 'Szia, én vagyok Sparky, köszöntelek az admin oldalon!'
  },
  {
    origin: 'Sparky',
    who: 'Sparky',
    quote: 'Szia, Sparky vagyok a DCI robot, remélem jó helyen jársz!'
  },
  {
    origin: 'Star Wars',
    who: 'Obi-Wan Kenobi+Sparky',
    quote: 'Hello there! I am General Sparky!'
  },
  {
    origin: 'Guardians of the Galaxy',
    who: 'Groot+Sparky',
    quote: 'I am Groot! I mean, almost.'
  },*/
  {
    origin: 'The Dark Knight',
    who: 'Joker',
    quote: 'Why so serious?'
  },
  {
    origin: 'The Lord of the Rings',
    who: 'Gandalf',
    quote: 'YOU, shall not, pass!'
  },
  {
    origin: 'The Lord of the Rings',
    who: 'Gimli',
    quote: 'That still only counts as one!'
  },
  {
    origin: 'Pirates of the Caribbean',
    who: 'Barbossa',
    quote: `The code is more what you'd call guidelines than actual rules.`
  },
  {
    origin: 'Sherlock Holmes',
    who: 'Sherlock Holmes',
    quote: 'I am not a hero. I am a high-functioning sociopath.'
  },
  {
    origin: 'Star Wars',
    who: 'Anakin',
    quote: 'I have brought peace, freedom, justice, and security to my new empire.'
  },
  {
    origin: 'Pirates of the Caribbean',
    who: 'Jack Sparrow',
    quote: `Oi! Fishface! I've got a jar of dirt!`
  },
  {
    origin: 'Breaking Bad',
    who: 'Walter White',
    quote: 'I am not in danger I am the danger!'
  },
  {
    origin: 'The Dark Knight',
    who: 'Joker',
    quote: 'I am not a monster. I am just ahead of the curve.'
  },
  {
    origin: 'Kung Fu Panda',
    who: 'Master Oogway',
    quote: 'Yesterday is history, tomorrow is a mystery, but today is a gift.'
  },
  {
    origin: 'Kung Fu Panda',
    who: 'Mr. Ping',
    quote: 'To make something special you just have to believe it is special.'
  },
  {
    origin: 'Kung Fu Panda',
    who: 'Po',
    quote: `I don't want to be more, I like who I am.`
  },
  {
    origin: 'Kung Fu Panda',
    who: 'Shifu',
    quote: `The only true limit is the one you set for yourself.`
  },
  {
    origin: 'Harry Potter',
    who: 'Dumbledore',
    quote: 'Did you put your name into the Goblet of Fire?'
  },
  {
    origin: 'The Dark Knight',
    who: 'Joker',
    quote: 'A villain is just a broken heroe.'
  },
  {
    origin: 'The Lord of the Rings',
    who: 'Sam',
    quote: 'I can not carry it for you, but I can carry you!'
  },
  {
    origin: 'Oppenheimer',
    who: 'J. Robert Oppenheimer',
    quote: 'Now I am become Death, the destroyer of worlds.'
  },
  {
    origin: 'Oppenheimer',
    who: 'Lewis Strauss',
    quote: `We're not convicting, just denying.`
  },
  {
    origin: 'Harry Potter',
    who: 'Hagrid',
    quote: 'You are a wizard!'
  },
  {
    origin: 'Harry Potter',
    who: 'Dumbledore',
    quote: 'It takes a great deal of bravery to stand up to our enemies, but just as much to stand up to our friends.'
  },
  {
    origin: 'Pirates of the Caribbean',
    who: 'Jack Sparrow',
    quote: 'Nobody move! I dropped me brain!'
  },
  {
    origin: 'The Lord of the Rings',
    who: 'Bilbo',
    quote: `It's mine. My own. My precious.`
  },
  {
    origin: 'The Dark Knight',
    who: 'Harvey Dent',
    quote: 'You either die a hero, or you live long enough to see yourself become the villain.'
  }
];
