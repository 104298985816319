import React from 'react';
import {
  Button,
  Checkbox,
  CircularProgress,
  Dialog,
  DialogContent,
  DialogTitle,
  FormControlLabel,
  TextField
} from '@mui/material';
import { Formik } from 'formik';
import { useValidationLogic } from '../../../validation/useValidationLogic';
import styles from './groupDialog.module.css';

interface GroupDialogProps {
  action: any;
  type: string;
  setAction: any;
  groupData: any;
  setGroupData: any;
  handleSubmit: () => void;
  loading: boolean;
}

const GroupDialog: React.FC<GroupDialogProps> = ({
  type,
  action,
  setAction,
  groupData,
  setGroupData,
  handleSubmit,
  loading
}) => {
  const { GroupSchema } = useValidationLogic();

  return (
    <Dialog
      open={action === 'create' || action === 'update'}
      onClose={() => {
        setAction(null);
        setGroupData(null);
      }}>
      <DialogTitle>{`${type} ${action == 'create' ? 'hozzáadása' : 'módosítása'}`}</DialogTitle>
      <DialogContent>
        <Formik
          enableReinitialize
          initialValues={{
            name: groupData?.name,
            urlSlug: groupData?.urlSlug,
            description: groupData?.description,
            shortDescription: groupData?.shortDescription,
            isVisible: groupData?.isVisible,
            orderNumber: groupData?.orderNumber
          }}
          validationSchema={GroupSchema}
          onSubmit={() => {
            handleSubmit();
          }}>
          {({ values, errors, touched, handleChange, handleSubmit }) => (
            <form onSubmit={handleSubmit} className={styles.form}>
              <TextField
                id='name'
                name='name'
                autoComplete='name'
                value={values.name}
                autoFocus
                label={'Csoport neve'}
                className={styles.input}
                onChange={(e) => {
                  handleChange(e);
                  setGroupData({ ...groupData, name: e.target.value });
                }}
                error={touched.name && Boolean(errors.name)}
                helperText={touched.name && Boolean(errors.name)}
              />
              <TextField
                id='urlSlug'
                name='urlSlug'
                autoComplete='urlSlug'
                value={values.urlSlug}
                autoFocus
                label={'Url'}
                className={styles.input}
                onChange={(e) => {
                  handleChange(e);
                  setGroupData({ ...groupData, urlSlug: e.target.value });
                }}
                error={touched.urlSlug && Boolean(errors.urlSlug)}
                helperText={touched.urlSlug && Boolean(errors.urlSlug)}
              />
              <TextField
                id='description'
                name='description'
                autoComplete='description'
                value={values.description}
                autoFocus
                label={'Leírás'}
                className={styles.input}
                onChange={(e) => {
                  handleChange(e);
                  setGroupData({ ...groupData, description: e.target.value });
                }}
                error={touched.description && Boolean(errors.description)}
                helperText={touched.description && Boolean(errors.description)}
              />
              <TextField
                id='shortDescription'
                name='shortDescription'
                autoComplete='shortDescription'
                value={values.shortDescription}
                autoFocus
                label={'Rövid leírás'}
                className={styles.input}
                onChange={(e) => {
                  handleChange(e);
                  setGroupData({ ...groupData, shortDescription: e.target.value });
                }}
                error={touched.shortDescription && Boolean(errors.shortDescription)}
                helperText={touched.shortDescription && Boolean(errors.shortDescription)}
              />
              <TextField
                inputProps={{ type: 'number' }}
                id='orderNumber'
                name='orderNumber'
                value={values?.orderNumber}
                autoFocus
                label={'Sorszám (opcionális)'}
                className={styles.input}
                onChange={(e) => {
                  handleChange(e);
                  setGroupData({ ...groupData, orderNumber: +e.target.value });
                }}
                error={touched.orderNumber && Boolean(errors.orderNumber)}
                helperText={touched.orderNumber && Boolean(errors.orderNumber)}
              />
              <FormControlLabel
                control={
                  <Checkbox
                    checked={groupData?.isVisible}
                    onChange={() => setGroupData({ ...groupData, isVisible: groupData?.isVisible ? false : true })}
                  />
                }
                label={'Aktív?'}
              />
              <Button disabled={loading} variant='contained' type='submit' className={styles.button}>
                {loading ? <CircularProgress size={24} /> : 'Mentés'}
              </Button>
            </form>
          )}
        </Formik>
      </DialogContent>
    </Dialog>
  );
};

export default GroupDialog;
