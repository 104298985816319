import React from 'react';
import { modifierDTO } from '../../../types/ProductDto';
import { useLocation, useNavigate } from 'react-router-dom';
import { useToasterLogic } from '../../../utils/useToasterLogic';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import queries from '../../../api/queries/SubscriptionQueries';
import useGeneralStore from '../../../store/GeneralStore';

export const useConfiguratorLogic = (modifiedItem: any, _instance?: any, create?: boolean) => {
  const path = useLocation()?.pathname;
  const queryClient = useQueryClient();
  const navigate = useNavigate();
  const { errorToast, successToast } = useToasterLogic();
  const [sliderModifiers, setSilderModifiers] = React.useState<modifierDTO[]>([]);
  const [notSliderModifiers, setNotSilderModifiers] = React.useState<modifierDTO[]>([]);
  const [dtoModifiers, setDtoModifiers] = React.useState<any>();
  const [mementoDtoModifiers, setMementoDtoModifiers] = React.useState<any>();
  const [paymentType, setPaymentType] = React.useState<string>('RECURRING_PAYMENT');
  const [quantity, setQuantity] = React.useState<number>(1);
  const [confirmOpen, setConfirmOpen] = React.useState<boolean>();

  const routeChange = (route: string) => {
    navigate(route);
  };

  const { subscription }: any = useGeneralStore((state) => ({
    subscription: state.subscription
  }));

  /*eslint-disable*/

  const patchInstance = useMutation({
    mutationFn: () =>
      queries.patchProductInstance({
        pIId: _instance['id'],
        config: { modifierURI: JSON.stringify(dtoModifiers) }
      }),
    onSuccess: () => {
      queryClient.invalidateQueries(['subscription']);
      successToast('Sikeres módosítás');
    },
    onError: (error: any) => {
      errorToast(error.message);
    }
  });
  const createInstance = useMutation({
    mutationFn: () =>
      queries.createInstance({
        subscriptionId: subscription?.id,
        instanceDTO: { productId: modifiedItem?.id, modifierUri: JSON.stringify(dtoModifiers), quantity: quantity, bundleNumber: 0 }
      }),
    onSuccess: () => {
      queryClient.invalidateQueries(['subscription']);
      successToast('Sikeres létrehozás');
    },
    onError: (error: any) => {
      errorToast(error.message);
    }
  });

  React.useEffect(() => {
    if (modifiedItem?.paymentType) {
      setPaymentType(modifiedItem?.paymentType);
    }
  }, [modifiedItem]);

  React.useEffect(() => {
    if (modifiedItem?.modifiers?.length > 0) {
      setNotSilderModifiers(
        (modifiedItem?.modifiers as any)?.filter(
          (m: modifierDTO) => m?.modifierCategory !== 'SLIDER' && m?.displayName !== ''
        )
      );
      setSilderModifiers(
        (modifiedItem?.modifiers as any)?.filter(
          (m: modifierDTO) => m?.modifierCategory === 'SLIDER' && m?.displayName !== ''
        )
      );
      if (create) {
        let modis: any = {};
        modifiedItem?.modifiers?.forEach((m: any) => {

          modis = {
            ...modis, [m.modifierKey]: m?.defaultValue
              ? m?.defaultValue
              : m.modifierCategory === 'CHECKBOX' && !m?.defaultValue
                ? 0
                : ''
          };

        })
        console.log(modis)
        setDtoModifiers(modis);
      } else {
        let modis = {};
        modis = JSON.parse(_instance.modifierURI);

        let mementoModis = {};
        mementoModis = _instance.mementoModifierURI ? JSON.parse(_instance.mementoModifierURI) : {};

        setDtoModifiers(modis);
        setMementoDtoModifiers(mementoModis);
      }

    }
  }, [modifiedItem?.modifiers]);

  return {
    paymentType,
    setPaymentType,
    modifiedItem,
    routeChange,
    path,
    dtoModifiers,
    mementoDtoModifiers,
    sliderModifiers,
    notSliderModifiers,
    setDtoModifiers,
    patchInstance,
    createInstance,
    setQuantity,
    confirmOpen,
    setConfirmOpen
  };
};
