import * as React from 'react';
import { useState } from 'react';
import styles from './tags.module.css';
import { IconButton, TextField } from '@mui/material';
import { MdAdd, MdCheck, MdClose, MdDelete, MdCreate, MdOutlineSearch } from 'react-icons/md';
import { useTagsLogic } from './useTagsLogic';
import { useNavigate } from 'react-router-dom';

const Tags = () => {
  const [newTag, setNewTag] = useState<boolean>(false);
  const [editTag, setEditTag] = useState<number>(-1);
  const [tagText, setTagText] = useState<string>('');
  const [editTagText, setEditTagText] = useState<string>('');
  const [tagFinder, setTagFinder] = useState<string>('');

  const navigate = useNavigate();

  const { fetchTags, postTag, deleteTag, putTag, products, setNewTagText, setFindTagText, setProductFetch } =
    useTagsLogic();

  return (
    <div className={styles.mainContainer}>
      <div className={styles.header}>
        <h1 className={styles.headerTitle}>Címkék</h1>
      </div>
      <div className={styles.tagsBlock}>
        <h2>Címkék kezelése</h2>
        <div>
          {newTag ? (
            <div className={styles.newTagText}>
              <TextField
                variant='outlined'
                label='Új címke neve'
                value={tagText}
                onChange={(e) => {
                  setTagText(e.target.value);
                }}
              />
              <IconButton
                onClick={() => {
                  setNewTagText(tagText);
                  postTag.mutate();
                }}>
                <MdCheck color='green'></MdCheck>
              </IconButton>
              <IconButton
                onClick={() => {
                  setNewTag(false);
                  setTagText('');
                }}>
                <MdClose color='#FF5067'></MdClose>
              </IconButton>
            </div>
          ) : (
            <div
              className={styles.newTagButton}
              onClick={() => {
                setNewTag(true);
              }}>
              <MdAdd color='#004070' size={'30px'}></MdAdd>
            </div>
          )}
        </div>
        <div className={styles.pillsContainer}>
          {fetchTags.data?.map((el: any) => {
            return (
              <div key={el.id} className={styles.tagsPills}>
                {editTag === el.id ? (
                  <div>
                    <TextField
                      variant='outlined'
                      label='Új címke neve'
                      value={editTagText === '' ? el.name : editTagText}
                      onChange={(e) => {
                        setEditTagText(e.target.value);
                      }}
                    />
                  </div>
                ) : (
                  <div className={styles.taxText}>{el.name}</div>
                )}
                {editTag === el.id ? (
                  <div className={styles.pillButtonContainer}>
                    <div>
                      <IconButton
                        onClick={() => {
                          setNewTagText(editTagText);
                          putTag.mutate(el.id);
                          setEditTagText('');
                          setEditTag(-1);
                        }}>
                        <MdCheck color='green'></MdCheck>
                      </IconButton>
                    </div>
                    <div>
                      <IconButton
                        onClick={() => {
                          setEditTag(-1);
                          setEditTagText('');
                        }}>
                        <MdClose color='#FF5067'></MdClose>
                      </IconButton>
                    </div>
                  </div>
                ) : (
                  <div className={styles.pillButtonContainer}>
                    <div>
                      <IconButton
                        onClick={() => {
                          setEditTag(el.id);
                        }}>
                        <MdCreate color='#004070'></MdCreate>
                      </IconButton>
                    </div>
                    <div>
                      <IconButton
                        onClick={() => {
                          deleteTag.mutate(el.id);
                        }}>
                        <MdDelete color='#FF5067'></MdDelete>
                      </IconButton>
                    </div>
                  </div>
                )}
              </div>
            );
          })}
        </div>
      </div>
      <div className={styles.tagsBlock}>
        <h2>Címkéhez kötött termékek kezelése</h2>
        <div className={styles.newTagText}>
          <TextField
            variant='outlined'
            value={tagFinder}
            onChange={(e) => {
              setTagFinder(e.target.value);
            }}
          />
          <IconButton
            onClick={() => {
              setFindTagText(tagFinder);
              setProductFetch(true);
            }}>
            <MdOutlineSearch></MdOutlineSearch>
          </IconButton>
        </div>
        <div className={styles.pillsContainer}>
          {products.map((el: any, i: any) => {
            return (
              <div
                key={i}
                className={styles.tagsPills}
                onClick={() => {
                  navigate(`/groups/${el.productGroupId}`);
                }}>
                <div>{el.id}</div>
                <div>{el.name}</div>
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
};

export default Tags;
