import axios from 'axios';
import { api } from '../axios';
import ENV from '../env';

export default {
  async fetchTags() {
    return await (await api({ baseUrl: ENV.REACT_APP_API_URL, auth: true })).get(`admin/tags`);
  },
  async createProductTag({ prodId, tagDto }) {
    return await (
      await api({ baseUrl: ENV.REACT_APP_API_URL, auth: true })
    ).post(`admin/products/${prodId}/tags`, tagDto);
  },
  async deleteProductTag({ prodId, tagDto }) {
    return await (
      await api({ baseUrl: ENV.REACT_APP_API_URL, auth: true })
    ).delete(`admin/products/${prodId}/tags/${tagDto.name}`, tagDto);
  },
  async fetchGroups() {
    return await (await api({ baseUrl: ENV.REACT_APP_API_URL, auth: true })).get(`admin/groups`);
  },
  async deleteGroup({ id }) {
    return await (await api({ baseUrl: ENV.REACT_APP_API_URL, auth: true })).delete(`admin/groups/${id}`);
  },
  async createGroup({ groupDto }) {
    return await (await api({ baseUrl: ENV.REACT_APP_API_URL, auth: true })).post(`admin/groups`, groupDto);
  },
  async updateGroup({ groupDto }) {
    return await (await api({ baseUrl: ENV.REACT_APP_API_URL, auth: true })).put(`admin/groups`, groupDto);
  },
  async fetchGroupById({ id }) {
    return await (await api({ baseUrl: ENV.REACT_APP_API_URL, auth: true })).get(`admin/groups/${id}`);
  },
  async fetchProducts({ groupId, currency }) {
    return await (
      await api({ baseUrl: ENV.REACT_APP_API_URL, auth: true })
    ).get(`admin/groups/${groupId}/products?currency=${currency}`);
  },
  async fetchProductById({ productId, currency }) {
    return await (
      await api({ baseUrl: ENV.REACT_APP_API_URL, auth: true })
    ).get(`admin/products/${productId}?currency=${currency}`);
  },
  async createProduct({ productDto, groupId }) {
    return await (
      await api({ baseUrl: ENV.REACT_APP_API_URL, auth: true })
    ).post(`admin/groups/${groupId}/products`, productDto);
  },
  async updateProduct({ productDto, id }) {
    return await (await api({ baseUrl: ENV.REACT_APP_API_URL, auth: true })).put(`admin/products/${id}`, productDto);
  },
  async deleteProduct({ id }) {
    return await (await api({ baseUrl: ENV.REACT_APP_API_URL, auth: true })).delete(`admin/products/${id}`);
  },
  async fetchKey({ compId }) {
    return await (
      await api({ baseUrl: ENV.REACT_APP_API_URL, auth: true })
    ).get('admin/compartments/' + compId + '/ssh-key');
  },
  async fetchProductImage({ id }) {
    return await (
      await api({ baseUrl: ENV.REACT_APP_API_URL, auth: false })
    ).get(`/products/${id}/image`, { responseType: 'blob' });
  },
  async fetchModifierImage({ modId, prodId }) {
    return await (
      await api({ baseUrl: ENV.REACT_APP_API_URL, auth: false })
    ).get(`products/${prodId}/modifiers/${modId}/image`, { responseType: 'blob' });
  },
  async fetchGroupImage({ id }) {
    return await (
      await api({ baseUrl: ENV.REACT_APP_API_URL, auth: false })
    ).get(`/groups/${id}/image`, { responseType: 'blob' });
  },

  async fetchGroupBundles({ id }) {
    return await (await api({ baseUrl: ENV.REACT_APP_API_URL, auth: false })).get(`admin/groups/${id}/groups`);
  },

  async deleteImage({ url }) {
    return await (await api({ baseUrl: ENV.REACT_APP_API_URL, auth: true })).delete(url);
  },

  convertBlobToBase64(blob) {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.onerror = reject;
      reader.onload = () => {
        resolve(reader.result);
      };
      reader.readAsDataURL(blob);
    });
  },
  async uploadRequest({ setLoading, file, onSuccess, onProgress, url }) {
    const form = new FormData();
    form.append('imageFile', file);
    setLoading(true);
    const CancelToken = axios.CancelToken;
    const source = CancelToken.source();
    await (
      await api({ baseUrl: ENV.REACT_APP_API_URL, auth: true })
    )
      .post(url, form, {
        headers: {
          'Content-Type': 'multipart/form-data'
        },
        onUploadProgress: ({ total, loaded }) => {
          onProgress(1, Math.round((loaded / total) * 100));
        },
        cancelToken: source.token
      })
      .then(({ data: response }) => {
        onSuccess(1, response);
        setLoading(false);
      })
      .catch((error) => {
        // onError(uid, {
        //   action,
        //   status: error.request,
        //   response: error.response
        // });
        setLoading(false);
        console.log(error);
      });
  }
};
