import {
  Button,
  Checkbox,
  FormControl,
  // FormControl,
  FormControlLabel,
  InputLabel,
  // InputLabel,
  MenuItem,
  Select,
  TextField
} from '@mui/material';
import { Formik } from 'formik';
import React from 'react';
import { useModifierLogic } from './useModifierLogic';
import { useValidationLogic } from '../../../validation/useValidationLogic';
import styles from './modifierForm.module.css';
import { modifierDTO } from '../../../types/ProductDto';

interface ModifierFormProps {
  handleSubmit: (_modifierData: modifierDTO) => void;
  modifier: modifierDTO | 'new';
}

const ModifierForm: React.FC<ModifierFormProps> = ({ handleSubmit, modifier }) => {
  const { modifierData, setModifierData } = useModifierLogic(modifier);
  const modifierCategories = ['SLIDER', 'CHECKBOX', 'RADIO_BUTTON', 'TEXT', 'SELECT', 'TEXT_FIELD'];
  // const modifierKeys = ['cpuCores', 'disk', 'domain', 'email', 'operatingSystem', 'memory', 'publicIp', 'vpn'];

  const { ModifierSchema } = useValidationLogic();

  const getModifierPrice = (currency: string, prices: any) => {
    return prices?.find((price: any) => price.currency === currency);
  };

  const updateModifierData = (newValue: number, currency: string) => {
    setModifierData((prevModifierData: any) => {
      let updatedPrices = prevModifierData.prices || []; // If prices array is undefined, initialize it to an empty array

      updatedPrices = updatedPrices.map((price: any) => {
        if (price.currency === currency) {
          return { ...price, grossAmount: newValue };
        }
        return price;
      });

      // If there's no object with the specified currency, create a new one
      if (updatedPrices.findIndex((price: any) => price.currency === currency) === -1) {
        updatedPrices.push({
          modifierId: modifier === 'new' ? null : modifierData?.id,
          grossAmount: newValue,
          taxRate: 0.27,
          currency: currency
        });
      }

      return { ...prevModifierData, prices: updatedPrices };
    });
  };

  return (
    <div>
      <Formik
        enableReinitialize
        initialValues={{
          modifierKey: modifierData?.modifierKey,
          description: modifierData?.description,
          modifierCategory: modifierData?.modifierCategory ?? 'SLIDER',
          displayName: modifierData?.displayName,
          minValue: modifierData?.minValue,
          maxValue: modifierData?.maxValue,
          defaultValue: modifierData?.defaultValue,
          scale: modifierData?.scale,
          grossAmountHUF: getModifierPrice('HUF', modifierData?.prices)?.grossAmount ?? undefined,
          grossAmountEUR: getModifierPrice('EUR', modifierData?.prices)?.grossAmount ?? undefined,
          possibleValues: modifierData?.possibleValues?.join('_')
        }}
        validationSchema={ModifierSchema}
        onSubmit={() => {
          handleSubmit(modifierData);
        }}>
        {({ values, errors, touched, handleChange, handleSubmit }) => (
          <form onSubmit={handleSubmit} className={styles.form}>
            <TextField
              id='modifierKey'
              name='modifierKey'
              autoComplete='modifierKey'
              value={values.modifierKey}
              autoFocus
              label={'Termék tulajdonság kulcsa'}
              className={styles.input}
              onChange={(e) => {
                e.preventDefault();
                setModifierData({ ...modifierData, modifierKey: e.target.value });
              }}
              error={touched.modifierKey && Boolean(errors.modifierKey)}
              helperText={touched.modifierKey && Boolean(errors.modifierKey) ? <>{errors.modifierKey}</> : <></>}
            />
            <TextField
              id='displayName'
              name='displayName'
              autoComplete='displayName'
              value={values.displayName}
              label={'Termék tulajdonság megjelenített neve'}
              className={styles.input}
              onChange={(e) => {
                handleChange(e);
                setModifierData({ ...modifierData, displayName: e.target.value });
              }}
              error={touched.displayName && Boolean(errors.displayName)}
              helperText={touched.displayName && Boolean(errors.displayName) ? <>{errors.displayName}</> : <></>}
            />
            <TextField
              id='description'
              name='description'
              autoComplete='description'
              value={values.description}
              label={'Leírás'}
              className={styles.input}
              onChange={(e) => {
                handleChange(e);
                setModifierData({ ...modifierData, description: e.target.value });
              }}
              error={touched.description && Boolean(errors.description)}
              helperText={touched.description && Boolean(errors.description) ? <>{errors.description}</> : <></>}
            />
            <FormControl className={styles.input}>
              <InputLabel style={{ background: 'var(--color-background-cards)' }}>Termék tulajdonság típusa</InputLabel>
              <Select
                id='modifierCategory'
                name='modifierCategory'
                autoComplete='modifierCategory'
                value={values?.modifierCategory}
                onChange={(e) => {
                  handleChange(e);
                  setModifierData({ ...modifierData, modifierCategory: e.target.value });
                }}
                error={touched.modifierCategory && Boolean(errors.modifierCategory)}>
                {modifierCategories?.map((type: string) => (
                  <MenuItem key={`select_item_${type}`} value={type}>
                    {type}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
            <div
              className={styles.formItem}
              hidden={['SLIDER', 'CHECKBOX', 'TEXT', 'TEXT_FIELD'].includes(values.modifierCategory)}>
              <TextField
                id='possibleValues'
                name='possibleValues'
                autoComplete='possibleValues'
                value={values?.possibleValues}
                placeholder='opció 1_opció 2_opció 3...'
                label={'Választható értékek'}
                className={styles.input}
                onChange={(e) => {
                  handleChange(e);
                  setModifierData({
                    ...modifierData,
                    possibleValues: e.target.value === '' ? null : e.target.value?.split('_')
                  });
                }}
                error={touched.minValue && Boolean(errors.minValue)}
                helperText={touched.minValue && Boolean(errors.minValue) ? <>{errors.displayName}</> : <></>}
              />
            </div>
            <div
              className={styles.formItem}
              hidden={['RADIO_BUTTON', 'SELECT', 'TEXT', 'TEXT_FIELD', 'CHECKBOX'].includes(values.modifierCategory)}>
              <TextField
                id='minValue'
                name='minValue'
                autoComplete='minValue'
                value={values.minValue}
                type='number'
                label={'Minimális érték'}
                className={styles.input}
                onChange={(e) => {
                  handleChange(e);
                  setModifierData({ ...modifierData, minValue: +e.target.value });
                }}
                error={touched.minValue && Boolean(errors.minValue)}
                helperText={touched.minValue && Boolean(errors.minValue) ? <>{errors.minValue}</> : <></>}
              />
            </div>
            <div
              className={styles.formItem}
              hidden={['RADIO_BUTTON', 'SELECT', 'TEXT', 'TEXT_FIELD', 'CHECKBOX'].includes(values.modifierCategory)}>
              <TextField
                id='maxValue'
                name='maxValue'
                autoComplete='maxValue'
                value={values.maxValue}
                type='number'
                label={'Maximális érték'}
                className={styles.input}
                onChange={(e) => {
                  handleChange(e);
                  setModifierData({ ...modifierData, maxValue: +e.target.value });
                }}
                error={touched.maxValue && Boolean(errors.maxValue)}
                helperText={touched.maxValue && Boolean(errors.maxValue) ? <>{errors.maxValue}</> : <></>}
              />
            </div>
            <div
              className={styles.formItem}
              hidden={['RADIO_BUTTON', 'SELECT', 'TEXT', 'TEXT_FIELD'].includes(values.modifierCategory)}>
              <TextField
                id='defaultValue'
                name='defaultValue'
                autoComplete='defaultValue'
                value={values.defaultValue}
                type='number'
                label={'Alapértelmezett érték'}
                className={styles.input}
                onChange={(e) => {
                  handleChange(e);
                  setModifierData({ ...modifierData, defaultValue: +e.target.value });
                }}
                error={touched.defaultValue && Boolean(errors.defaultValue)}
                helperText={touched.defaultValue && Boolean(errors.defaultValue) ? <>{errors.defaultValue}</> : <></>}
              />
            </div>
            <div
              className={styles.formItem}
              hidden={['RADIO_BUTTON', 'SELECT', 'TEXT', 'TEXT_FIELD', 'CHECKBOX'].includes(values.modifierCategory)}>
              <TextField
                id='scale'
                name='scale'
                autoComplete='scale'
                value={values.scale}
                type='number'
                label={'Módosítás mértéke'}
                className={styles.input}
                onChange={(e) => {
                  handleChange(e);
                  setModifierData({ ...modifierData, scale: +e.target.value });
                }}
                error={touched.scale && Boolean(errors.scale)}
                helperText={touched.scale && Boolean(errors.scale) ? <>{errors.scale}</> : <></>}
              />
            </div>
            <TextField
              id='grossAmountHUF'
              name='grossAmountHUF'
              autoComplete='grossAmountHUF'
              value={values.grossAmountHUF}
              type='number'
              label={'Ár (HUF)'}
              className={styles.input}
              onChange={(e) => {
                handleChange(e);
                updateModifierData(+e.target.value, 'HUF');
              }}
              error={touched.grossAmountHUF && Boolean(errors.grossAmountHUF)}
              helperText={
                touched.grossAmountHUF && Boolean(errors.grossAmountHUF) ? <>{errors.grossAmountHUF}</> : <></>
              }
            />
            <TextField
              id='grossAmountEUR'
              name='grossAmountEUR'
              autoComplete='grossAmountEUR'
              value={values.grossAmountEUR}
              type='number'
              label={'Ár (EUR)'}
              className={styles.input}
              onChange={(e) => {
                handleChange(e);
                updateModifierData(+e.target.value, 'EUR');
              }}
              error={touched.grossAmountEUR && Boolean(errors.grossAmountEUR)}
              helperText={
                touched.grossAmountEUR && Boolean(errors.grossAmountEUR) ? <>{errors.grossAmountEUR}</> : <></>
              }
            />
            <FormControlLabel
              control={
                <Checkbox
                  checked={modifierData?.optional}
                  onChange={() => setModifierData({ ...modifierData, optional: modifierData?.optional ? false : true })}
                />
              }
              label={'Opcionális?'}
            />
            <div className={styles.btnGroup}>
              <Button variant='contained' type='submit' className={styles.button}>
                Mentés
              </Button>
            </div>
          </form>
        )}
      </Formik>
    </div>
  );
};

export default ModifierForm;
