import { api } from '../axios';
import ENV from '../env';

export default {
  async fetchCurrent() {
    return await (await api({ baseUrl: ENV.REACT_APP_API_URL, auth: true })).get(`admin/users/current`);
  },

  async fetchAllUsers() {
    return await (await api({ baseUrl: ENV.REACT_APP_API_URL, auth: true })).get(`admin/users`);
  },
  async fetchUserById({ id }) {
    return await (await api({ baseUrl: ENV.REACT_APP_API_URL, auth: true })).get(`admin/users/${id}`);
  },
  async fetchUserBilling({ compId, subId }) {
    return await (
      await api({ baseUrl: ENV.REACT_APP_API_URL, auth: true })
    ).get(`${ENV.REACT_APP_API_URL}/admin/compartments/${compId}/subscriptions/${subId}/billing-info`);
  }
};
