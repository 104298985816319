import React, { useState } from 'react';
import ReactImageUploading from 'react-images-uploading';
import { useUploaderLogic } from './useUploaderLogic';
import { Button } from '@mui/material';
import styles from './uploader.module.css';

interface UploaderProps {
  id: number;
  setImage: any;
  image: any;
  url: string;
  parentId?: number;
  percentageBg?: string;
  fullWidth?: boolean;
  variant?: 'contained' | 'outlined';
}

import CircularProgress, { CircularProgressProps } from '@mui/material/CircularProgress';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import ConfirmDialog from '../../general/confirmDialog/ConfirmDialog';

function CircularProgressWithLabel(props: CircularProgressProps & { value: number }) {
  return (
    <Box sx={{ position: 'relative', display: 'inline-flex' }}>
      <CircularProgress variant='determinate' {...props} />
      <Box
        sx={{
          top: 0,
          left: 0,
          bottom: 0,
          right: 0,
          position: 'absolute',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center'
        }}>
        <Typography variant='caption' component='div' color='text.secondary'>{`${Math.round(
          props.value
        )}%`}</Typography>
      </Box>
    </Box>
  );
}

const Uploader: React.FC<UploaderProps> = ({
  id,
  setImage,
  image,
  url,
  parentId,
  fullWidth = false,
  variant = 'contained'
}) => {
  const { onChange, handleImageDelete, handleUpload, /* loading,*/ progress, imageList } = useUploaderLogic(
    id,
    setImage,
    url,
    parentId && parentId
  );

  const [deleteDialog, setDeleteDialog] = useState<boolean>(false);

  return (
    <>
      <ReactImageUploading
        value={imageList}
        onChange={onChange}
        maxNumber={1}
        dataURLKey='data_url'
        acceptType={['jpg', 'jpeg', 'png']}>
        {({ imageList, onImageUpload, /* onImageRemoveAll,*/ onImageUpdate, onImageRemove, isDragging, dragProps }) => (
          // write your building UI
          <div className={styles.uploader}>
            {!image && (
              <Button
                variant={variant == 'outlined' ? 'outlined' : 'contained'}
                fullWidth={fullWidth}
                style={isDragging ? { color: 'red' } : undefined}
                onClick={onImageUpload}
                {...dragProps}>
                Kattints vagy húzz ide egy képet
              </Button>
            )}
            &nbsp;
            {imageList.length < 1 && image && (
              <Button
                variant={variant == 'outlined' ? 'outlined' : 'contained'}
                fullWidth={fullWidth}
                onClick={() => {
                  setDeleteDialog(true);
                }}>
                Kép törlése
              </Button>
            )}
            {imageList.map((image, index) => (
              <div key={index} className={styles.imageItem}>
                {/* <img src={image['data_url']} alt='' width='100' /> */}
                <div className={styles.cancel_upload}>
                  <Button
                    variant={variant == 'outlined' ? 'outlined' : 'contained'}
                    onClick={() => onImageUpdate(index)}>
                    Választok másikat
                  </Button>
                  <Button
                    variant={variant == 'outlined' ? 'outlined' : 'contained'}
                    onClick={() => onImageRemove(index)}>
                    Mégsem
                  </Button>
                </div>
              </div>
            ))}
            {imageList.length > 0 && (
              <Button
                variant={variant == 'outlined' ? 'outlined' : 'contained'}
                fullWidth={fullWidth}
                onClick={() => handleUpload()}>
                feltölt
              </Button>
            )}
            {progress && progress < 100 ? <CircularProgressWithLabel value={progress} /> : <></>}
          </div>
        )}
      </ReactImageUploading>
      <ConfirmDialog
        open={deleteDialog}
        setOpen={setDeleteDialog}
        title={'Biztosan törölni szeretnéd a képet?'}
        action={() => {
          handleImageDelete();
          setImage(null);
          setDeleteDialog(false);
        }}
        loading={false}
      />
    </>
  );
};

export default Uploader;
