import React, { useEffect } from 'react';
import { useUsersLogic } from './useUsersLogic';
import styles from './users.module.css';
import { Slide } from '@mui/material';

const Users = () => {
  const { users } = useUsersLogic();
  useEffect(() => {
    console.log(users);
  }, [users]);

  return (
    <div className={styles.mainContainer}>
      <div className={styles.header}>
        <h1 className={styles.headerTitle}>Felhasználók</h1>
      </div>
      <div className={styles.userListWrapper}>
        <table className={styles.ownGridHeader}>
          <thead className={styles.thead}>
            <tr className={styles.tablecell}>
              <td>ExternalId</td>
            </tr>
            <tr className={styles.tablecell}>
              <td>Felhasználónév</td>
            </tr>
            <tr className={styles.tablecell}>
              <td>Teljes név</td>
            </tr>
            <tr className={styles.tablecell}>
              <td>Email cím</td>
            </tr>
            <tr className={styles.tablecell}>
              <td>Státusz</td>
            </tr>
          </thead>
        </table>

        {users?.map((user: any) => (
          <div key={`user_item_${user?.externalId}`} className={styles.gridItem}>
            <Slide direction='right' timeout={500} in={true}>
              <div className={styles.service} onClick={() => {}}>
                <div className={styles.serviceSummary}>
                  <div style={{ display: 'flex', width: '100%', alignItems: 'center', height: '100%' }}>
                    <div className={styles.tablecell}>
                      <p className={styles.tableCellItem}>{user?.externalId}</p>
                    </div>
                    <div className={styles.tablecell}>
                      <p className={styles.tableCellItem}>{user?.username}</p>
                    </div>
                    <div className={styles.tablecell}>
                      <p className={styles.tableCellItem}>
                        {user?.lastname} {user?.firstname}
                      </p>
                    </div>
                    <div className={styles.tablecell}>
                      <p className={styles.tableCellItem}>{user?.email}</p>
                    </div>
                    <div className={styles.tablecell}>
                      <div className={styles.tableCellItem}>
                        {user?.active ? (
                          <div className={styles.dot} style={{ backgroundColor: 'green' }}></div>
                        ) : (
                          <div className={styles.dot} style={{ backgroundColor: 'red' }}></div>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </Slide>
          </div>
        ))}
      </div>
    </div>
  );
};

export default Users;
