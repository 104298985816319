import React from 'react';
import { useState } from 'react';
import { Button, IconButton, Tooltip, Typography } from '@mui/material';
import styles from './queryParam.module.css';
import { useEffect } from 'react';
import QueryParamEditor from '../QueryParamEditor/QueryParamEditor';
import { QueryOptionDto } from '../../../../types/QueryBuilderDtos';
import { MdHighlightOff } from 'react-icons/md';

interface QueryParamProps {
  option: QueryOptionDto;
  setOption: any;
  disableOption: any;
  setIsAddDisabled: any;
}

const QueryParam: React.FC<QueryParamProps> = ({ option, setOption, disableOption, setIsAddDisabled }) => {
  const [isOpen, setIsOpen] = useState(option.value === '');
  const [hover, setHover] = useState(false);
  const toggleisOpen = () => setIsOpen(!isOpen);
  const [displayTitle, setDisplayTitle] = useState('');
  const [tempOption, setTempOption] = useState(option);

  const getDateString = (date: any) => {
    const options = { timeZone: 'UTC' };
    console.log(hover);
    return new Date(date).toLocaleDateString('hu-HU', options);
  };

  useEffect(() => {
    setIsAddDisabled(isOpen);
  }, [isOpen]);
  const getDisplayFields = (key: any, value: any) => {
    const displayKey = (option as any)?.keys?.find((k: any) => k.name === key).displayName;
    const displayValue = (option as any)?.values?.find((k: any) => k.name === value).displayName;
    return [displayKey, displayValue];
  };

  const getDisplayedSelectValues = (value: any) => {
    let displayedValue = '';
    (option as any)?.values?.forEach((k: any, idx: number) => {
      if (k.toString() == value) {
        displayedValue = (option as any)?.displayedValues[idx];
      }
    });
    return displayedValue;
  };
  useEffect(() => {
    setTempOption(option);
  }, [option]);

  /*eslint-disable*/
  useEffect(() => {
    switch (tempOption.type) {
      case 'date':
        const params = tempOption.value.split('&');
        const values = params.map((param) => getDateString(param.split('=')[1]));
        const fromDate = values[0];
        const toDate = values[1];
        setDisplayTitle(`${tempOption.displayName}${tempOption.value !== '' ? `= ${fromDate}-tól ${toDate}-ig` : ''}`);
        break;
      case 'select':
        if (tempOption.value !== '') {
          const value = tempOption.value.split('=')[1];
          const displayedName = getDisplayedSelectValues(value);
          setDisplayTitle(`${tempOption.displayName}${tempOption.value !== '' ? `= ${displayedName}` : ''}`);
        } else {
          setDisplayTitle(`${tempOption.displayName}`);
        }
        break;
      case 'reportingDate':
        const reportinParams = tempOption.value.split('&');
        const reportingvalues = reportinParams.map((param) => getDateString(param.split('=')[1]));
        const rFromDate = reportingvalues[0];
        const rtoDate = reportingvalues[1];
        setDisplayTitle(
          `${tempOption.displayName}${tempOption.value !== '' ? `= ${rFromDate}-tól ${rtoDate}-ig` : ''}`
        );
        break;
      case 'key-value':
        if (tempOption.value !== '') {
          const key = tempOption.value.split('=')[1].split(',')[0];
          const value = tempOption.value.split('=')[1].split(',')[1];
          const [displayKey, displayName] = getDisplayFields(key, value);
          setDisplayTitle(
            `${tempOption.displayName}${tempOption.value !== '' ? `= ${displayKey}, ${displayName}` : ''}`
          );
        } else {
          setDisplayTitle(`${tempOption.displayName}`);
        }
        break;
      case 'pagination':
        break;
      default:
        /*if(tempOption.value === "isActive=ISPRINTED"){
          tempOption.value = "isPrinted=true"
        }else if (tempOption.value === "isActive=ISPRINTEDDONE"){
          tempOption.value = "isPrintedDone=true"
        }*/
        setDisplayTitle(
          decodeURI(`${tempOption.displayName}${tempOption.value !== '' ? `= ${tempOption.value.split('=')[1]}` : ''}`)
        );
        break;
    }
  }, [tempOption]);

  const confirm = () => {
    setOption(tempOption);
  };

  return (
    <Button variant='outlined' className={styles.button}>
      <Tooltip title={`${option.displayName} szerkesztése`}>
        <Typography onClick={toggleisOpen} variant='body1' style={{ fontSize: '0.9rem' }}>
          {displayTitle}
        </Typography>
      </Tooltip>

      <IconButton
        onMouseEnter={() => setHover(true)}
        onMouseLeave={() => setHover(false)}
        onClick={(e) => {
          disableOption();
          setIsOpen(false);
          setIsAddDisabled(false);
          e.stopPropagation();
        }}>
        <MdHighlightOff style={{ color: 'var(--color-main)' }} />
      </IconButton>

      {isOpen && (
        <div className={styles.editorWrapper}>
          <QueryParamEditor tempOption={tempOption} setTempOption={setTempOption} />
          <Button
            color='primary'
            variant='outlined'
            disabled={tempOption.value === ''}
            onClick={() => {
              setIsOpen(false);
              setIsAddDisabled(false);
              confirm();
            }}>
            Ok
          </Button>
        </div>
      )}
    </Button>
  );
};

export default QueryParam;
