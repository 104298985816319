import React from 'react';
import { sortedServiceDTO } from '../../../types/ServicesDto';
import { Divider, useMediaQuery } from '@mui/material';
import styles from './serviceList.module.css';
import ServiceItem from '../ServiceItem/ServiceItem';

interface ServiceListProps {
  sortedServices: sortedServiceDTO[];
}

const ServiceList: React.FC<ServiceListProps> = ({ sortedServices }) => {
  const tablet = useMediaQuery('(min-width:480px)');
  const laptop = useMediaQuery('(min-width:1200px)');

  return (
    <>
      {sortedServices
        ?.sort((a: any, b: any) => a?.groupName?.toLowerCase().localeCompare(b?.groupName?.toLowerCase()))
        ?.map(({ groupName, instances }, i) => (
          <div key={i} className={styles.serviceListContainer}>
            <Divider variant='middle' style={{ width: '90%' }}>
              <h3 style={{ fontFamily: 'var(--font-main)', color: 'var(--color-main)' }}>{groupName}</h3>
            </Divider>
            <table className={styles.ownGridHeader}>
              {!laptop && !tablet ? (
                <thead className={styles.thead}>
                  <tr className={styles.tablecell}>
                    <td>{'Service name'}</td>
                  </tr>
                </thead>
              ) : !laptop && tablet ? (
                <thead className={styles.thead}>
                  <tr className={styles.tablecell}>
                    <td>{'Service name'}</td>
                  </tr>
                  <tr className={styles.tablecell}>
                    <td>{'System status'}</td>
                  </tr>
                </thead>
              ) : (
                <thead className={styles.thead}>
                  <tr className={styles.serviceName + ' ' + styles.tablecell}>
                    <td>{'Service name'}</td>
                  </tr>
                  <tr className={styles.tablecell}>
                    <td>{'CPU'}</td>
                  </tr>
                  <tr className={styles.tablecell}>
                    <td>{'Disk'}</td>
                  </tr>
                  <tr className={styles.tablecell}>
                    <td>{'Memory'}</td>
                  </tr>
                  <tr className={styles.tablecell}>
                    <td>{'Backup'}</td>
                  </tr>
                  <tr className={styles.tablecell}>
                    <td>{'OS'}</td>
                  </tr>
                  <tr className={styles.tablecell}>
                    <td>{'VPN'}</td>
                  </tr>
                  <tr className={styles.tablecell}>
                    <td>{'Public Ip'}</td>
                  </tr>
                  <tr className={styles.tablecell}>
                    <td>{'System status'}</td>
                  </tr>
                </thead>
              )}
              {/*eslint-disable */}
            </table>
            {instances
              ?.sort((a: any, b: any) => a.id - b.id)
              ?.map((pI: any, i) => (
                <ServiceItem key={pI['id']} service={pI} />
              ))}
          </div>
        ))}
    </>
  );
};

export default ServiceList;
