import React, { useEffect, useState } from 'react';
import monster from '../../assets/images/server_monster_2.svg';
import styles from './landing.module.css';
import { welcomeTexts } from './welcomeTexts';
import useMediaQuery from '@mui/material/useMediaQuery';
import ConfettiExplosion from 'react-confetti-explosion';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import Button from '@mui/material/Button';

type WTType = {
  origin: string;
  who: string;
  quote: string;
};

const Landing = () => {
  const [wText, setWText] = useState<WTType>();
  const [selectQuoteWho, setSelectQuoteWho] = React.useState<string>('');
  const [selectQuoteOrigin, setSelectQuoteOrigin] = React.useState<string>('');
  const [selectionQuoteWho, setSelectionQuoteWho] = React.useState<string[]>([]);
  const [selectionQuoteOrigin, setSelectionQuoteOrigin] = React.useState<string[]>([]);
  const [isExploding, setIsExploding] = React.useState<boolean>(false);
  const mobile = useMediaQuery('(min-width:480px)');
  const tablet = useMediaQuery('(min-width:992px)');

  const randomNumberInRange = (min: number, max: number) => {
    return Math.floor(Math.random() * (max - min + 1)) + min;
  };
  const newQuote = () => {
    const index = randomNumberInRange(0, welcomeTexts.length - 1);
    setWText(welcomeTexts[index]);
  };

  useEffect(() => {
    const index = randomNumberInRange(0, welcomeTexts.length - 1);
    let who: string[] = [];
    let origin: string[] = [];
    welcomeTexts.forEach((el) => {
      if (!who.includes(el.who) && !el.who.includes('Sparky')) {
        who.push(el.who);
      }
      if (!origin.includes(el.origin) && !el.who.includes('Sparky')) {
        origin.push(el.origin);
      }
    });
    setSelectionQuoteWho(who);
    setSelectionQuoteOrigin(origin);
    setSelectQuoteWho(who[randomNumberInRange(0, who.length - 1)]);
    setSelectQuoteOrigin(origin[randomNumberInRange(0, origin.length - 1)]);
    setWText(welcomeTexts[index]);
  }, [welcomeTexts]);

  return (
    <div className={styles.mainContainer}>
      <div className={styles.titleContainer}>
        <div>{wText?.quote}</div>
      </div>
      <div>
        {isExploding && (
          <ConfettiExplosion
            onComplete={() => setIsExploding(false)}
            particleCount={250}
            duration={3500}
            particleSize={15}
          />
        )}
      </div>
      <img src={monster} height={!mobile ? '200px' : !tablet ? '350px' : '500px'}></img>
      {tablet ? (
        <div>
          {!wText?.who.includes('Sparky') ? (
            <div className={styles.whoContainer}>
              <div>Hol hangzott el és ki mondta?</div>
              <div>
                <Select
                  value={selectQuoteOrigin}
                  className={styles.whiteText}
                  onChange={(e) => {
                    setSelectQuoteOrigin(e.target.value);
                  }}>
                  {selectionQuoteOrigin?.map((el, i) => {
                    return (
                      <MenuItem key={i} value={el}>
                        {el}
                      </MenuItem>
                    );
                  })}
                </Select>
              </div>
              <div>
                <Select
                  value={selectQuoteWho}
                  className={styles.whiteText}
                  onChange={(e) => {
                    setSelectQuoteWho(e.target.value);
                  }}>
                  {selectionQuoteWho?.map((el, i) => {
                    return (
                      <MenuItem key={i} value={el}>
                        {el}
                      </MenuItem>
                    );
                  })}
                </Select>
              </div>
              <Button
                className={styles.whiteText}
                variant='text'
                onClick={() => {
                  if (selectQuoteWho === wText?.who && selectQuoteOrigin === wText?.origin) {
                    setIsExploding(true);
                    newQuote();
                  }
                }}>
                Tipp
              </Button>
            </div>
          ) : (
            ''
          )}
        </div>
      ) : (
        ''
      )}
    </div>
  );
};

export default Landing;
