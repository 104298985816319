import React from 'react';
import styles from './configuratoRadioButton.module.css';
import { modifierDTO } from '../../../types/ProductDto';
import { FormControl, Radio, RadioGroup, FormControlLabel } from '@mui/material';

interface ConfiguratorRadioButtonProps {
  modifier: modifierDTO;
  dtoModifiers: any;
  mementoDtoModifiers?: any;
  setDtoModifiers: any;
}

const ConfiguratorRadioButton: React.FC<ConfiguratorRadioButtonProps> = ({
  modifier,
  dtoModifiers,
  mementoDtoModifiers,
  setDtoModifiers
}) => {
  return (
    <>
      {/*eslint-disable */}
      <h3 className={styles.title}>{modifier?.description}</h3>
      <FormControl style={{ width: '90%', color: 'var(--color-main) !important' }}>
        <RadioGroup
          value={dtoModifiers[modifier?.modifierKey]}
          onChange={(e: any) => setDtoModifiers({ ...dtoModifiers, [modifier?.modifierKey]: e.target.value })}>
          {modifier?.possibleValues?.map((value: any) => (
            <FormControlLabel
              value={value}
              control={
                <Radio
                  style={{
                    color: mementoDtoModifiers
                      ? value == mementoDtoModifiers[modifier?.modifierKey] &&
                        dtoModifiers[modifier?.modifierKey] != mementoDtoModifiers[modifier?.modifierKey]
                        ? '#FFC107'
                        : 'black'
                      : 'black'
                  }}
                />
              }
              label={value}
              style={{
                color: mementoDtoModifiers
                  ? value == mementoDtoModifiers[modifier?.modifierKey] &&
                    dtoModifiers[modifier?.modifierKey] != mementoDtoModifiers[modifier?.modifierKey]
                    ? '#FFC107'
                    : 'black'
                  : 'black'
              }}
            />
          ))}
        </RadioGroup>
      </FormControl>
    </>
  );
};

export default ConfiguratorRadioButton;
