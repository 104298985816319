import { Link } from '@mui/material';
import React from 'react';
import styles from './breadcrumb.module.css';
import { useNavigate } from 'react-router-dom';

interface BreadcrumbProps {
  name: string;
  to: any;
  linkName: string;
  baseColor: string;
}

const Breadcrumb: React.FC<BreadcrumbProps> = ({ name, linkName, to }) => {
  const navigate = useNavigate();

  /*eslint-disable*/

  return (
    <div id={`breadcrumb_${name}`} className={styles.breadcrumb}>
      <Link className={styles.link} title={linkName} target={to} onClick={() => navigate(to)}>
        {linkName}
      </Link>
      <p className={styles.breadcrumbText}>/</p>
      <p className={styles.breadcrumbText}>{name}</p>
    </div>
  );
};

export default Breadcrumb;
