import { Slider } from '@mui/material';
import styles from './configuratorSlider.module.css';
import React from 'react';
import { modifierDTO } from '../../../types/ProductDto';
import queries from '../../../api/queries/ProductQueries';
import { useQuery } from '@tanstack/react-query';
import useGeneralStore from '../../../store/GeneralStore';

interface ConfiguratorSliderProps {
  modifier: modifierDTO;
  dtoModifiers: any;
  mementoDtoModifiers?: any;
  setDtoModifiers: any;
  productId?: number;
}

const ConfiguratorSlider: React.FC<ConfiguratorSliderProps> = ({
  modifier,
  dtoModifiers,
  mementoDtoModifiers,
  setDtoModifiers,
  productId
}) => {
  const { modifierKeys } = useGeneralStore((state: any) => ({
    modifierKeys: state.modifierKeys
  }));

  const getUnit = (item: string) => {
    return (modifierKeys as any)?.find((k: any) => k.name == item)?.unitOfMeasurement === 'GB'
      ? 'GB'
      : (modifierKeys as any)?.find((k: any) => k.name == item)?.unitOfMeasurement;
  };

  const [modifierImage, setModifierImage] = React.useState<any>();
  /*eslint-disable*/
  const modifierImageFetchQuery = useQuery({
    queryKey: ['modifierImage', productId, modifier?.id],
    queryFn: () => queries.fetchModifierImage({ prodId: productId, modId: modifier?.id }),
    onSuccess: async (data: any) => {
      let image = await queries.convertBlobToBase64(await data);
      setModifierImage(image);
    },
    retry: (failureCount, error: any) => (error.response.status === 404 ? false : true),
    refetchOnWindowFocus: false,
    enabled: !!modifier?.id
  });

  return (
    <div className={styles.sliderWrapper}>
      <img src={modifierImage} alt={`modifierImage_${modifier?.id}`} width={'50px'} />
      <Slider
        className={styles.slider}
        slotProps={{ thumb: { className: styles.thumb } }}
        style={{ width: '80%' }}
        sx={{
          '& .MuiSlider-mark[data-index="2"]': {
            display: 'none'
          },
          '& .MuiSlider-markLabel[data-index="2"]': {
            color: 'black',
            backgroundColor: '#FFC107',
            borderRadius: '4px',
            padding: '1px 12px',
            fontSize: '0.6rem',
            whiteSpace: 'nowrap'
          },
          '& .MuiSlider-markLabel[data-index="2"]::before': {
            content: '""',
            position: 'absolute',
            top: '-10px',
            left: '50%',
            transform: 'translateX(-50%)',
            borderLeft: '4px solid transparent',
            borderRight: '4px solid transparent',
            borderTop: '4px solid white',
            borderBottom: '6px solid #FFC107'
          }
        }}
        value={dtoModifiers[modifier?.modifierKey]}
        min={modifier.minValue}
        max={modifier.maxValue}
        marks={[
          {
            value: modifier.minValue,
            label: `${modifier.minValue} ${modifierKeys?.length > 0 && getUnit(modifier?.modifierKey)}`
          },
          {
            value: modifier.maxValue,
            label: `${modifier.maxValue} ${modifierKeys?.length > 0 && getUnit(modifier?.modifierKey)}`
          },
          {
            value:
              mementoDtoModifiers &&
              dtoModifiers[modifier?.modifierKey] != mementoDtoModifiers[modifier?.modifierKey] &&
              mementoDtoModifiers[modifier?.modifierKey],
            label: `${
              mementoDtoModifiers &&
              dtoModifiers[modifier?.modifierKey] != mementoDtoModifiers[modifier?.modifierKey] &&
              mementoDtoModifiers[modifier?.modifierKey]
            }`
          }
        ]}
        step={modifier.scale}
        onChange={(e: any) => setDtoModifiers({ ...dtoModifiers, [modifier?.modifierKey]: e.target.value })}
        valueLabelDisplay='auto'
        key={modifier?.id}
      />
    </div>
  );
}; /*eslint-enable*/

export default ConfiguratorSlider;
