/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable no-unused-vars */
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { useRef, useState } from 'react';
import queries from '../../../api/queries/ProductQueries';
import { modifierDTO, tagDTO } from '../../../types/ProductDto';
import { useValidationLogic } from '../../../validation/useValidationLogic';
import { useToasterLogic } from '../../../utils/useToasterLogic';

export const useProductDialogLogic = (productData: any, setProductData: any) => {
  const [tags, setTags] = useState<tagDTO[]>([]);
  const { ProductSchema } = useValidationLogic();
  const [editModifier, setEditModifier] = useState<any>();
  const [addTag, setAddTag] = useState<boolean>();
  const [selectedTag, setSelectedTag] = useState<tagDTO | null | undefined>(null);
  const productTypes = ['MANUAL'];
  const { successToast, errorToast } = useToasterLogic();
  const [sure, setSure] = useState<boolean>(false);

  const queryClient = useQueryClient();

  const preProcessModifier = (modifier: any) => {
    switch (modifier.modifierCategory) {
      case 'SLIDER': {
        //Removing possibleValues from the object
        const { possibleValues, ...rest } = modifier;
        return { ...rest };
      }
      case 'CHECKBOX': {
        //Removing possibleValues from the object
        const { possibleValues, ...rest } = modifier;
        return { ...rest, minValue: 0, maxValue: 1, scale: 1 };
      }
      case 'RADIO_BUTTON': {
        //Removing minValue, maxValue, defaultValue, scale from the object
        const { minValue, maxValue, defaultValue, scale, ...rest } = modifier;
        return { ...rest };
      }
      case 'SELECT': {
        //Removing minValue, maxValue, defaultValue, scale from the object
        const { minValue, maxValue, defaultValue, scale, ...rest } = modifier;
        return { ...rest };
      }
      case 'TEXT': {
        //Removing minValue, maxValue, defaultValue, scale, possibleValues from the object
        const { minValue, maxValue, defaultValue, scale, possibleValues, ...rest } = modifier;
        return { ...rest };
      }
      case 'TEXT_FIELD': {
        //Removing minValue, maxValue, defaultValue, scale, possibleValues from the object
        const { minValue, maxValue, defaultValue, scale, possibleValues, ...rest } = modifier;
        return { ...rest };
      }
      default:
        return { ...modifier };
    }
  };

  const handleModifierSubmit = (modifier: modifierDTO) => {
    var modifiers: modifierDTO[] = [];

    let processedModifier = preProcessModifier(modifier);

    if (productData?.modifiers?.length > 0) {
      modifiers = productData?.modifiers?.filter((m: modifierDTO) => m.id !== processedModifier.id);
      modifiers.push(processedModifier);
      setProductData({ ...productData, modifiers: modifiers });
    } else {
      modifiers.push(processedModifier);
      setProductData({ ...productData, modifiers: modifiers });
    }
    setEditModifier(false);
  };

  /*eslint-disable*/
  const handleTagSubmit = () => {
    setProductData({ ...productData, tags: [...productData?.tags, selectedTag] });
    createProductTagQuery.mutate();
  };
  const handleTagDelete = () => {
    setProductData({ ...productData, tags: productData?.tags?.filter((t: tagDTO) => t.id !== selectedTag?.id) });
    deleteProductTagQuery.mutate();
  };

  const buttonRef = useRef(null);

  const tagsFetchQuery = useQuery({
    queryKey: ['tags'],
    queryFn: () => queries.fetchTags(),
    onSuccess: async (data) => {
      setTags(data as any);
    },
    retry: (failureCount, error: any) => (error.response.status === 404 ? false : true),
    refetchOnWindowFocus: false
  });

  const createProductTagQuery = useMutation({
    mutationFn: () => queries.createProductTag({ prodId: productData?.id, tagDto: selectedTag }),
    onSuccess: () => {
      queryClient.invalidateQueries(['group']);
      successToast('Sikeres címke hozzáadás');
      setSelectedTag(null);
      setAddTag(false);
    },
    onError: () => {
      errorToast('Sikertelen címke hozzáadás');
    }
  });
  const deleteProductTagQuery = useMutation({
    mutationFn: () => queries.deleteProductTag({ prodId: productData?.id, tagDto: selectedTag }),
    onSuccess: () => {
      queryClient.invalidateQueries(['group']);
      successToast('Sikeres címke leválasztás');
      setSelectedTag(null);
      setSure(false);
    },
    onError: () => {
      errorToast('Sikertelen címke leválasztás');
    }
  });

  return {
    tags,
    ProductSchema,
    editModifier,
    addTag,
    productTypes,
    handleModifierSubmit,
    handleTagSubmit,
    buttonRef,
    setEditModifier,
    setAddTag,
    setSelectedTag,
    selectedTag,
    createProductTagQuery,
    deleteProductTagQuery,
    setSure,
    sure,
    handleTagDelete
  };
};
