import './App.css';
import React from 'react';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
import Header from './components/general/header/Header';
import Footer from './components/general/footer/Footer';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import Landing from './pages/landing/Landing';
import './theme/common.css';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { routes } from './config/routes';
import Login from './pages/login/Login';
import Logout from './pages/logout/Logout';
import Groups from './pages/groups/Groups';
import Group from './pages/groups/Group/Group';
import Tags from './pages/tags/Tags';
import Discounts from './pages/discounts/Discounts';
import Users from './pages/users/Users';
import { useQuery } from '@tanstack/react-query';
import queries from './api/queries/GeneralQueries';
import userQueries from './api/queries/UserQueries';
import useGeneralStore from './store/GeneralStore';
import Subscriptions from './pages/subscriptions/Subscriptions';
import Subscription from './pages/subscriptions/Subscription/Subscription';
import Sidebar from './components/general/sidebar/Sidebar';
import Redirect from './pages/redirect/Redirect';
import Blogs from './pages/blogs/Blogs';
//import AuthService from './services/AuthService';

const App = () => {
  const { setModifierKeys, setCurrent, current, setCurrentCompartment } = useGeneralStore((state: any) => ({
    compartment: state.compartment,
    setModifierKeys: state.setModifierKeys,
    setCurrent: state.setCurrent,
    current: state.current,
    setCurrentCompartment: state.setCurrentCompartment
  }));

  /*eslint-disable*/
  const modifierKeyQuery = useQuery({
    queryKey: ['modifierKeys'],
    queryFn: () => queries.fetchModifierKeys(),
    staleTime: Infinity,
    cacheTime: Infinity,
    onSuccess: (data: any) => {
      setModifierKeys(data);
    }
  });
  const currentUserQuery = useQuery({
    queryKey: ['currentUser'],
    queryFn: () => userQueries.fetchCurrent(),
    staleTime: Infinity,
    cacheTime: Infinity,
    onSuccess: (data) => {
      setCurrent(data);
    }
  });
  const currentCompartmentQuery = useQuery({
    queryKey: ['currentCompartment'],
    queryFn: () => queries.fetchCurrentCompartment(),
    staleTime: Infinity,
    cacheTime: Infinity,
    onSuccess: (data: any) => {
      setCurrentCompartment(data[0]?.compartment);
    }
  });
  /*eslint-enable*/

  return (
    <>
      <BrowserRouter>
        <Header />
        {currentUserQuery.isLoading ? (
          <Redirect />
        ) : (
          <Routes>
            {current?.groups?.includes('dt_admin') && (
              <>
                <Route path={'/'} element={<Landing />} />
                <Route path={routes.USERS.to} element={<Users />} />
                <Route path={routes.SUBSCRIPTIONS.to} element={<Subscriptions />} />
                <Route path={routes.SUBSCRIPTION.to} element={<Subscription />} />
                <Route path={routes.BLOG.to} element={<Blogs />} />
                <Route
                  path={routes.GROUPS.to}
                  element={
                    <Sidebar highlight={0}>
                      <Groups />
                    </Sidebar>
                  }
                />
                <Route
                  path={routes.GROUP.to}
                  element={
                    <Sidebar highlight={0}>
                      <Group />
                    </Sidebar>
                  }
                />
                <Route
                  path={routes.TAGS.to}
                  element={
                    <Sidebar highlight={1}>
                      <Tags />
                    </Sidebar>
                  }
                />
                <Route
                  path={routes.DISCOUNTS.to}
                  element={
                    <Sidebar highlight={2}>
                      <Discounts />
                    </Sidebar>
                  }
                />
              </>
            )}
            <Route path={routes.LOGOUT.to} element={<Logout />} />
            <Route path={routes.LOGIN.to} element={<Login />} />
            <Route path={`*`} element={<Redirect />} />
          </Routes>
        )}

        <ToastContainer
          position='bottom-right'
          autoClose={5000}
          hideProgressBar
          newestOnTop
          limit={5}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
        />
        <Footer />
      </BrowserRouter>
      <ReactQueryDevtools initialIsOpen={false} />
    </>
  );
};

export default App;
