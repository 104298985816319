import * as React from 'react';
import styles from './login.module.css';
import AuthService from '../../services/AuthService';
import { useNavigate } from 'react-router-dom';
// import { routes } from '../../config/routes';

const Login = () => {
  const navigate = useNavigate();
  // const localeLang = useSettingsStore((state) => state.locale);

  React.useEffect(() => {
    AuthService.isLoggedIn() ? navigate('/') : AuthService.login();
  }, []);

  return (
    <div className={styles.background}>
      <h3 className={styles.main_text}>{'Most átirányítunk a bejelentkező felületre...'}</h3>
    </div>
  );
};

export default Login;
