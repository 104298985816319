import React, { useRef } from 'react';
import styles from './discounts.module.css';
import { useDiscountLogic } from './useDiscountLogic';
import { Checkbox, IconButton, Tooltip } from '@mui/material';
import moment from 'moment';
import { MdAdd, MdDelete, MdEdit } from 'react-icons/md';
import DiscountCreator from '../../components/discountComponents/discountCreator/DiscountCreator';
import { DataGrid, GridToolbarContainer, GridToolbarQuickFilter } from '@mui/x-data-grid';
import CustomNoRowsOverlay from '../../components/datagrid/customNoRowsOverlay/CustomNoRowsOverlay';
import DiscountEditor from '../../components/discountComponents/discountEditor/DiscountEditor';
import { useTagsLogic } from '../tags/useTagsLogic';
import ConfirmDialog from '../../components/general/confirmDialog/ConfirmDialog';

const Discounts = () => {
  const { fetchDiscounts, postDiscount, deleteDiscount, putDiscount } = useDiscountLogic();
  const { fetchTags } = useTagsLogic();

  const [isCreate, setIsCreate] = React.useState<boolean>(false);
  const [isEdit, setIsEdit] = React.useState<boolean>(false);
  const [sureOpen, setSureOpen] = React.useState<boolean>(false);
  const [editData, setEditData] = React.useState({});

  const dicountRef = useRef<any>();

  function CustomToolbar() {
    return (
      <GridToolbarContainer className={styles.toolbar}>
        <GridToolbarQuickFilter />
        <IconButton
          onClick={() => {
            setEditData({});
            setIsCreate(true);
          }}>
          <MdAdd />
        </IconButton>
      </GridToolbarContainer>
    );
  }
  const columns = [
    {
      field: 'id',
      headerName: 'Id',
      renderHeader: (params: any) => (
        <Tooltip title='Leárazás ID-je'>
          <b className={styles.headerName}>{params.colDef.headerName}</b>
        </Tooltip>
      ),
      flex: 1,
      minWidth: 150
    },
    {
      field: 'typeMatcher',
      headerName: 'Type Matcher',
      renderHeader: (params: any) => (
        <Tooltip title='Leárazás melyik tagekhez van hozzárendelve'>
          <b className={styles.headerName}>{params.colDef.headerName}</b>
        </Tooltip>
      ),
      flex: 1,
      minWidth: 150
    },
    {
      field: 'code',
      headerName: 'Code',
      renderHeader: (params: any) => (
        <Tooltip title='Leárazás kódja és neve'>
          <b className={styles.headerName}>{params.colDef.headerName}</b>
        </Tooltip>
      ),
      flex: 1,
      minWidth: 150
    },
    {
      field: 'discountRate',
      headerName: 'Discount Rate',
      renderHeader: (params: any) => (
        <Tooltip title='Leárazás mennyisége'>
          <b className={styles.headerName}>{params.colDef.headerName}</b>
        </Tooltip>
      ),
      renderCell: (params: any) => <div>{params.value * 100}%</div>,
      flex: 1,
      minWidth: 150
    },
    {
      field: 'endDate',
      headerName: 'End Date',
      renderHeader: (params: any) => (
        <Tooltip title='Leárazás vége'>
          <b className={styles.headerName}>{params.colDef.headerName}</b>
        </Tooltip>
      ),
      renderCell: (params: any) => <div>{moment(params.value).format('yyyy.MM.DD hh:mm:ss')}</div>,
      flex: 1,
      minWidth: 150
    },
    {
      field: 'createdDate',
      headerName: 'Created Date',
      renderHeader: (params: any) => (
        <Tooltip title='Leárazás létrehozása'>
          <b className={styles.headerName}>{params.colDef.headerName}</b>
        </Tooltip>
      ),
      renderCell: (params: any) => <div>{moment(params.value).format('yyyy.MM.DD hh:mm:ss')}</div>,
      flex: 1,
      minWidth: 150
    },
    {
      field: 'isPublic',
      headerName: 'Public',
      renderHeader: (params: any) => (
        <Tooltip title='Publikus / Privát'>
          <b className={styles.headerName}>{params.colDef.headerName}</b>
        </Tooltip>
      ),
      renderCell: (params: any) => <Checkbox checked={params.value} />,
      flex: 1,
      maxWidth: 100
    },
    {
      field: 'actions',
      type: 'actions',
      sortable: false,
      filterable: false,
      renderCell: (params: any) => (
        <>
          <IconButton
            onClick={() => {
              setEditData(params.row);
              setIsEdit(true);
            }}>
            <MdEdit />
          </IconButton>
          <IconButton
            onClick={() => {
              dicountRef.current = params.row;
              setSureOpen(true);
            }}>
            <MdDelete />
          </IconButton>
        </>
      )
    }
  ];

  return (
    <div className={styles.mainContainer}>
      <div className={styles.header}>
        <h1 className={styles.headerTitle}>Kedvezmények</h1>
      </div>
      <div>
        <DataGrid
          loading={fetchDiscounts.isLoading}
          rows={fetchDiscounts.data ?? []}
          columns={columns}
          disableRowSelectionOnClick
          disableColumnFilter
          disableColumnSelector
          disableDensitySelector
          slots={{ toolbar: CustomToolbar, noRowsOverlay: CustomNoRowsOverlay }}
          slotProps={{
            toolbar: {
              showQuickFilter: true
            }
          }}
          autoHeight
        />
      </div>
      {/*TODO: Ide miért kell 2 komponens? ha insert van üres objektumot adsz át, ha update akkor a meglévőt és kész.*/}
      <DiscountCreator
        open={isCreate}
        setClose={setIsCreate}
        data={editData}
        postDiscount={postDiscount}
        tags={fetchTags?.data}></DiscountCreator>
      <DiscountEditor
        open={isEdit}
        setClose={setIsEdit}
        data={editData}
        putDiscount={putDiscount}
        tags={fetchTags?.data}></DiscountEditor>
      <ConfirmDialog
        open={sureOpen}
        setOpen={setSureOpen}
        action={() => {
          deleteDiscount.mutate(dicountRef.current?.id);
          setSureOpen(false);
        }}
        loading={deleteDiscount.isLoading}
        title='Biztos törölni szeretnéd ezt a leárazás jelölőt?'
      />
    </div>
  );
};

export default Discounts;
