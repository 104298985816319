import { api } from '../axios';
import ENV from '../env';

export default {
  async fetchAllCompartments() {
    return await (await api({ baseUrl: ENV.REACT_APP_API_URL, auth: true })).get(`admin/compartments`);
  },

  async getAllSubscription({ search }) {
    return await (
      await api({ baseUrl: ENV.REACT_APP_API_URL, auth: true })
    ).get(`admin/subscriptions${search ? search : '?sort=id,asc'}`);
  },
  async fetchSubscriptionById({ id }) {
    return await (await api({ baseUrl: ENV.REACT_APP_API_URL, auth: true })).get(`admin/subscriptions/${id}`);
  },
  async fetchSubscriptionByUserId({ compId, userId }) {
    return await (
      await api({ baseUrl: ENV.REACT_APP_API_URL, auth: true })
    ).get(`admin/compartments/${compId}/subscriptions/users/${userId}`);
  },
  async updateSubscription({ compId, body }) {
    await (
      await api({ baseUrl: ENV.REACT_APP_API_URL, auth: true })
    ).put(`${ENV.REACT_APP_API_URL}/admin/compartments/${compId}/subscriptions`, { ...body });
  },
  async removeService({ compId, serviceID }) {
    await (
      await api({ baseUrl: ENV.REACT_APP_API_URL, auth: true })
    ).delete(`${ENV.REACT_APP_API_URL}/admin/compartments/${compId}/product-instances/${serviceID}`);
  },
  async patchProductInstance({ pIId, config }) {
    await (
      await api({ baseUrl: ENV.REACT_APP_API_URL, auth: true })
    ).patch(`/admin/product-instances/${pIId}/execute-modification`, { ...config });
  },
  async patchInstanceActive({ compId, pIId, config }) {
    await (
      await api({ baseUrl: ENV.REACT_APP_API_URL, auth: true })
    ).patch(`/admin/compartments/${compId}/product-instances/${pIId}/product-active`, { ...config });
  },
  async createUser({ userDTO, isVerify }) {
    return await (
      await api({ baseUrl: ENV.REACT_APP_API_URL, auth: true })
    ).post(`/admin/users?isVerificationEmailNeeded=${isVerify}`, { ...userDTO });
  },
  async createInstance({ instanceDTO, subscriptionId }) {
    return await (
      await api({ baseUrl: ENV.REACT_APP_API_URL, auth: true })
    ).post(`/admin/subscriptions/${subscriptionId}/product-instances`, { ...instanceDTO });
  },
  async addDiscount({ subscriptionId, code }) {
    return await (
      await api({ baseUrl: ENV.REACT_APP_API_URL, auth: true })
    ).post(`/admin/subscriptions/${subscriptionId}/discounts?code=${code}`);
  }
};
