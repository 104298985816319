import React from 'react';
import styles from './productCard.module.css';
import { useProductCardLogic } from './useProductCardLogic';
import { modifierDTO } from '../../../types/ProductDto';
import { IconButton, Switch } from '@mui/material';
import { MdDelete, MdEdit } from 'react-icons/md';
import Uploader from '../Uploader/Uploader';
import ConfirmDialog from '../../general/confirmDialog/ConfirmDialog';
import { formatCurrency } from '../../../js/utility';
interface ProductCardProps {
  product: any;
  setOpen: any;
  setProductData: any;
  setOriProduct: any;
  handleStatus: (_product: any, _status: boolean) => void;
}

const ProductCard: React.FC<ProductCardProps> = ({ product, setOpen, setProductData, handleStatus, setOriProduct }) => {
  const { getTotalPrice, productImage, setProductImage, deleteProduct, setSureOpen, sureOpen } =
    useProductCardLogic(product);

  return (
    <div className={styles.productCard}>
      <div className={styles.cardTitleWrapper} style={{ opacity: product?.isVisible ? 1 : 0.4 }}>
        <h1 className={styles.diskCardTitle}>{product?.name}</h1>
      </div>
      <img
        src={productImage}
        alt='product_image'
        className={product?.isVisible ? styles.productIcon : styles.productIconInactive}
        width={'120px'}
      />
      <Uploader image={productImage} setImage={setProductImage} id={product?.id} url='/admin/products' />
      <div className={styles.elements}>
        {
          /*eslint-disable */
          product?.modifiers
            ?.sort((a: modifierDTO, b: modifierDTO) =>
              a.modifierKey.toLowerCase().localeCompare(b.modifierKey.toLowerCase())
            )
            ?.map(
              (m: modifierDTO) =>
                m?.displayName !== '' && (
                  <div
                    key={`modifier_${m.id}`}
                    className={product?.isVisible ? styles.profuctInfo : styles.productInfoInactive}>
                    {m?.modifierCategory !== 'SLIDER' && m?.possibleValues?.length === 1 ? (
                      <>
                        {m?.displayName}: {m?.possibleValues[0]}
                      </>
                    ) : m?.modifierCategory === 'SLIDER' && m?.minValue === m?.maxValue ? (
                      <>
                        {m?.displayName}: {m?.minValue} {m?.modifierKey}
                      </>
                    ) : m?.modifierCategory === 'SLIDER' && m?.maxValue > m?.minValue ? (
                      <>
                        {m?.displayName}: {m?.minValue}-{m?.maxValue} {m?.modifierKey}
                      </>
                    ) : (
                      <>{m?.displayName}</>
                    )}
                  </div>
                )
            )
        }
      </div>
      <div>
        <p className={product?.isVisible ? styles.price : styles.priceInactive}>
          {formatCurrency(getTotalPrice(product, 'HUF'), 'HUF')}
        </p>
        <p className={product?.isVisible ? styles.price : styles.priceInactive}>
          {formatCurrency(getTotalPrice(product, 'EUR'), 'EUR')}
        </p>
      </div>
      <div className={styles.action_group}>
        <IconButton
          onClick={() => {
            setProductData(product);
            setOriProduct(product);
            setOpen('update');
          }}>
          <MdEdit />
        </IconButton>

        <Switch
          checked={product?.isVisible}
          className={styles.switch}
          onChange={() => handleStatus(product, product?.isVisible ? false : true)}
        />
        <IconButton
          onClick={() => {
            setSureOpen(true);
          }}>
          <MdDelete />
        </IconButton>
      </div>
      <ConfirmDialog
        open={sureOpen}
        setOpen={setSureOpen}
        action={() => deleteProduct.mutate()}
        loading={deleteProduct.isLoading}
        title='Biztos törölni szeretnéd ezt a terméket?'
      />
      {/* <button type='button' className={styles.selectBtn} onClick={() => routeChange(product)}>
              {t('Select')}
            </button> */}
    </div>
  );
};

export default ProductCard;
