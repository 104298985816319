import { create } from 'zustand';
import { devtools, persist } from 'zustand/middleware';

const useGeneralStore = (set) => ({
  compartments: 1,
  modifierKeys: [],
  subscription: 1,
  current: {},
  currentCompartment: {},
  setCurrentCompartment: (comp) => {
    set(() => ({
      currentCompartment: comp
    }));
  },
  setCurrent: (current) => {
    set(() => ({
      current: current
    }));
  },
  setCompartments: (comps) => {
    set(() => ({
      compartments: comps
    }));
  },
  setModifierKeys: (keys) => {
    set(() => ({
      modifierKeys: keys
    }));
  },
  setSubscription: (subscription) => {
    set(() => ({
      subscription: subscription
    }));
  }
});

export default create(
  devtools(
    persist(useGeneralStore, {
      name: 'generals'
    })
  )
);
