import { api } from '../axios';
import ENV from '../env';

export default {
  async putDiscount({ discountId, discountsDTO }) {
    await (
      await api({ baseUrl: ENV.REACT_APP_API_URL, auth: true })
    ).put(`admin/discounts/`, { ...discountsDTO, id: discountId });
  },
  async deleteDiscount({ discountId }) {
    await (await api({ baseUrl: ENV.REACT_APP_API_URL, auth: true })).delete(`admin/discounts/${discountId}`);
  },
  async fetchDiscounts() {
    return await (await api({ baseUrl: ENV.REACT_APP_API_URL, auth: true })).get(`admin/discounts`);
  },
  async postDiscount({ discountsDTO }) {
    return await (
      await api({ baseUrl: ENV.REACT_APP_API_URL, auth: true })
    ).post(`admin/discounts`, { ...discountsDTO });
  }
};
