import React from 'react';
import styles from './configuratorSelect.module.css';
import { modifierDTO } from '../../../types/ProductDto';
import { FormControl, Select, MenuItem } from '@mui/material';

interface ConfiguratorSelectProps {
  modifier: modifierDTO;
  dtoModifiers: any;
  mementoDtoModifiers?: any;
  setDtoModifiers: any;
}

{
  /*eslint-disable */
}

const ConfiguratorSelect: React.FC<ConfiguratorSelectProps> = ({
  modifier,
  dtoModifiers,
  mementoDtoModifiers,
  setDtoModifiers
}) => {
  return (
    <>
      {/*eslint-disable */}
      <h3 className={styles.title}>{modifier.description}</h3>
      <FormControl style={{ width: '90%', color: 'var(--color-main) !important' }}>
        <Select
          sx={
            mementoDtoModifiers
              ? dtoModifiers[modifier?.modifierKey] != mementoDtoModifiers[modifier?.modifierKey]
                ? { borderTop: 1, borderBottom: 1, borderLeft: 1, borderRight: 1, borderColor: '#FFC107' }
                : {}
              : {}
          }
          onChange={(e: any) => setDtoModifiers({ ...dtoModifiers, [modifier?.modifierKey]: e.target.value })}
          value={dtoModifiers[modifier?.modifierKey]}>
          {modifier?.possibleValues?.map((pV: any, idx: number) => (
            <MenuItem
              key={`possibleValues_${idx}`}
              value={pV}
              style={{
                color: mementoDtoModifiers
                  ? mementoDtoModifiers[modifier?.modifierKey] === pV
                    ? '#FFC107'
                    : 'black'
                  : 'black'
              }}>
              {pV}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </>
  );
};

export default ConfiguratorSelect;
