import React, { useCallback, useEffect, useState } from 'react';
import { useNavigate } from 'react-router';
import { MdArrowDropDown } from 'react-icons/md';
import styles from './SidebarAccordion.module.css';
import { classNames } from '../../../utils/react_utils';

export type SidebarAccordionProps = {
  icon: React.ReactNode;
  text: string;
  name?: string;
  to?: string;
  disabled?: boolean;
  opened?: boolean;
  sx?: string;
  children?: React.ReactNode[] | null;
};

const SidebarAccordion: React.FC<SidebarAccordionProps> = ({
  icon,
  text,
  name = '',
  to = '',
  disabled = false,
  opened = false,
  sx = '',
  children = null
}) => {
  const [isOpened, setIsOpened] = useState(opened);
  const navigate = useNavigate();

  const handleHeadClick = useCallback(() => {
    if (disabled) {
      return;
    }

    if (to !== '' && children === null) {
      // There are no items to display in the accordion,
      // and a "to" parameter has been provided, navigate to the given page (and stop execution)
      navigate(to);
      return;
    }

    setIsOpened((current) => {
      return !current;
    });
  }, [setIsOpened, navigate, disabled, name, children, to]);

  useEffect(() => {
    if (isOpened && disabled) {
      setIsOpened(false);
    }
  }, [isOpened, disabled]);

  const style = classNames(styles.accordion_container, { [styles.body_open]: isOpened }, sx);
  const accordionHeadStyle = classNames(
    styles.head,
    {
      [styles.head_open]: isOpened,
      [styles.disabled]: disabled
    },
    sx
  );

  return (
    <div className={style}>
      <button className={accordionHeadStyle} onClick={handleHeadClick}>
        <div style={{ display: 'flex', alignItems: 'center', paddingRight: '1rem' }}>
          <span className={styles.icons}>{icon}</span>
          <span className={styles.texts}>{text}</span>
          {children?.length && children?.length > 0 && !disabled && (
            <MdArrowDropDown fontSize={30} style={{ margin: '-0.5rem 0 0 3rem' }} />
          )}
        </div>
      </button>
      <div className={styles.body}>
        <div>
          {children?.map((item: React.ReactNode, index: number) => (
            <div key={index}>{item}</div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default SidebarAccordion;
