import React, { useState } from 'react';
import styles from './discountProvider.module.css';
import {
  Button,
  Dialog,
  DialogContent,
  FormControl,
  FormControlLabel,
  IconButton,
  MenuItem,
  Radio,
  RadioGroup,
  Select,
  Slider
} from '@mui/material';
import { MdAddCircle } from 'react-icons/md';
import { Formik } from 'formik';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import tagQueries from '../../../api/queries/TagsQueries';
import { DateTimePicker } from '@mui/x-date-pickers';
import discountQueries from '../../../api/queries/DiscountsQueries';
import subscriptionQueries from '../../../api/queries/SubscriptionQueries';
import { useToasterLogic } from '../../../utils/useToasterLogic';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import useGeneralStore from '../../../store/GeneralStore';
import dayjs from 'dayjs';
import ConfirmDialog from '../../general/confirmDialog/ConfirmDialog';

interface DiscountProviderProps {
  data: any;
  setData: any;
}

const DiscountProvider: React.FC<DiscountProviderProps> = ({ data, setData }) => {
  const [open, setOpen] = useState<boolean>(false);
  const [confirmOpen, setConfirmOpen] = useState<boolean>(false);
  const [newDiscount, setNewDiscount] = useState<string>('true');
  const queryClient = useQueryClient();
  const { successToast, errorToast } = useToasterLogic();
  const [selectedDiscount, setSelectedDiscount] = useState<any>('');
  const { subscription }: any = useGeneralStore((state) => ({
    subscription: state.subscription
  }));
  const [formData, setFormData] = useState<any>({
    code: data?.code ?? '',
    typeMatcher: data?.typeMatcher ?? [],
    discountRate: data?.discountRate ?? 0,
    createdDate: dayjs(data?.createdDate),
    usableUntil: dayjs(data?.usableUntil),
    isPublic: data?.isPublic ?? false,
    endDate: dayjs(data?.endDate),
    isGenerated: data?.isGenerated ?? true
  });

  React.useEffect(() => {
    setFormData({
      code: data?.code ?? '',
      typeMatcher: data?.typeMatcher ?? [],
      discountRate: data?.discountRate ?? 0,
      createdDate: dayjs(data?.createdDate),
      usableUntil: dayjs(data?.usableUntil),
      isPublic: data?.isPublic ?? false,
      endDate: dayjs(data?.endDate),
      isGenerated: data?.isGenerated ?? true
    });
  }, [data]);
  const handleClose = () => {
    setOpen(false);
  };
  const fetchTags = useQuery<any, any>({
    queryKey: ['tags'],
    queryFn: () => tagQueries.fetchTags(),
    retry: (failureCount, error: any) => (error.response.status === 404 ? false : true),
    refetchOnWindowFocus: false
  });
  const fetchDiscounts = useQuery<any, any>({
    queryKey: ['discounts'],
    queryFn: () => discountQueries.fetchDiscounts(),
    retry: (failureCount, error: any) => (error.response.status === 404 ? false : true),
    refetchOnWindowFocus: false
  });
  const addDiscount = useMutation({
    mutationFn: (code) => subscriptionQueries.addDiscount({ subscriptionId: subscription?.id, code: code }),
    onSuccess: () => {
      successToast('Sikeres kupon hozzáadás');
      queryClient.invalidateQueries(['subscription']);
    },
    onError: (error: any) => {
      errorToast(error.message);
    }
  });
  const createDiscount = useMutation({
    mutationFn: (data: any) => discountQueries.postDiscount({ discountsDTO: data }),
    onSuccess: (data: any) => {
      successToast('Sikeres kupon létrehozás');
      addDiscount.mutate(data?.code);
    },
    onError: (error: any) => {
      errorToast(error.message);
    }
  });
  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <div className={styles.discountProvider}>
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <h4>Kupon hozzáadása</h4>
          <IconButton>
            <MdAddCircle
              onClick={() => {
                setOpen(!open);
                setData({});
              }}
            />
          </IconButton>
          <Dialog open={open} onClose={handleClose}>
            <DialogContent className={styles.dialogcontent}>
              <h3>Kupon hozzáadása</h3>
              <FormControl style={{ width: '90%', color: 'var(--color-main) !important' }}>
                <RadioGroup value={newDiscount} onChange={(e: any) => setNewDiscount(e.target.value)}>
                  <FormControlLabel
                    value={'true'}
                    control={
                      <Radio
                        style={{
                          color: 'black'
                        }}
                      />
                    }
                    label={'Új kupon hozzáadása'}
                    style={{
                      color: 'black'
                    }}
                  />
                  <FormControlLabel
                    value={'false'}
                    control={
                      <Radio
                        style={{
                          color: 'black'
                        }}
                      />
                    }
                    label={'Meglévő kupon hozzáadása'}
                    style={{
                      color: 'black'
                    }}
                  />
                </RadioGroup>
              </FormControl>
              {newDiscount == 'true' && fetchTags?.data && (
                <Formik
                  enableReinitialize
                  initialValues={formData}
                  onSubmit={() => {
                    setSelectedDiscount(null);
                    setConfirmOpen(true);
                  }}>
                  {({ values, handleSubmit }) => (
                    <form onSubmit={handleSubmit} className={styles.mainForm}>
                      <div className={styles.inputContainer}>
                        <div className={styles.inputLabel}>Kedvezmény százaléka:</div>
                        <div className={styles.inputField}>
                          <Slider
                            min={0}
                            max={100}
                            step={1}
                            autoFocus
                            valueLabelDisplay='on'
                            value={values.discountRate * 100 ?? 0}
                            onChange={(e, val) => {
                              setFormData({ ...formData, discountRate: (val as any) / 100 });
                            }}
                          />
                        </div>
                      </div>
                      <div className={styles.inputContainer}>
                        <div className={styles.inputLabel}>Hozzá tartozó címkék:</div>
                        <div className={styles.inputField}>
                          <Select
                            variant='standard'
                            fullWidth
                            multiple
                            autoFocus
                            onChange={(e: any) => {
                              setFormData({ ...formData, typeMatcher: e.target.value });
                            }}
                            value={values.typeMatcher ?? ''}>
                            {fetchTags?.data?.map((el: any) => {
                              return (
                                <MenuItem key={el.id} value={el.name}>
                                  {el.name}
                                </MenuItem>
                              );
                            })}
                          </Select>
                        </div>
                      </div>
                      <div className={styles.inputContainer}>
                        <div className={styles.inputLabel}>Kedvezmény kezdete:</div>
                        <div className={styles.inputField}>
                          <DateTimePicker
                            autoFocus
                            value={values.createdDate}
                            onChange={(e: any) => {
                              setFormData({ ...formData, createdDate: e });
                            }}
                          />
                        </div>
                      </div>
                      <div className={styles.inputContainer}>
                        <div className={styles.inputLabel}>Kedvezmény vége:</div>
                        <div className={styles.inputField}>
                          <DateTimePicker
                            autoFocus
                            value={values.endDate}
                            onChange={(e: any) => {
                              setFormData({ ...formData, endDate: e });
                            }}
                          />
                        </div>
                      </div>
                      <div className={styles.inputContainer}>
                        <div className={styles.inputLabel}>Használható eddig:</div>
                        <div className={styles.inputField}>
                          <DateTimePicker
                            autoFocus
                            value={values.usableUntil}
                            onChange={(e: any) => {
                              setFormData({ ...formData, usableUntil: e });
                            }}
                          />
                        </div>
                      </div>
                      <Button disabled={false} type='submit'>
                        Létrehozás és hozzáadás
                      </Button>
                    </form>
                  )}
                </Formik>
              )}
              {newDiscount == 'false' && (
                <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                  <Select
                    className={styles.input}
                    label='Kuponkód'
                    value={selectedDiscount}
                    onChange={(e) => setSelectedDiscount(e.target.value)}>
                    {fetchDiscounts?.data?.map((discount: any) => (
                      <MenuItem key={discount?.id} value={discount?.code}>
                        {discount?.code}
                      </MenuItem>
                    ))}
                  </Select>
                  <Button onClick={() => setConfirmOpen(true)} type='button'>
                    Hozzáadás
                  </Button>
                </div>
              )}
            </DialogContent>
          </Dialog>
        </div>
        <ConfirmDialog
          open={confirmOpen}
          setOpen={setConfirmOpen}
          title='Biztosan hozzáadod a kupont a feliratkozáshoz?'
          action={() => (selectedDiscount ? addDiscount.mutate(selectedDiscount) : createDiscount.mutate(formData))}
          loading={addDiscount?.isLoading || createDiscount?.isLoading}
        />
      </div>
    </LocalizationProvider>
  );
};

export default DiscountProvider;
