import { TextField } from '@mui/material';
import React from 'react';
import { QueryOptionDto } from '../../../../../types/QueryBuilderDtos';

interface StringEditorProps {
  option: QueryOptionDto;
  setOption: any;
}

const StringEditor: React.FC<StringEditorProps> = ({ option, setOption }) => {
  return (
    <TextField
      variant='outlined'
      InputProps={{ style: { fontFamily: 'var(--font-main)' } }}
      onChange={(e) => setOption({ ...option, value: `${option.name}=${e.target.value}` })}
    />
  );
};

export default StringEditor;
