import { useQuery } from '@tanstack/react-query';
import React from 'react';
import queries from '../../../../api/queries/ProductQueries';

export const useModifierItemLogic = (modId: number, prodId: number) => {
  const [modifierImage, setModifierImage] = React.useState<any>();

  /*eslint-disable*/
  const modifierImageFetchQuery = useQuery({
    queryKey: ['modifierImage', modId],
    queryFn: () => queries.fetchModifierImage({ modId: modId, prodId: prodId }),
    onSuccess: async (data) => {
      let image = await queries.convertBlobToBase64(await data);
      setModifierImage(image);
    },
    retry: (failureCount, error: any) => (error.response.status === 404 || error.response.status === 403 ? false : true),
    refetchOnWindowFocus: false,
    enabled: !!modId && !!prodId
  });

  return {modifierImage, setModifierImage};
};
