import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { useEffect, useState } from 'react';
import queries from '../../api/queries/ProductQueries';
import { useValidationLogic } from '../../validation/useValidationLogic';
import { useToasterLogic } from '../../utils/useToasterLogic';

export const useGroupsLogic = () => {
  const [groups, setGroups] = useState<any[]>([]);
  const [action, setAction] = useState<any>(false);
  const [sureOpen, setSureOpen] = useState<any>(false);
  const [groupData, setGroupData] = useState<any>();

  const { successToast, errorToast } = useToasterLogic();

  const { GroupSchema } = useValidationLogic();

  const queryClient = useQueryClient();

  const fetchGroupsQuery = useQuery({
    queryKey: ['groups'],
    queryFn: () => queries.fetchGroups(),
    onSuccess: (data: any) => {
      setGroups(data);
    },
    retry: (failureCount, error: any) => (error.response.status === 404 ? false : true),
    refetchOnWindowFocus: false
  });

  const createGroupQuery = useMutation({
    mutationFn: () => queries.createGroup({ groupDto: groupData }),
    onSuccess: (data: any) => {
      queryClient.invalidateQueries(['groups']);

      if (data?.parent) {
        queryClient.invalidateQueries(['groupBundles']);
      }

      setAction(null);
      setGroupData(null);
      successToast('Sikeres termékcsoport létrehozás');
    },
    onError: () => {
      errorToast('Sikertelen termékcsoport létrehozás');
    }
  });

  const updateGroupQuery = useMutation({
    mutationFn: () => queries.updateGroup({ groupDto: { ...groupData, products: [] } }),
    onSuccess: () => {
      queryClient.invalidateQueries(['groups']);
      setAction(null);
      setGroupData(null);
      successToast('Sikeres termékcsoport frissítés');
    },
    onError: () => {
      errorToast('Sikertelen termékcsoport frissítés');
    }
  });

  const deleteGroupQuery = useMutation({
    mutationFn: (id) => queries.deleteGroup({ id: id }),
    onSuccess: () => {
      queryClient.invalidateQueries(['groups']);
      setSureOpen(false);
      successToast('Sikeres törlés');
    },
    onError: () => {
      errorToast('Sikertelen törlés');
    }
  });

  const handleSubmit = () => {
    if (action === 'update') {
      updateGroupQuery.mutate();
    } else {
      createGroupQuery.mutate();
    }
  };

  const handleStatus = (group: any, status: boolean) => {
    setGroupData({ ...group, isVisible: status });
    updateGroupQuery.mutate();
  };

  useEffect(() => {}, [fetchGroupsQuery.data]);

  return {
    groups,
    createGroupQuery,
    deleteGroupQuery,
    setAction,
    action,
    GroupSchema,
    setGroupData,
    groupData,
    handleSubmit,
    handleStatus,
    sureOpen,
    setSureOpen,
    updateGroupQuery
  };
};
