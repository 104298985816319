import React from 'react';
import styles from './subscriptions.module.css';
import { useSubscriptionsLogic } from './useSubscriptionsLogic';
import { useNavigate } from 'react-router-dom';
import { Slide } from '@mui/material';
import { FaCheckCircle } from 'react-icons/fa';
import { MdClose } from 'react-icons/md';
import QueryBuilder from '../../components/general/QueryBuilder/QueryBuilder';
import UserSubCreator from '../../components/userComponents/UserSubCreator/UserSubCreator';

const Subscriptions = () => {
  const { subscriptionsQuery, refresh, reset } = useSubscriptionsLogic();
  const navigate = useNavigate();
  const routeChange = (path: string) => {
    navigate(`${path}`);
    console.log(path);
  };

  return (
    <div className={styles.mainContainer}>
      <div className={styles.header}>
        <h1 className={styles.headerTitle}>Feliratkozások</h1>
      </div>
      <UserSubCreator />
      <QueryBuilder itemsLength={subscriptionsQuery?.data?.page?.totalElements} refresh={refresh} reset={reset}>
        <div className={styles.subscriptionListWrapper}>
          <table className={styles.ownGridHeader}>
            <thead className={styles.thead}>
              <tr className={styles.tablecell}>
                <td>Azonosító</td>
              </tr>
              <tr className={styles.hideXS}>
                <td>Felhasználó</td>
              </tr>
              <tr className={styles.hideM}>
                <td>Compartment</td>
              </tr>
              <tr className={styles.hideM}>
                <td>Szolgáltatások</td>
              </tr>
              <tr className={styles.hideM}>
                <td>Létrehozás dátuma</td>
              </tr>
              <tr className={styles.hideM}>
                <td>Kredit egyenleg</td>
              </tr>
              <tr className={styles.hideM}>
                <td>Kártyaregisztráció</td>
              </tr>
              <tr className={styles.hideM}>
                <td>Valuta</td>
              </tr>
              <tr className={styles.tablecell}>
                <td>Státusz</td>
              </tr>
            </thead>
          </table>

          {subscriptionsQuery?.data?._embedded?.subscriptionDTOList?.map((subscription: any) => (
            <div key={`subscription_item_${subscription?.externalId}`} className={styles.gridItem}>
              <Slide direction='right' timeout={500} in={true}>
                <div className={styles.service} onClick={() => routeChange(subscription?.id)}>
                  <div className={styles.serviceSummary}>
                    <div style={{ display: 'flex', width: '100%', alignItems: 'center', height: '100%' }}>
                      <div className={styles.tablecell}>
                        <p
                          style={{
                            fontSize: 'var(--fs-13)',
                            marginBottom: '0',
                            marginTop: '0'
                          }}>
                          {subscription?.id}
                        </p>
                      </div>
                      <div className={styles.hideXS}>
                        <p
                          style={{
                            fontSize: 'var(--fs-13)',
                            marginBottom: '0',
                            marginTop: '0'
                          }}>
                          {subscription?.userId}
                        </p>
                      </div>
                      <div className={styles.hideM}>
                        <p
                          style={{
                            fontSize: 'var(--fs-13)',
                            marginBottom: '0',
                            marginTop: '0'
                          }}>
                          {subscription?.compartmentId}
                        </p>
                      </div>
                      <div className={styles.hideM}>
                        <p
                          style={{
                            fontSize: 'var(--fs-13)',
                            marginBottom: '0',
                            marginTop: '0'
                          }}>
                          {subscription?.productInstances?.length}db
                        </p>
                      </div>
                      <div className={styles.hideM}>
                        <p
                          style={{
                            fontSize: 'var(--fs-13)',
                            marginBottom: '0',
                            marginTop: '0'
                          }}>
                          {subscription?.createdDate}
                        </p>
                      </div>
                      <div className={styles.hideM}>
                        <p
                          style={{
                            fontSize: 'var(--fs-13)',
                            marginBottom: '0',
                            marginTop: '0'
                          }}>
                          {subscription?.creditBalance}
                        </p>
                      </div>
                      <div className={styles.hideM}>
                        <p
                          style={{
                            fontSize: 'var(--fs-13)',
                            marginBottom: '0',
                            marginTop: '0'
                          }}>
                          {subscription?.isCardRegistered ? <FaCheckCircle color='green' /> : <MdClose color='red' />}
                        </p>
                      </div>
                      <div className={styles.hideM}>
                        <p
                          style={{
                            fontSize: 'var(--fs-13)',
                            marginBottom: '0',
                            marginTop: '0'
                          }}>
                          {subscription?.currency}
                        </p>
                      </div>
                      <div className={styles.tablecell}>
                        {subscription?.status ? (
                          <div className={styles.dot} style={{ backgroundColor: 'green' }}></div>
                        ) : (
                          <div className={styles.dot} style={{ backgroundColor: 'red' }}></div>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </Slide>
            </div>
          ))}
        </div>
      </QueryBuilder>
    </div>
  );
};

export default Subscriptions;
