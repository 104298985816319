import React from 'react';
import styles from './dataBlock.module.css';

export type DataBlockProps = {
  title: string;
  children: React.ReactNode;
};

const DataBlock: React.FC<DataBlockProps> = ({ title, children }) => {
  return (
    <div className={styles.setting_block}>
      <h2>{title}</h2>
      {children}
    </div>
  );
};

export default DataBlock;
